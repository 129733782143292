import { Box, Button, Card, CardMedia, Grid, Typography } from "@mui/material";
import { Routes, Route, Navigate, Link } from "react-router-dom";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import './tools.scss';
import { Header } from "../../component/home-header/header";
import { Footer } from "../../component/home-footer/footer";
import { CropVideo } from "./crop-video/crop-video";
import { CutVideo } from "./cut-video/cut-video";
import { JoinMergeVideo } from "./join-merge-video/join-merge";
import { LoopVideo } from "./loop-video/loop-video";
import { ResizeVideo } from "./resize-video/resize-video";
import { RotateVideo } from "./rotate-video/rotate-video";
import { ConvertVideo } from "../More/convert-video/convert-video";
import { CompressVideo } from "../More/compress-video/compress-video";
import { TranscribeVideo } from "../SubtitlesTranscription/transcribe-video/transcribe-video";
import { TranslateVideo } from "../SubtitlesTranscription/translate-video/translate-video";
import { AudioToText } from "../SubtitlesTranscription/audio-to-text/audio-to-text";
import { AutoSubtitles } from "../SubtitlesTranscription/auto-subtitles/auto-subtitles";
import { ToolPage } from "./toolpage";

import { GetProjectLink } from "../../axios/ApiProvider";

export const EDIT_FRONTEND_URL = process.env.REACT_APP_BASE_FRONTEND_EDITOR_URL
export const BASE_STATIC_STORAGE_URL = process.env.REACT_APP_STATIC_STORAGE_URL;

export const Tools = () => {
  const handleCreateNewProject = async () => {
    let result = await GetProjectLink();
    if (!result || result.state == 'error') window.toastr.error(result.data.message);
    else if (!!Object.keys(result.data)[0]) window.location.href = `${EDIT_FRONTEND_URL}/${Object.keys(result.data)[0]}?from=new`
  }

  const toolsList = [
    {
      title: 'Video Converter',
      details: 'Convert Video to MP4, AVI, and More',
      links: '/video-converter',
      image: `${BASE_STATIC_STORAGE_URL}/images/features/Export%20Convert%20Video.png`
    },
    {
      title: 'Video Compressor',
      details: 'Compress Video - No Loss in Quality',
      links: '/video-compressor',
      image: `${BASE_STATIC_STORAGE_URL}/images/features/Compress%20Video.png`
    },
    {
      title: 'Video Resizer',
      details: 'Resize Video Online',
      links: '/video-resizer',
      image: `${BASE_STATIC_STORAGE_URL}/images/features/Export%20Resize%20Video.png`
    },
    {
      title: 'Crop Video',
      details: 'Crop Video Online, Video Cropper',
      links: '/crop-video',
      image: `${BASE_STATIC_STORAGE_URL}/images/features/Crop%20Video.png`
    },
    {
      title: 'Cut Video',
      details: 'Video Trimmer, Cut Video Online',
      links: '/video-trimmer',
      image: `${BASE_STATIC_STORAGE_URL}/images/features/Cut%20Video.png`
    },
    {
      title: 'Loop Video',
      details: 'Loop and Repeat Video Online',
      links: '/loop-video',
      image: `${BASE_STATIC_STORAGE_URL}/images/features/Export%20Loop%20Video.png`
    },
    {
      title: 'Rotate Video',
      details: 'Video Rotator, Rotate Video Online for Free',
      links: '/rotate-video',
      image: `${BASE_STATIC_STORAGE_URL}/images/features/Add%20Your%20File%20Rotate%20Video.png`
    },
    {
      title: 'Transcribe Video',
      details: 'Transcribe Video to Text',
      links: '/transcribe-video-to-text',
      image: `${BASE_STATIC_STORAGE_URL}/images/features/Export%20Transcribe%20Video.png`
    },
    {
      title: 'Translate Video',
      details: 'AI Video Translator, Translate Videos Online',
      links: '/video-translator',
      image: `${BASE_STATIC_STORAGE_URL}/images/features/Videoo-Translations.jpg`
    },
    {
      title: 'Merge Video',
      details: 'Merge Videos Online, Combine Video Clips',
      links: '/merge-videos',
      image: `${BASE_STATIC_STORAGE_URL}/images/features/Add%20Your%20File%20Join-Merge.png`
    },
    {
      title: 'Audio to Text',
      details: 'Transcribe Audio to Text, AI Transcription',
      links: '/audio-to-text',
      image: `${BASE_STATIC_STORAGE_URL}/images/features/Add%20Audio%20to%20Text.png`
    },
    {
      title: 'Subtitle Generator',
      details: 'AI Powered Subtitle Generator Online',
      links: '/subtitle-generator',
      image: `${BASE_STATIC_STORAGE_URL}/images/features/Add%20Subtitle%20to%20Video.png`
    },
  ]
  return (
    <Box className="T_containner">
      <Box className="Header"><Header /></Box>

      <Box className="content-containner">
        <Box className="section-15 py-12">
          <Box className="div-1232 text-center">
            <Box>
            <Typography variant="h3" gutterBottom fontWeight={'bold'}>
            Online Video Tools <br />for Social Media & Business
            </Typography>
            </Box>
            <Box sx={{marginBottom: '1rem'}}>
              <Typography variant="p" gutterBottom >
                Videoo.io provides all the Video Editing Tools that your need, No need to install any software to your computer, all in your browser.
              </Typography>
            </Box>
            <Box onClick={handleCreateNewProject}>
              <Button variant="contained" className="create-now mx-auto">Create Now</Button>
            </Box>
          </Box>
        </Box>
        <Box className='section-15 pt-12'>
          <Box className='div-1232'>
            <Box className='breadcrumbs'>
              <a href={'/'}>
                <Box className='breadcrumb-text-block'>Videoo</Box>
              </a>
              <Box className='breadcrumb-arrow'></Box>
              <a href={'/'}>
                <Box className='breadcrumb-text-block'>Video Tools</Box>
              </a>
            </Box>
          </Box>
        </Box>

        <Box className="section-9 directory std-hero-section">
          <Box className="div-1232">
            <Box sx={{textAlign: 'center'}}>
              <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>All Video Tools You Need</Typography>
            </Box>
            <Box sx={{textAlign: 'center', marginBottom: '3rem'}}>
              <Typography variant="p" gutterBottom >You can use Videoo.io from all operating systems; Windows, Mac, Linux, Android, and iOS, either from Chrome, Firefox, Safari, Opera or Edge.</Typography>
            </Box>
            <Box className="py-15">
              <Grid container spacing={3}>
                {toolsList.map((toolItem, key) => (
                  <Grid key={key} item md={4} xs={12} sx={{textAlign: 'center'}}>
                    <Card className="tools-card" sx={{borderRadius: '15px'}}>
                      <CardMedia 
                        component="img"
                        height={140}
                        src={toolItem.image}
                        >
                        
                      </CardMedia>
                      
                    </Card>
                    <Card className="tools-label-card p-13 mx-auto" sx={{maxWidth: '80%', borderRadius: '15px', marginTop: '-20px', position: 'relative'}}>
                        <Box sx={{textAlign: 'center', paddingY: "10px"}}>
                          <Typography variant="h5" gutterBottom fontWeight={600} color={'#717171'}>{toolItem.title}</Typography>   
                        </Box>
                        <Box sx={{textAlign: 'center', paddingY: "10px"}}>
                          <Typography variant="span">{toolItem.details}</Typography>
                        </Box>
                        <Box sx={{textAlign: 'center', paddingY: "10px"}}>
                        <Link to={toolItem.links}>
                          <Button variant="text" color="success">Read More <ArrowRightAltIcon /></Button>
                        </Link>
                        </Box>
                      </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Box>

        <Box className="section-9 directory std-hero-section">
          <Box className="section-15 py-12 mx-auto">
            <Box className="div-1232">
              <Box className="mx-auto">
                <Typography sx={{textAlign: 'center'}} variant="h3" gutterBottom fontWeight={'bolder'}>Make Your Videos Easily</Typography>
              </Box>
              <Box className="mx-auto text-center mt-10" onClick={handleCreateNewProject}>
                <Button variant="contained" className="py-10 px-33" color="success">Create Now <ArrowRightAltIcon /></Button>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* <Routes>
          <Route path="../video-converter" element={<ConvertVideo />} />
          <Route path="/video-compressor" element={<CompressVideo />} />
          <Route path="/crop-video" element={<CropVideo />} />
          <Route path="/video-trimmer" element={<CutVideo />} />
          <Route path="/merge-videos" element={<JoinMergeVideo />} />
          <Route path="/loop-video" element={<LoopVideo />} />
          <Route path="/video-resizer" element={<ResizeVideo />} />
          <Route path="/rotate-video" element={<RotateVideo />} />
          <Route path="/transcribe-video-to-text" element={<TranscribeVideo />} />
          <Route path="/video-translator" element={<TranslateVideo />} />
          <Route path="/audio-to-text" element={<AudioToText />} />
          <Route path="/subtitle-generator" element={<AutoSubtitles />} />
          <Route path="/" element={<ToolPage />} />
        </Routes> */}

      </Box>

      <Box className="footer-containner"><Footer /></Box>
    </Box >
  )
}