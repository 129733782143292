import { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Menu, Typography } from "@mui/material";

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import { PlanMenuInfo } from "./../dashboard.config";
import { getPaymentPortal } from "../../../axios/ApiProvider";

export const PlanMenu = ({ userData }) => {
  const languageStrs = useSelector((store) => store.editdata.languageStrs)
  const [planMenuEl, setPlanMenuEl] = useState(null);
  const isPlanMenu = Boolean(planMenuEl);
  const PlanMenuOpen = (event) => { setPlanMenuEl(event.currentTarget); };
  const PlanMenuClose = () => { setPlanMenuEl(null); };

  let username = userData?.username || ''
  let firName = userData?.firstname || ''
  let lastname = userData?.lastname || ''

  const onClick = async (type) => {
    if (type === 'Billing') {
      let result = await getPaymentPortal()
      if (result.state !== 'success') return

      let portal_url = result.data.portal_url
      window.location.href = portal_url
    }
  }

  return (
    <>
      <Box className="d-sidebar-plan-menu" onClick={PlanMenuOpen}>
        {!!userData?.picture && (
          <img src={userData?.picture} alt='' className="w-40 h-40 rounded-md" />
        )}

        {!userData?.picture && (
          <Box className="dw-plan-logo-section">
            <Box className="dw-plan-logo">
              <span>{username.slice(0, 1)}</span>
            </Box>
          </Box>
        )}

        <Box className="dw-plan-content">
          {/* <span className="dw-plan-content-header">Free Plan</span> */}
          <span className="dw-plan-content-title">{firName} {lastname}</span>
        </Box>

        <Box className="dw-plan-dropdown-icon">
          <ArrowDropUpIcon />
        </Box>
      </Box>

      <Menu
        aria-labelledby="demo-positioned-button"
        className="sidebar-plan-menu-contain sidebar-menu"
        anchorEl={planMenuEl}
        open={isPlanMenu}
        onClose={PlanMenuClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left', }}
        transformOrigin={{ vertical: 'top', horizontal: 'left', }}
      >
        <Box className="plan-menu-section">
          <Box className="plan-menu-header-sec" onClick={PlanMenuClose}>
            {!!userData?.picture && (
              <img src={userData?.picture} alt='' className="w-40 h-40 rounded-md" />
            )}

            {!userData?.picture && (
              <Box className="pmh-logo-section">
                <Box className="pmh-logo">
                  <span>{username.slice(0, 1)}</span>
                </Box>
              </Box>
            )}

            <Box className="pmh-content">
              {/* <span className="pmh-content-header">Free Plan</span> */}
              <span className="pmh-content-title">{firName} {lastname}</span>
            </Box>

            <Box className="pmh-dropdown-icon">
              <ArrowDropDownIcon />
            </Box>
          </Box>

          <Box className="plan-menu-body-sec">
            <Box className="plan-menu-subtitle-time-contain">
              <Box className="pm-subtitle-time-header">
                <CreditCardOutlinedIcon />
                {languageStrs.available_subtitles_time}
              </Box>

              <Typography className="pm-subtitle-time-title text-12">
                {userData?.subtitle_time || 0}
              </Typography>
            </Box>

            <Box className="plan-menu-subtitle-time-contain">
              <Box className="pm-subtitle-time-header">
                <CreditCardOutlinedIcon />
                {languageStrs.available_storage_space}
              </Box>

              <Typography className="pm-subtitle-time-title text-12">
                {userData?.storage_usage_size || ''}
              </Typography>
            </Box>

            <Box className="plan-menu-subtitle-time-contain">
              <Box className="pm-subtitle-time-header">
                <CreditCardOutlinedIcon />
                {languageStrs.available_text_to_speech}
              </Box>

              <Typography className="pm-subtitle-time-title text-12">
                {userData?.texttospeech_status || ''}
              </Typography>
            </Box>

            <Box className="plan-menu-list-contain">
              {PlanMenuInfo.map((item, index) => (
                <Box key={index}
                  className="plan-menu-list"
                  onClick={() => { onClick(item.name) }}
                >
                  {item.icon} {item.name}
                </Box>
              ))}
            </Box>
          </Box>

          <Box className="line"></Box>
          {/* <Box className="plan-menu-footer-sec">
            <Box className="plan-menu-footer">
              <Typography className="plan-menu-footer-title">
                Other workspaces
              </Typography>

              <Box className="plan-menu-footer-newWorkspaces-btn">
                New <AddOutlinedIcon />
              </Box>
            </Box>
          </Box> */}
        </Box>
      </Menu>
    </>
  )
}