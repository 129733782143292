// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog-wrapper {
  gap: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 100px;
}
.blog-wrapper .blog-container .blog-item {
  cursor: pointer;
  box-shadow: rgba(17, 17, 26, 0.1019607843) 0px 1px 0px, rgba(17, 17, 26, 0.1019607843) 0px 8px 24px, rgba(17, 17, 26, 0.1019607843) 0px 16px 48px;
}
@media screen and (max-width: 1536px) {
  .blog-wrapper {
    padding: 30px 70px;
  }
}
@media screen and (max-width: 1280px) {
  .blog-wrapper {
    padding: 20px 50px;
  }
}
@media screen and (max-width: 992px) {
  .blog-wrapper {
    padding: 10px 30px;
  }
}
@media screen and (max-width: 640px) {
  .blog-wrapper {
    padding: 0px 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/blogs/videooblog/videoblog.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AACF;AAEI;EACE,eAAA;EACA,iJAAA;AAAN;AAIE;EAdF;IAeI,kBAAA;EADF;AACF;AAGE;EAlBF;IAmBI,kBAAA;EAAF;AACF;AAIE;EAxBF;IAyBI,kBAAA;EADF;AACF;AAKE;EA9BF;IA+BI,iBAAA;EAFF;AACF","sourcesContent":[".blog-wrapper {\n  gap: 30px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  padding: 30px 100px;\n\n  .blog-container {\n    .blog-item {\n      cursor: pointer;\n      box-shadow: #11111a1a 0px 1px 0px, #11111a1a 0px 8px 24px, #11111a1a 0px 16px 48px;\n    }\n  }\n\n  @media screen and (max-width: 1536px) {\n    padding: 30px 70px;\n  }\n\n  @media screen and (max-width: 1280px) {\n    padding: 20px 50px;\n  }\n\n  @media screen and (max-width: 1024px) {}\n\n  @media screen and (max-width: 992px) {\n    padding: 10px 30px;\n  }\n\n  @media screen and (max-width: 768px) {}\n\n  @media screen and (max-width: 640px) {\n    padding: 0px 20px;\n  }\n\n  @media screen and (max-width: 350px) {}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
