import './videoocareer.scss'
import { Layouts } from '../../component/layouts/layouts'

export const Career9 = () => {
  return (
    <Layouts>
      <div className='flex flex-col items-center justify-center px-10 sm:px-20 md:px-30 lg:px-40'>
        <div className='w-full max-w-1000 flex flex-col gap-10 md:gap-20 lg:gap-30 py-30'>
          <div className='text-center text-20 md:text-30 lg:text-40 font-semibold'>
            Backend Software Engineer
          </div>
          <div className='flex flex-col gap-10 mm:gap-20'>
            <div className='text-15 md:text-18'>
              We are seeking a talented and experienced Backend Software Engineer to join our development team. As a Backend Software Engineer, you will be responsible for designing, developing, and maintaining the server-side logic of our software applications. You will work closely with cross-functional teams to understand project requirements, implement scalable solutions, and ensure the smooth operation of our systems.

              This is an exciting opportunity to work on cutting-edge technologies and contribute to the development of innovative software solutions. You will have the chance to collaborate with a team of highly skilled engineers and make a significant impact on the success of our products.
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Responsibilities:</span>
              <div className='text-15 md:text-18'>
                - Design and develop scalable, reliable, and high-performance backend systems
              </div>
              <div className='text-15 md:text-18'>
                - Collaborate with cross-functional teams to gather and analyze requirements
              </div>
              <div className='text-15 md:text-18'>
                - Implement efficient and secure APIs and web services
              </div>
              <div className='text-15 md:text-18'>
                - Write clean and maintainable code following best practices and coding standards
              </div>
              <div className='text-15 md:text-18'>
                - Perform code reviews to ensure code quality and provide constructive feedback
              </div>
              <div className='text-15 md:text-18'>
                - Troubleshoot and debug issues, and provide timely resolutions
              </div>
              <div className='text-15 md:text-18'>
                - Collaborate with DevOps teams to deploy and monitor applications
              </div>
              <div className='text-15 md:text-18'>
                - Stay up-to-date with the latest technologies and trends in backend development
              </div>
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Qualifications:</span>
              <div className='text-15 md:text-18'>
                - Bachelor's or higher degree in Computer Science, Software Engineering, or a related field
              </div>
              <div className='text-15 md:text-18'>
                - Strong experience in backend development using languages such as Java, Python, or Ruby
              </div>
              <div className='text-15 md:text-18'>
                - Proficiency in database technologies like SQL, NoSQL, and ORM frameworks
              </div>
              <div className='text-15 md:text-18'>
                - Familiarity with cloud platforms like AWS, Azure, or GCP
              </div>
              <div className='text-15 md:text-18'>
                - Knowledge of web development frameworks and libraries (e.g., Spring Boot, Django)
              </div>
              <div className='text-15 md:text-18'>
                - Understanding of RESTful APIs and microservices architecture
              </div>
              <div className='text-15 md:text-18'>
                - Experience with version control systems (e.g., Git)
              </div>
              <div className='text-15 md:text-18'>
                - Strong problem-solving and debugging skills
              </div>
            </div>

            <div className='text-15 md:text-18'>
              If you are a skilled Backend Software Engineer with a passion for building robust and scalable systems, we would love to hear from you. Join our team and contribute to the development of innovative software solutions that have a real impact on our users.

              To apply, please submit your resume and cover letter to info@videoo.io. Please include "Backend Software Engineer Application" in the subject line. Only shortlisted candidates will be contacted.

              We look forward to reviewing your application!
            </div>
          </div>
        </div>
      </div>
    </Layouts>
  )
}