import { useState, useEffect, useRef } from "react";
import { useSelector } from 'react-redux';
import { Box, Dialog, DialogContent, DialogTitle, DialogActions, Button } from "@mui/material";

import { loadStripe } from "@stripe/stripe-js";
import { CardElement, Elements,} from "@stripe/react-stripe-js";
import { ElementsConsumer } from "@stripe/react-stripe-js";
import { addPaymentMethod } from '../../../../axios/ApiProvider';

const REACT_APP_STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY
const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY);

const CreditModalCard = (props) => {
  const languageStrs = useSelector((store) => store.editdata.languageStrs)
	const { stripe, elements, openAddPaymentDialog, handleCloseDialog, paymentMethodAdded } = props

	const handleAddPaymentMethod = async () => {
		if (elements == null) return

		let card_element = elements.getElement(CardElement)
    
		const params = { type: "card", card: card_element }

    const { error, paymentMethod } = await stripe.createPaymentMethod(params)

		if (error || !paymentMethod) {
      window.toastr.error("Add payment method failed. try again")
			return
		}

    await addPaymentMethod(paymentMethod);
    paymentMethodAdded()
	}

	return (
    <Dialog open={openAddPaymentDialog} onClose={handleCloseDialog} fullWidth>
      <DialogTitle>{languageStrs.add_payment_method}</DialogTitle>
      <DialogContent>
        <div className="flex-1 relative">
          <Box className="planModal-GridRight-body flex flex-col gap-10">
            <div className="flex flex-col gap-5">
              <label>
                {languageStrs.payment}
              </label>

              <Box className="planModal-rightBody-cardElementWrapper">
                <Box className="planModal-rightBody-cardElement">
                  <CardElement options={{ hidePostalCode: true }} />
                </Box>
              </Box>
            </div>
          </Box>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>{languageStrs.Cancel}</Button>
        <Button onClick={handleAddPaymentMethod}>{languageStrs.okay}</Button>
      </DialogActions>
    </Dialog>
	);
};

const CreditCard = (props) => {
	const { ModalPlanData, ModalStatus, openAddPaymentDialog, handleCloseDialog, paymentMethodAdded  } = props
	const { SetModalStatus, userPlan } = props

	return (
		<Elements stripe={stripePromise}>
			<ElementsConsumer>
				{({ stripe, elements }) => (
					<CreditModalCard
						stripe={stripe}
						elements={elements}
						userPlan={userPlan}
						ModalStatus={ModalStatus}
						ModalPlanData={ModalPlanData}
						SetModalStatus={SetModalStatus}
            openAddPaymentDialog={openAddPaymentDialog}
            handleCloseDialog={handleCloseDialog}
            paymentMethodAdded={paymentMethodAdded}
					/>
				)}
			</ElementsConsumer>
		</Elements>
	);
};

export default CreditCard
