import './videoocareer.scss'
import { Layouts } from '../../component/layouts/layouts'

export const Career3 = () => {
  return (
    <Layouts>
      <div className='flex flex-col items-center justify-center px-10 sm:px-20 md:px-30 lg:px-40'>
        <div className='w-full max-w-1000 flex flex-col gap-10 md:gap-20 lg:gap-30 py-30'>
          <div className='text-center text-20 md:text-30 lg:text-40 font-semibold'>
            C++ Software Developer, OpenGL
          </div>
          <div className='flex flex-col gap-10 mm:gap-20'>
            <div className='text-15 md:text-18'>
              We are seeking a skilled and experienced C++ Software Developer with expertise in OpenGL to join our team. As a Software Developer, you will be responsible for developing and maintaining high-performance applications using C++ and OpenGL. You will work on various projects, including graphics rendering, simulation, and virtual reality applications.

              This is an exciting opportunity to work on cutting-edge projects and contribute to the development of innovative software solutions. You will have the chance to collaborate with a talented team and leverage your C++ and OpenGL skills to create high-quality and visually stunning applications.
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Responsibilities:</span>
              <div className='text-15 md:text-18'>
                - Design, develop, and maintain C++ applications using OpenGL
              </div>
              <div className='text-15 md:text-18'>
                - Implement and optimize graphics rendering algorithms and techniques
              </div>
              <div className='text-15 md:text-18'>
                - Collaborate with cross-functional teams to understand project requirements and objectives
              </div>
              <div className='text-15 md:text-18'>
                - Conduct code reviews and ensure code quality and performance
              </div>
              <div className='text-15 md:text-18'>
                - Troubleshoot and debug software issues
              </div>
              <div className='text-15 md:text-18'>
                - Stay up-to-date with the latest industry trends and advancements in C++ and OpenGL
              </div>
              <div className='text-15 md:text-18'>
                - Assist with other software development tasks as needed
              </div>
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Qualifications:</span>
              <div className='text-15 md:text-18'>
                - Bachelor's degree in Computer Science, Software Engineering, or a related field
              </div>
              <div className='text-15 md:text-18'>
                - Strong proficiency in C++ programming language
              </div>
              <div className='text-15 md:text-18'>
                - Solid understanding of computer graphics principles and techniques
              </div>
              <div className='text-15 md:text-18'>
                - Experience with OpenGL or other graphics APIs
              </div>
              <div className='text-15 md:text-18'>
                - Familiarity with shader programming (GLSL)
              </div>
              <div className='text-15 md:text-18'>
                - Knowledge of software development best practices and design patterns
              </div>
              <div className='text-15 md:text-18'>
                - Strong problem-solving and analytical skills
              </div>
              <div className='text-15 md:text-18'>
                - Excellent communication and collaboration abilities
              </div>
              <div className='text-15 md:text-18'>
                - Portfolio or examples of previous work demonstrating proficiency in C++ and OpenGL development
              </div>
            </div>

            <div className='text-15 md:text-18'>
              If you are a talented C++ Software Developer with a passion for graphics programming and a desire to work on exciting projects, we would love to hear from you. Join our team and be part of a dynamic and innovative environment where your skills and expertise will contribute to the success of our software solutions.

              To apply, please submit your resume and portfolio to info@videoo.io. Please include "C++ Software Developer, OpenGL Application" in the subject line. Only shortlisted candidates will be contacted.

              We look forward to reviewing your application!
            </div>
          </div>
        </div>
      </div>
    </Layouts>

    )
}