export const VIDEOELEMENTS = 'VIDEOELEMENTS'
export const MOVEABLEDIVARRAY = 'MOVEABLEDIVARRAY'
export const SELECTEDITEMID = 'SELECTEDITEMID'
export const DATALOADED = 'DATALOADED'
export const WEBGLSIZE = 'WEBGLSIZE'
export const IMAGEELEMENTS = 'IMAGEELEMENTS'
export const SELECTEDGROUPID = 'SELECTEDGROUPID'
export const AUDIOELEMENTS = 'AUDIOELEMENTS'
export const SWITCHVIDEO = 'SWITCHVIDEO'

export const webgldata = {
  dataLoaded: true,
  webGlSize: {
    w: 1920,
    h: 1080
  },

  videoElements: {},
  audioElements: {},
  imageElements: {},
  moveableDivArray: {},
  selectedItemID: null,
  selectedGroupID: null,
  switchVideo: {
    isSwitching: false,
    videoID: ''
  },
}
