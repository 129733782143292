export const ZOOMRATE = 'ZOOMRATE';
export const TIMECOUNT = 'TIMECOUNT';
export const VIDEOPLAYING = 'VIDEOPLAYING';
export const VIDEOBUFFERING = 'VIDEOBUFFERING';
export const VIDEOPLAYSTARTED = 'VIDEOPLAYSTARTED';

export const videodata = {
  zoomRate: 0,
  videoPlaying: false,
  Time_Count: new Date(0),
  videoBuffering: false,
  videoPlayerStarted: false
}