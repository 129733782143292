import { Box } from '@mui/system';

import './resize-video.scss';
import UploadImg from './../../../assets/image/tools/upload-img.png';
import facebookLogo from "./../../../assets/image/Logos/facebook_logo.png";
import instagramLogo from "./../../../assets/image/Logos/instagram_logo.png";
import tiktokLogo from "./../../../assets/image/Logos/tiktok_logo.png";
import twitterLogo from "./../../../assets/image/Logos/twitter_logo.png";
import youtubeLogo from "./../../../assets/image/Logos/youtube_logo.png";
import whatsappLogo from "./../../../assets/image/Logos/whatsapp_logo.png";

import { createNewProject } from './../uploadHelper';
import { Footer } from '../../../component/home-footer/footer';
import { Header } from '../../../component/home-header/header';

export const BASE_STATIC_STORAGE_URL = process.env.REACT_APP_STATIC_STORAGE_URL;
export const how_to_1   = BASE_STATIC_STORAGE_URL + '/images/features/Add Your File Resize Video.png';
export const how_to_2   = BASE_STATIC_STORAGE_URL + '/images/features/Resize Video.png';
export const how_to_3   = BASE_STATIC_STORAGE_URL + '/images/features/Export Resize Video.png';

export const ResizeVideo = () => {
  return (
    <Box className="T_containner">
      <Box className="Header"><Header /></Box>

      <Box className="content-containner">
      <>
      <Box className='section-15'>
        <Box className='div-1232 _1300'>
          <Box className='breadcrumbs'>
            <a href={'/'}>
              <Box className='breadcrumb-text-block'>Videoo</Box>
            </a>
            <Box className='breadcrumb-arrow'></Box>
            <a href={'/'}>
              <Box className='breadcrumb-text-block'>Tools</Box>
            </a>
            <Box className='breadcrumb-arrow'></Box>
            <Box className='breadcrumb-text-block'>Video Resizer</Box>
          </Box>
        </Box>
      </Box>

      <Box className='section-9 directory std-hero-section'>
        <Box className='div-1232'>
          <Box className='hero-grid'>
            <Box className='hero-grid-text'>
              <Box className='hero-header-title'>Video Resizer</Box>
              <Box className='hero-content-title'>Free online video editing software - create videos online</Box>

              <Box onClick={() => {
                createNewProject("feature-resize-video")
              }} className='choose-btn'>
                <Box className='choose-btn-text'>Create Project</Box>
                <Box className='choose-btn-img'>
                  <img src={UploadImg} alt='uploadImg' />
                </Box>
              </Box>
            </Box>
            <Box className='hero-img'>
              <iframe
                  src={`https://www.youtube.com/embed/MXDZZ_9TO74`}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                  style={{margin: 'auto', width: '100%', aspectRatio: 1.7}}
              />
            </Box>
          </Box>

          {/* <Box className="logos-grid">
              <Box className="usedByLogos-item">
                  <img src={instagramLogo} alt="instagram logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={youtubeLogo} alt="youtube logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={facebookLogo} alt="facebook logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={twitterLogo} alt="twitter logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={tiktokLogo} alt="tiktok logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={whatsappLogo} alt="whatsapp logo" width={100} />
              </Box>
              {{usedLogs.map((logo, index) => (
                  <Box className="usedByLogos-item" key={index}>{logo}</Box>
              ))}}
          </Box> */}
        </Box>
      </Box>

      <Box className='wf-section'>
        <Box className='div-1232'>
          <Box className='div-block-67'>
            <h2 className="heading-19">Resize Video Online, Free!</h2>
            <Box className='text-section-block'>
              <ul className='text-section-ul'>
                <li className='py-10'>
                  Are you struggling to resize a video for your website or social media channels? Look no further than Videoo.io, a cloud-based online video editor that can help you resize your video quickly and easily.

                  Videoo.io offers a user-friendly interface that allows you to upload your video and resize it to your desired dimensions with just a few clicks. You can also crop your video, adjust the aspect ratio, and add text or other elements to enhance its quality.

                  Using Videoo.io to resize your video can also benefit your search engine optimization (SEO) efforts. By optimizing your video size, you can improve your website's loading speed, which is a crucial factor in SEO ranking. You can also reduce the server load and improve the user experience on your website.

                  With Videoo.io, you can easily resize your video on the go or in a time crunch. You don't need to purchase expensive software or hardware, and you can access the video editor from anywhere, anytime.

                  Resizing a video using Videoo.io is a simple process that can be completed in just a few steps. Follow these instructions to resize your video using the timeline feature:
                </li>

                <li className='py-10'>
                  1. Upload your video: Start by logging in to your Videoo.io account and uploading your video file to the editor.
                </li>
                <li className='py-10'>
                  2. Add the video to the timeline: Once your video is uploaded, drag and drop it onto the timeline. You will see the video appear as a series of clips on the timeline.
                </li>
                <li className='py-10'>
                  3. Select the clip to resize: Click on the clip you want to resize to select it. You will see a blue border appear around the clip to indicate that it is selected.
                </li>
                <li className='py-10'>
                  4. Resize the clip: To resize the clip, hover your cursor over one of the corners of the clip until you see a double arrow cursor. Click and drag the corner to resize the clip to your desired dimensions. You can also use the width and height values in the properties panel to resize the clip precisely.
                </li>
                <li className='py-10'>
                  5. Preview and save your resized video: Once you have resized the clip, preview your video to ensure that it looks the way you want it to. If you are happy with the result, save your resized video to your computer or directly to your social media or website.
                </li>
                <li className='py-10'>
                  In conclusion, using the timeline feature in Videoo.io to resize your video is a quick and easy process that can help improve your website's loading speed and overall SEO ranking. Follow these simple steps to resize your video and create high-quality, professional-looking content.
                </li>
              </ul>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className='wf-section'>
        <Box className='div-1232 wide _3'>
          <Box className='div-block-70'>
            <Box className='div-block-69'>
              <h2 className="heading-20">How to Resize a Video</h2>
            </Box>

            <Box className='div-block-68'>
              <Box className='how-to-div-item'>
                <img src={how_to_1} alt='howTo1' className="how-to-img" />
                <h3 className="heading-21 m-y-0">Add Your File</h3>
                <p className="how-to-content">Select your video and upload it. You can drag &amp; drop, or choose the file from your computer or mobile device</p>
              </Box>

              <Box className='how-to-div-item'>
                <img src={how_to_2} alt='howTo2' className="how-to-img" />
                <h3 className="heading-21 m-y-0">Resize Video</h3>
                <p className="how-to-content">Simply click on the video’s image and drag the corners to adjust. Or click ‘Settings’ and choose a preset canvas size (like ‘YouTube’, or ‘Instagram Stories’) to resize automatically</p>
              </Box>

              <Box className='how-to-div-item'>
                <img src={how_to_3} alt='howTo3' className="how-to-img" />
                <h3 className="heading-21 m-y-0">Share!</h3>
                <p className="how-to-content">That’s it. You’re ready to share your video with your friends and followers. Hit ‘Export’ and your video will start to download</p>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
      </Box>

      <Box className="footer-containner"><Footer /></Box>
    </Box >
   
  )
}