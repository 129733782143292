import './videoohowto.scss'
import { Layouts } from '../../../component/layouts/layouts'

export const Howto1 = () => {
  return (
    <Layouts>
      <div className='flex flex-col items-center justify-center px-10 sm:px-20 md:px-30 lg:px-40'>
        <div className='w-full max-w-1000 flex flex-col gap-10 md:gap-20 lg:gap-30 py-30'>
          <iframe
            src={`https://www.youtube.com/embed/YxZEcRy3Yvw`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
            style={{margin: 'auto', width: '100%', aspectRatio: 1.7}}
            
          />
          
          <div className='flex flex-col gap-10 mm:gap-20'>
            <div className='text-15 md:text-18'>
              Videoo.io is transforming the landscape of online video editing by offering an array of cutting-edge features that cater to both professional and casual editors. This cloud-based platform is a game-changer for content creators, YouTubers, and influencers who are in search of a powerful yet user-friendly editing workspace. Below is a step-by-step guide on how to utilize the AI-based auto subtitles feature on Videoo.io, along with a detailed look at its diverse capabilities
            </div>
          </div>
          <div className='text-center text-20 md:text-30 lg:text-40 font-semibold'>
            Step-by-Step Guide to Using AI-Based Auto Subtitles on Videoo.io
          </div>
          <div className='flex flex-col gap-10 mm:gap-20'>
            
            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Sign Up/Login:</span> Access Videoo.io and sign in to your account. New users can quickly sign up for free.
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Select a Project: </span> Click on 'Create New Project' or select an existing one from your dashboard.
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Upload Your Video:</span> Drag and drop your video file into the project. Videoo.io supports up to 4K video editing online, ensuring your content is of the highest quality.
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Auto Subtitles:</span> Navigate to the ‘Subtitles’ feature on the toolbar. Select ‘Auto-Generate’ to let the AI work its magic, creating accurate subtitles in multiple languages.
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Edit Subtitles:</span> Once the subtitles are generated, you can manually adjust them for precision. The interface is designed for collaborative video editing, allowing teams to work together seamlessly.
            </div>
            
            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Export:</span> With the high-resolution video export tool, you can download your subtitled video in up to 4K resolution. Whether it's for HDR content or a standard project, the quality is uncompromised.
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Share or Publish:</span> Directly share your video from Videoo.io to your chosen platform, or embed it into your website with ease.
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Online Collaborative Video Editing:</span> Videoo.io brings teams together in a collaborative environment, making it simpler to edit and review projects in real-time.
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Movie Maker Online Video Editing Software:</span> This platform combines the simplicity of a movie maker with advanced editing tools, suitable for all skill levels.
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Cloud-Based and 4K Video Editing:</span> Edit anywhere, anytime without compromising on quality. The cloud-based infrastructure supports 4K video editing, catering to professional standards.
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Software for Everyone:</span> Whether you’re an amateur or a professional, Videoo.io’s interface is intuitive, making it a video editing software tool for everyone.
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Templates for Quick Production:</span> Access a library of video editing templates designed to accelerate production without sacrificing originality.
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>HDR and High-Resolution Export:</span> Catering to the latest trends, Videoo.io supports HDR video editing and allows for high-resolution exports, ensuring your videos stand out.
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Multilingual and Multi-Language Support:</span> Break language barriers with the easy-to-use video editor that comes with auto-translate, perfect for global content creators.
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Reinvented Editing for Content Creators:</span> Videoo.io has reinvented video editing for YouTubers and influencers, offering tailored tools that enhance creativity and workflow.
            </div>

            <div className='text-15 md:text-18'>
            In summary, Videoo.io is not just an online video editor; it's a comprehensive, cloud-based video editing workspace designed for the modern creator. With its support for 4K editing, HDR content, multilingual capabilities, and AI-based features like auto subtitles, Videoo.io is poised to become the go-to platform for video editing across the globe.
            </div>
          </div>
        </div>
      </div>
    </Layouts>
  )
}