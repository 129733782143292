import { useState } from "react";
import { Box, Menu } from "@mui/material";

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined';
import { NewVideoInfo } from "./../dashboard.config";

export const NewVideoMenu = () => {
  const [MenuEl, setMenuEl] = useState(null);
  const isMenuOpen = Boolean(MenuEl);
  const MenuOpen = (event) => { setMenuEl(event.currentTarget); };
  const MenuClose = () => { setMenuEl(null); };
  return (
    <>
      <Box className="d-sidebar-newVideoBtn-contian">
        <Box className="d-sidebar-newVideoBtn" onClick={MenuOpen}>
          New Video <AddOutlinedIcon />
        </Box>

        <Menu
          aria-labelledby="demo-positioned-button"
          className="sidebar-newVideo-menu-contain sidebar-menu"
          anchorEl={MenuEl}
          open={isMenuOpen}
          onClose={MenuClose}
          sx={{ mt: 0.8 }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
          transformOrigin={{ vertical: 'top', horizontal: 'left', }}
        >
          <Box className="newVideo-menu-section">
            <Box className="newVideo-menu-items">
              {NewVideoInfo.map((item, index) => (
                <Box className="newVideo-menu-item" key={index}>
                  <Box className={`new-video-menu-item-icon ${item.iconclass}`}>
                    {item.icon}
                  </Box>
                  <span>{item.title}</span>
                </Box>
              ))}
            </Box>

            {/* <Box className="newVM-explore-template">
              <span>Explore Templates</span>
              <InboxOutlinedIcon />
            </Box> */}
          </Box>
        </Menu>
      </Box>
    </>
  )
}