import React from 'react'
import { Link } from 'react-router-dom'
import './videoocareer.scss';
import { Layouts } from '../../component/layouts/layouts';

export const BASE_STATIC_STORAGE_URL = process.env.REACT_APP_STATIC_STORAGE_URL;
export const career1Img = BASE_STATIC_STORAGE_URL + '/images/blog1.jpg';
export const career2Img = BASE_STATIC_STORAGE_URL + '/images/blog2.jpg'
export const career3Img = BASE_STATIC_STORAGE_URL + '/images/blog3.jpg'
export const career4Img = BASE_STATIC_STORAGE_URL + '/images/blog4.jpg'
export const career5Img = BASE_STATIC_STORAGE_URL + '/images/blog5.jpg'
export const career6Img = BASE_STATIC_STORAGE_URL + '/images/blog6.jpg';
export const career7Img = BASE_STATIC_STORAGE_URL + '/images/blog7.jpg'
export const career8Img = BASE_STATIC_STORAGE_URL + '/images/blog8.jpg'
export const career9Img = BASE_STATIC_STORAGE_URL + '/images/blog9.jpg'
export const career10Img = BASE_STATIC_STORAGE_URL + '/images/blog10.jpg'

const careersData = [
  {
    img: career1Img,
    link: '/careers/frontend-software-engineer-videoo',
    title: 'Frontend Software Engineer',
    description: 'Join our team at videoo.io as a Frontend Software Engineer. Develop and enhance our web and mobile applications, collaborate with cross-functional teams, and contribute to the user interface and user experience of our products. If you are passionate about frontend development and want to work in a dynamic and innovative environment, apply now and be part of our talented team.',
  },
  {
    img: career2Img,
    link: '/careers/graduate-digital-designer-videoo',
    title: 'Graduate Digital Designer',
    description: 'Join our team at videoo.io as a Graduate Digital Designer. Create visually appealing digital assets, collaborate with our development team, and contribute to our web and mobile applications. Kickstart your career in digital design and gain hands-on experience in a fast-paced environment. Apply now and be part of our innovative team shaping the user experience of our products.',
  },
  {
    img: career3Img,
    link: '/careers/cpp-software-developer-opengl-videoo',
    title: 'C++ Software Developer, OpenGL',
    description: 'Join videoo.io as a skilled C++ Software Developer with expertise in OpenGL. Develop high-performance graphics applications using OpenGL, collaborating with teams to enhance our cloud-based video editor. If you have a strong background in C++ development and a passion for real-time graphics, apply now to be part of our cutting-edge video editing platform.',
  },
  {
    img: career4Img,
    link: '/careers/cloud-devops-engineer-videoo',
    title: 'Cloud DevOps Engineer',
    description: 'Are you a skilled and experienced Cloud DevOps Engineer looking for an exciting opportunity to work with cutting-edge technologies? Join our team at videoo.io as a Cloud DevOps Engineer and play a crucial role in designing and implementing scalable and secure cloud infrastructure for our innovative video editing platform. As a Cloud DevOps Engineer, you will collaborate with our development and operations teams to automate deployments, monitor system performance, and ensure the reliability and availability of our cloud-based applications. Your expertise in cloud services, containerization, and CI/CD pipelines will contribute to the seamless operation and continuous improvement of our platform. If you are passionate about DevOps principles and enjoy working in a collaborative environment, this is the perfect role for you. Join us and be part of a dynamic team that is shaping the future of online video editing.',
  },
  {
    img: career5Img,
    link: '/careers/cloud-solution-architect-videoo',
    title: 'Cloud Solution Architect',
    description: 'Join our team at videoo.io as a Cloud Solution Architect and play a pivotal role in designing and implementing scalable and secure cloud-based solutions. As a Cloud Solution Architect, you will collaborate with cross-functional teams to architect and optimize our platform infrastructure for performance and cost-efficiency. Bring your expertise in cloud technologies and microservices to create innovative solutions that meet our customers needs. If you are a strategic thinker and thrive in a fast-paced environment, apply now and shape the future of cloud-based video editing.',
  },
  {
    img: career6Img,
    link: '/careers/software-engineer-in-test-videoo',
    title: 'Software Engineer in Test',
    description: 'Join our team at videoo.io as a Software Engineer in Test and be at the forefront of ensuring the quality and reliability of our cloud-based video editor. As a Software Engineer in Test, you will work closely with our development team to design and implement automated test strategies and frameworks. Your expertise in testing methodologies and tools will be essential in identifying and resolving software defects and ensuring a seamless user experience. If you have a passion for software quality and enjoy working in a collaborative and fast-paced environment, apply now and contribute to the success of our innovative video editing platform.',
  },
  {
    img: career7Img,
    link: '/careers/product-owner-videoo',
    title: 'Product Owner',
    description: 'Are you a passionate and driven individual with a strong product mindset? Join our team at videoo.io as a Product Owner and take ownership of our innovative cloud video editor. As a Product Owner, you will be responsible for defining and prioritizing product features, collaborating with cross-functional teams, and ensuring the successful delivery of our product roadmap. Your strategic vision and customer-centric approach will drive the evolution of our video editing platform and shape the user experience for millions of users worldwide. If you thrive in a fast-paced and dynamic environment and are eager to make a significant impact, we would love to hear from you. Join us and be part of a team that is revolutionizing the way videos are created and edited online.',
  },
  {
    img: career8Img,
    link: '/careers/chief-financial-officer-cfo-videoo',
    title: 'Chief Financial Officer (CFO)',
    description: 'Are you an experienced financial leader ready to make a strategic impact? We are seeking a Chief Financial Officer (CFO) to join our dynamic team at videoo.io. As the CFO, you will play a pivotal role in shaping our financial strategy and driving growth. Your expertise in financial planning, budgeting, and forecasting will be critical as we navigate the evolving landscape of the cloud video editing industry. If you are a visionary leader with a strong business acumen and a passion for driving financial success, we invite you to apply and help us lead videoo.io to new heights.',
  },
  {
    img: career9Img,
    link: '/careers/backend-software-engineer-videoo',
    title: 'Backend Software Engineer',
    description: 'You will be responsible for developing robust and scalable backend systems, collaborating with cross-functional teams to optimize performance and enhance user experience. If you are passionate about backend development and want to work on innovative video editing solutions, apply now and be part of our talented engineering team. Let us create the next generation of online video editing together.',
  },
  {
    img: career10Img,
    link: '/careers/video-producer-online-editor-videoo',
    title: 'Video Producer for Online Video Editor',
    description: 'Join our team at videoo.io as a Video Producer for our cutting-edge cloud video editor. You will oversee video content production, ensuring top-notch quality and engaging user experiences. If you are passionate about video production and want to shape the future of online video editing, apply now! Lets revolutionize the way people create and share videos together.',
  },
]

export const Career = () => {
  return (
    <Layouts>
      <div className='blog-wrapper'>
        <div className='px-10 text-center py-20 md:py-30 lg:py-40'>
          <span className='text-30 md:text-45 lg:text-55 font-bold leading-8'>
            Videoo Careers Page
          </span>
        </div>

        <div className='blog-container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl2:grid-cols-4 gap-10 md:gap-20 mm:gap-30'>
          {careersData.map((careersData, key) => (
            <Link to={careersData.link} key={key}
              className='blog-item flex flex-col gap-20 p-15 rounded-md'
            >
              <img alt='blog'
                src={careersData.img}
                className='w-full'
              />

              <div className='flex flex-col gap-10 py-5'>
                <div className='text-20 md:text-25 font-semibold leading-8'>
                  {careersData.title}
                </div>

                <div className='text-12 md:text-15'>
                  {careersData.description}
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </Layouts>
  )
}