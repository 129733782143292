import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Grid, Box, Typography, Modal } from "@mui/material";
import Swal from 'sweetalert2';
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import { ElementsConsumer, AddressElement } from "@stripe/react-stripe-js";

import { ThreeDots } from 'react-loading-icons';
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { HelperIcon, GmailIcon, OutlookIcon } from "../../Icons";

import { CreditSubscribe } from "./../../axios/ApiProvider";
import { EmailValidate } from '../../provider/validate/FormValidate';

import { GetSelectedPlan, SetSelectedPlan } from "./../../pages/Pricing/pricing.config"

const REACT_APP_STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY
const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY);

const CreditModalCard = (props) => {
	const loginState = useSelector((store) => store.maindata.loginState);
	const {discoundVal} = props;
	const {coupon} = props;
	const {freTrial} = props;
	const { stripe, elements } = props
	const { SetModalStatus, userPlan } = props
	const { ModalPlanData, ModalStatus } = props
	const planModalClose = () => SetModalStatus(false);
	const [ModalplanMonthly, setModalplanMonthly] = useState(false);
	const ModalPlanchange = val => { if (!isSubmit) setModalplanMonthly(val); }

	const creditForm = useRef()
	const [ErrMsg, SetErrMsg] = useState("")
	const [isSubmit, SetisSubmit] = useState(false)
	const [SuccessSub, SetSuccessSub] = useState(false)

	const [userData, setUserData] = useState({})
	useEffect(() => {
		let tempData = {}
		let item = localStorage.getItem('user_data')
		if (!!item && JSON.parse(item)?.state === 'loggedin') {
			tempData = JSON.parse(item)
		}
		console.log('userData', tempData);
		setUserData(tempData)
	}, [])

	useEffect(() => {
		let tempData = {}
		let item = localStorage.getItem('user_data')
		if (!!item && JSON.parse(item)?.state === 'loggedin') tempData = JSON.parse(item)
		setUserData(tempData)
		console.log('this is for user login', loginState);
		console.log('login stateVal', tempData);
	}, [loginState])

	const [isAddr, setIsAddr] = useState(false)
	const onChange = ({ currentTarget }) => {
		setIsAddr(!!currentTarget?.checked)
	}

	const handleSubmit = async (event) => {
		event.preventDefault()
		
		if (elements == null) return

		SetErrMsg("")
		SetisSubmit(true)

		let card_element = elements.getElement(CardElement)
		let addressElement = elements.getElement(AddressElement)
		let checkemail = EmailValidate(userData?.email)

		const params = { type: "card", card: card_element }
		const { error, paymentMethod } = await stripe.createPaymentMethod(params)

		if (error || !paymentMethod) {
			submitFailed(error.message);
			return
		}

		let selectPlan = Number(GetSelectedPlan()) || 0
		if (!ModalplanMonthly) {
			selectPlan += 3
		}
		console.log('userDataInformation', userData);
		let creditInfo = {
			product: selectPlan,
			email: checkemail,
			card: paymentMethod,
			card_element: card_element,
			billingDetails: {},
			payment_method_id: paymentMethod.id,
			plan: ModalplanMonthly ? "monthly" : "annualy"
		};

		if (isAddr) {
			let addressDetails = await addressElement.getValue()
			creditInfo.billingDetails = addressDetails.value
		}

		let SubRes = await CreditSubscribe(creditInfo, ModalplanMonthly, stripe, userData, coupon, freTrial);
		if (SubRes.state === 'error') {
			submitFailed(SubRes.data.message)
			return
		}

		SetisSubmit(false)
        SetSuccessSub(false)
              
		return
	}

	const submitFailed = (msg) => {
		SetisSubmit(false)
		SetErrMsg(msg)
	}

	return (
		<Modal open={ModalStatus}
			onClose={planModalClose}
			className="planModal-section"
		>
			<Box className="planModal-box-contain">
				<Box className="planMoal-box">
					<CloseIcon className="planModal-closeBtn" onClick={planModalClose} />

					<Box className="planModal-container">
						<Box sx={{ display: "flex", minHeight: "100%" }}>
							<Box sx={{ width: "100%" }}>
								<Box className="planModal-containerGrid">
									<Box className="planModal-containerGrid-left">
										<Box className="planModal-GridLeft-backbtn">
											<Box className="planModal-GridLeft-Btn" onClick={planModalClose}>
												<ArrowBackIcon />
											</Box>
										</Box>

										<Box className="planModal-GridLeft-hader">
											<img src={ModalPlanData.icon_path} alt='icon-path' />

											<div>
												<Box className="planModal-GridLeft-haderTitle">
													{ModalPlanData.name}
												</Box>

												<Box className="planModal-GridLeft-haderContent">
													{ModalPlanData.description}
												</Box>
											</div>
										</Box>

										<Box className="planModal-GridLeft-body">
											<Box className="planModal-GridLeft-items-contain">
												<Box className="planModal-GridLeft-bodyitems">
													{!ModalPlanData.status && (
														ModalPlanData.modalInfo.lists.map((item, index) => (
															<Box className="planModal-GridLeft-bodyitem" key={index}>
																<Box className="planModal-GridLeft-bodyitemIcon">
																	{ModalPlanData.modalInfo.checkIcon}
																</Box>

																{index === ModalPlanData.modalInfo.planIndex ? (
																	ModalplanMonthly ? (
																		`${item} ${ModalPlanData.modalInfo.planmin} min/mo`
																	) : (
																		`${item} ${ModalPlanData.modalInfo.planmin * 12} min/yr`
																	)
																) : (item)}
															</Box>
														))
													)}
												</Box>
											</Box>
										</Box>

										<Box className="planModal-GridLeft-footer">
											<Box className="planModal-GridLeftFooter-helperbtn">
												<HelperIcon /> Help
											</Box>

											<Link to={`#`} className="planModal-GridLeftFooter-link">
												Compare all plans<KeyboardArrowRightIcon />
											</Link>
										</Box>
									</Box>

									<Box className="planModal-containerGrid-right">
										<Typography className="planModal-GridRight-title">
											Upgrade to {ModalPlanData.name}
										</Typography>

										<Box className="planModal-GridRight-contain gap-20">
											<Typography className={`planModal-GridRight-planIntervaltitle ${isSubmit && ('subscription-disable')}`}>
												Billing Period
											</Typography>

											<Box className={`planModal-GridRight-planIntervalGrid ${isSubmit ? 'subscription-disable' : ''}`}>
												<Box onClick={() => { ModalPlanchange(false); }}
													className={`planModal-GridRight-planInterval-Item${!ModalplanMonthly ? "Active" : ""}`}
												>
													<div>
														<Box className="planModal-planInterval-group">
															<Box className="planModal-planInterval-groupCircle" />

															<Typography className="planModal-planInterval-groupTitle">
																Pay annually <span>Save {ModalPlanData.saveUp}%</span>
															</Typography>
														</Box>

														<Box className="planModal-planInterval-calculation">
															<Typography className="planModal-calculation-baseprice">
																${(ModalPlanData.Annuallyprice * discoundVal).toFixed(2)}
															</Typography>
															x 1 editor x 12 months
														</Box>
													</div>
												</Box>

												<Box onClick={() => { ModalPlanchange(true); }}
													className={`planModal-GridRight-planInterval-Item${ModalplanMonthly ? "Active" : ""}`}
												>
													<div>
														<Box className="planModal-planInterval-group">
															<Box className="planModal-planInterval-groupCircle" />

															<Typography className="planModal-planInterval-groupTitle">
																Pay monthly
															</Typography>
														</Box>

														<Box className="planModal-planInterval-calculation">
															<Typography className="planModal-calculation-baseprice">
																${(ModalPlanData.monthlyprice * discoundVal).toFixed(2)}
															</Typography>
															x 1 editor per month
														</Box>
													</div>
												</Box>
											</Box>

											<form ref={creditForm}
												onSubmit={handleSubmit}
												className='flex-1 flex flex-col gap-15'
											>
												<div className="flex flex-row gap-10 items-center">
													<input
														type='checkbox'
														name="isAddress"
														checked={isAddr}
														onChange={onChange}
													/>

													<label>Enable Billing Address</label>
												</div>

												<div className="flex-1 relative">
													<Box className="planModal-GridRight-body flex flex-col gap-10">

														<Box className={`planModal-rightBody-inputgrid ${isSubmit && ('subscription-disable')}`}>
															{loginState && 
																<Box className="planModal-rightBody-inputItem">
																	<input readOnly
																		disabled={isSubmit} name="email"
																		placeholder="Enter your email address here"
																		value={userData?.email}
																		onChange={() => { }}
																	/>

																	<label>Email Address</label>
																</Box>
															}
															{!loginState && 
																<Grid container spacing={4}>
																	<Grid item md={12}>
																	<Box className="planModal-rightBody-inputItem">
																		<input
																			type="email"
																			name="email"
																			placeholder="Enter your email address here"
																			value={userData?.email}
																			onChange={(e) => { setUserData({...userData, email: e.target.value}); }}
																			required
																		/>

																		<label>Email Address</label>
																	</Box>
																	</Grid>
																	<Grid item md={6}>
																		<Box className="planModal-rightBody-inputItem">
																		<input 
																			placeholder="Enter your name"
																			value={userData?.name}
																			onChange={(e) => { setUserData({...userData, name: e.target.value}); }}
																			required
																		/>
																		<label>Name</label>
																		</Box>
																	</Grid>
																	<Grid item md={6}>
																		<Box className="planModal-rightBody-inputItem">
																		<input 
																			placeholder="Enter your Surname"
																			value={userData?.surname}
																			onChange={(e) => { setUserData({...userData, surname: e.target.value}); }}
																			required
																		/>
																		<label>Surname</label>
																		</Box>
																	</Grid>
																</Grid>															
															}
														</Box>

														<div className="flex flex-col gap-5">
															<label className={`${isSubmit && ('subscription-disable')}`}>
																Payment Option
															</label>

															<Box className="planModal-rightBody-cardElementWrapper">
																<Box className="planModal-rightBody-cardElement">
																	<CardElement options={{ hidePostalCode: true, disabled: isSubmit }} />
																</Box>
															</Box>
														</div>

														{!!isAddr && (
															<AddressElement
																options={{
																	mode: 'billing',
																	defaultValues: {
																		name: loginState? userData?.firstname + ' ' + userData?.lastname : userData.name,
																	},
																}}
															/>
														)}
													</Box>
												</div>

												<Box className={`planModal-GridRight-footer`}>
													<Box className="planModal-rightFooter-ErrTxt">
														<Typography className="planModal-ErrText">
															{ErrMsg}
														</Typography>
													</Box>

													{SuccessSub ? (
														<Box className="rightFooter-subscription-Error">
															<Box className="Subscription-left">
																<span className="Subscription-left-header">
																	Payment was not successful
																</span>

																<div className="Subscription-left-content">
																	Your account has been created. We've sent you an email link where you can log in and try again.
																</div>

																<div className="Subscription-left-footer">
																	Haven't received the email?
																	<span className="Subscription-sendAgain">
																		Send Email Again
																	</span>
																</div>
															</Box>

															<Box className="Subscription-right">
																<Link to={``} className="Subscription-EmailIcon-Err">
																	<GmailIcon /> Open Gmail
																</Link>

																<Link to={``} className="Subscription-EmailIcon-Err">
																	<OutlookIcon /> Open Outlook
																</Link>
															</Box>
														</Box>
													) : (
														<>
															<Box className="planModal-rightFooter-group">
																{isSubmit ? (
																	<Box className="planModal-rightFooter-submitBtn">
																		Please wait ...
																		<ThreeDots speed={0.8} width={50} />
																	</Box>
																) : (
																	<button type="submit" disabled={!stripe} className="planModal-rightFooter-submitBtn">
																		Subscribe Now
																		<Typography className="planModal-submitBtn-price">
																			${ModalplanMonthly ? (ModalPlanData.monthlyprice * discoundVal).toFixed(2) : (ModalPlanData.Annuallyprice * discoundVal * 12).toFixed(2)} (USD)
																		</Typography>

																	</button>
																)}

																<Box className="planModal-CancelBtn" onClick={() => { if (!isSubmit) planModalClose(); }}>
																	Cancel
																</Box>
															</Box>

															<Box className="planModal-rightFooter-footerTxt">
																By clicking on subscribe, you agree to our
																<Link to={`/terms`}> Terms </Link>
																and <Link to={`/privacy`}> Privacy Policy </Link>.
															</Box>
														</>
													)}
												</Box>
											</form>
										</Box>
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Modal >
	);
};

export const CreditModal = (props) => {
	const { ModalPlanData, ModalStatus } = props
	const { SetModalStatus, userPlan } = props
	const {discoundVal} = props
	const {coupon} = props
	const {freTrial} = props
	return (
		<Elements stripe={stripePromise}>
			<ElementsConsumer>
				{({ stripe, elements }) => (
					<CreditModalCard
						stripe={stripe}
						elements={elements}
						userPlan={userPlan}
						discoundVal={discoundVal}
						coupon={coupon}
						freTrial={freTrial}
						ModalStatus={ModalStatus}
						ModalPlanData={ModalPlanData}
						SetModalStatus={SetModalStatus}
					/>
				)}
			</ElementsConsumer>
		</Elements>
	);
};
