import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { tips } from '../../../../util'
import { ChangeWorkspace, TransferModal } from "../../../modal/confirm"
import { AddMemberModal } from "../../../modal/confirm"
import { useGlobalContext } from "../../../../provider"
import { ChangeMemberLevel, ConfirmModal } from "../../../modal/confirm"
import { ShareProject, memberLevelChange, createWorkspace, deleteWorkspace, getMembersList, getProjectList, getWorkspacesList, removeMember, transferOwner, getInvitationList, removeInvitation, ChangeProjectWorkspace } from "../../../../axios/ApiProvider"

export const WorkSpace = () => {
  const [globalState, { onLoading }] = useGlobalContext()
  const languageStrs = useSelector((store) => store.editdata.languageStrs);
  const [workSpacesList, setWorkSpacesList] = useState([])
  const [activeWorkSpace, setActiveWorkSpace] = useState(null)
  const [isOwner, setisOwner] = useState(false)

  const [transferModal, setTransferModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [resetState, setResetState] = useState(false)

  useEffect(() => {
    if (!!globalState.userData) {
      getWorkSpaces()
    }
  }, [globalState.userData])

  useEffect(() => {
    let tempOwner = false
    let userData = globalState.userData

    const tempData = workSpacesList.find((item) => (
      item.uuid === activeWorkSpace
    ))


    if (userData && tempData) {
      if (userData.email === tempData.user) {
        tempOwner = true
      }
    }

    if (tempOwner !== isOwner) {
      setisOwner(tempOwner)
    }
  }, [activeWorkSpace, globalState.userData])

  const getWorkSpaces = async () => {
    try {
      const result = await getWorkspacesList()

      if (result.state === 'success') {
        if (typeof result.data === 'object') {
          setWorkSpacesList(result.data)
        }
      }

      return true
    } catch (err) {
      console.log(err.message)
      return false
    }
  }

  const changeAciveUUID = (uuid) => {
    if (activeWorkSpace !== uuid) {
      setActiveWorkSpace(uuid)
    }
  }

  const onCreate = async () => {
    onLoading(true)

    try {
      let result = await createWorkspace()

      if (result.state === 'success') {
        tips("success", result.data)
        await getWorkSpaces()
      }

      onLoading(false)
      return true
    } catch (err) {
      console.log(err.message)
      onLoading(false)
      return false
    }
  }

  const onDelete = async () => {
    onLoading(true)

    try {
      if (!activeWorkSpace || typeof activeWorkSpace !== 'string') {
        throw new Error("worksapce uuid error")
      }

      let result = await deleteWorkspace(activeWorkSpace)

      if (result.state === 'success') {
        tips("success", result.data)
        setActiveWorkSpace(null)
        await getWorkSpaces()
      }

      onLoading(false)
      return true
    } catch (err) {
      console.log(err.message)
      onLoading(false)
      return false
    }
  }

  const onTransfer = async (email) => {
    onLoading(true)

    try {
      if (!activeWorkSpace || typeof activeWorkSpace !== 'string') {
        throw new Error("worksapce uuid error")
      }

      if (email === globalState.userData.email) {
        throw new Error('can`t transfer to this email')
      }

      let result = await transferOwner(activeWorkSpace, email)

      if (result.state === 'success') {
        tips("success", 'owner transfer successed')
        setActiveWorkSpace(null)
        await getWorkSpaces()
      }

      onLoading(false)
      return true
    } catch (err) {
      console.log(err.message)
      onLoading(false)
      return false
    }
  }

  return (
    <div className="v-scroll w-full h-full flex flex-col gap-20 px-15 md:px-20 py-10 overflow-y-auto">
      <div className="flex flex-col gap-10 pt-30">
        <span className="">{languageStrs.workspace}</span>

        <div className="flex flex-col-reverse md:grid md:grid-cols-6 gap-5 md:gap-20">
          <div className="md:col-span-4 flex flex-col justify-center border border-black/50">
            {workSpacesList.map((workSpace, key) => (
              <div onClick={() => { changeAciveUUID(workSpace.uuid) }} key={key}
                className={`flex flex-row gap-10  px-15 md:px-30 py-5 cursor-pointer ${activeWorkSpace === workSpace.uuid && ('text-green-600')}`}
              >
                {key + 1}. {`${workSpace?.title} (${workSpace?.uuid})`}
              </div>
            ))}
          </div>

          <div className="md:col-span-2 flex flex-col flex-wrap gap-5 md:gap-15 justify-center md:py-10">
            <CustomButton onClick={() => { setConfirmModal(true) }}>
              {languageStrs.create_workspace}
            </CustomButton>

            {(isOwner && activeWorkSpace) && (
              <CustomButton onClick={() => { setDeleteModal(true) }}>
                {languageStrs.delete_workspace}
              </CustomButton>
            )}

            {(isOwner && activeWorkSpace) && (
              <CustomButton onClick={() => { setTransferModal(true) }}>
                {languageStrs.transfer_owner}
              </CustomButton>
            )}
          </div>
        </div>
      </div>

      {activeWorkSpace && (
        <MembersList
          isOwner={isOwner}
          workspace_uuid={activeWorkSpace}
          userEmail={globalState.userData?.email}
          getWorkSpaces={getWorkSpaces}
          setResetState={setResetState}
          resetState={resetState}
        />
      )}

      {activeWorkSpace && (
        <InvitationList
          isOwner={isOwner}
          resetState={resetState}
          workspace_uuid={activeWorkSpace}
        />
      )}

      {activeWorkSpace && (
        <ProjectsList
          isOwner={isOwner}
          workspaces={workSpacesList}
          workspace_uuid={activeWorkSpace}
          getWorkSpaces={getWorkSpaces}
        />
      )}

      {/* create confirm modal */}
      <ConfirmModal title="Create WorkSpace"
        content="Could you create new workspace?"
        onClose={() => { setConfirmModal(false) }}
        openState={confirmModal}
        onConfirm={onCreate}
      />

      {/* delete confirm modal */}
      <ConfirmModal title="Delete WorkSpace"
        content="Could you delete workspace?"
        onClose={() => { setDeleteModal(false) }}
        openState={deleteModal}
        onConfirm={onDelete}
      />

      {/* transfer owner modal */}
      <TransferModal onTransfer={onTransfer}
        onClose={() => { setTransferModal(false) }}
        openState={transferModal}
      />
    </div>
  )
}

export const MembersList = (props) => {
  const { setResetState, resetState } = props
  const languageStrs = useSelector((store) => store.editdata.languageStrs);
  const { getWorkSpaces, workspace_uuid, userEmail, isOwner } = props
  const [globalState, { onLoading }] = useGlobalContext()

  const [members, setMembers] = useState([])
  const [isAdmin, setisAdmin] = useState(false)
  const [activeMember, setActiveMember] = useState(null)
  const [activeInfo, setActiveInfo] = useState(null)

  const [changeLevel, setChangelevel] = useState(false)
  const [addMemberModal, setAddMemberModal] = useState(false)
  const [removeMemberModal, setRemoveMemberModal] = useState(false)

  useEffect(() => {
    setMembers([])
    setActiveMember(null)
    getMembersData(workspace_uuid)
  }, [workspace_uuid])

  useEffect(() => {
    let tempInfo = null
    let tempAdmin = false

    let checkMember = members.find((member) => (
      member.uuid === activeMember
    ))

    let userInfo = members.find((member) => (
      member.user === userEmail
    ))

    if (checkMember && userInfo) {
      if (checkMember.user !== userEmail) {
        if (checkMember.level > 0) {
          tempAdmin = true
        }
      }
    }

    if (checkMember) {
      tempInfo = checkMember
    }

    setActiveInfo(tempInfo)

    if (isAdmin !== tempAdmin) {
      setisAdmin(tempAdmin)
    }
  }, [activeMember])

  const getMembersData = async (workspace_uuid) => {
    try {
      const result = await getMembersList(workspace_uuid)

      if (result.state === 'success') {
        if (typeof result.data === 'object') {
          setMembers(result.data)
          console.log("🚀 members data", result.data)
        }
      }
    } catch (err) {
      console.log(err.message)
    }
  }

  const changeActiveMember = (member) => {
    if (member.uuid !== activeMember) {
      setActiveMember(member.uuid)
    }
  }

  const onAddMember = async (email) => {
    onLoading(true)

    try {
      if (!workspace_uuid || typeof workspace_uuid !== 'string') {
        throw new Error("worksapce uuid error")
      }

      if (email === userEmail) {
        throw new Error('can`t add this email')
      }

      let result = await ShareProject(workspace_uuid, email, "")

      if (result.state === 'success') {
        tips("success", 'add new member successed')
        await getMembersData(workspace_uuid)
        setResetState(!resetState)
      }

      onLoading(false)
      return true
    } catch (err) {
      console.log(err.message)
      onLoading(false)
      return false
    }
  }

  const onRemoveMember = async () => {
    onLoading(true)

    try {
      if (!workspace_uuid || typeof workspace_uuid !== 'string') {
        throw new Error("worksapce uuid error")
      }

      if (!isAdmin) {
        throw new Error('can`t remove this member')
      }

      let checkMember = members.find((member) => (
        member.uuid === activeMember
      ))

      if (!checkMember) {
        throw new Error('member not exists')
      }

      let result = await removeMember(workspace_uuid, checkMember.user)

      if (result.state === 'success') {
        tips("success", 'remove member successed')
        setActiveMember(null)
        await getMembersData(workspace_uuid)
      }

      onLoading(false)
      return true
    } catch (err) {
      tips("warning", err.message)
      onLoading(false)
      return false
    }
  }

  const onChangeLevel = async (level) => {
    onLoading(true)

    try {
      if (!workspace_uuid || typeof workspace_uuid !== 'string') {
        throw new Error("worksapce uuid error")
      }

      if (!isAdmin) {
        throw new Error('can`t remove this member')
      }


      let checkMember = members.find((member) => (
        member.uuid === activeMember
      ))

      if (!checkMember) {
        throw new Error('member not exists')
      }

      let result = await memberLevelChange(workspace_uuid, checkMember.user, level)

      if (result.state === 'success') {
        tips("success", result.data)
        await getMembersData(workspace_uuid)
      }

      onLoading(false)
      return true
    } catch (err) {
      tips("warning", err.message)
      onLoading(false)
      return false
    }
  }

  return (
    <div className="flex flex-col gap-10 pt-30">
      <span className="">{languageStrs.members}</span>

      <div className="flex flex-col-reverse md:grid md:grid-cols-6 gap-5 md:gap-20">
        <div className="md:col-span-4 flex flex-col justify-center border border-black/50">
          {members.map((member, key) => (
            <div onClick={() => { changeActiveMember(member) }} key={key}
              className={`flex flex-row gap-10  px-15 md:px-30 py-5 cursor-pointer ${member.uuid === activeMember && ('text-green-600')}`}
            >
              <span>{key + 1}. {member.user}</span>

              <span className="underline">
                {member.level === 0 && ('('+languageStrs.owner+')')}
                {member.level === 1 && ('('+languageStrs.admin+')')}
                {member.level === 2 && ('('+languageStrs.member+')')}
                {member.level === 3 && ('('+languageStrs.read_only+')')}
              </span>
            </div>
          ))}
        </div>

        <div className="md:col-span-2 flex flex-col flex-wrap gap-5 md:gap-15 justify-center md:py-10">
          {isOwner && (
            <CustomButton onClick={() => { setAddMemberModal(true) }}>
              {languageStrs.add_member}
            </CustomButton>
          )}

          {(isAdmin && activeMember) && (
            <CustomButton onClick={() => { setRemoveMemberModal(true) }}>
              {languageStrs.remove_member}
            </CustomButton>
          )}

          {(isAdmin && activeMember) && (
            <CustomButton onClick={() => { setChangelevel(true) }}>
              {languageStrs.change_member_level}
            </CustomButton>
          )}

          <div className="flex flex-row">
            <div className="max-w-150 w-full flex flex-col px-10">
              <div className="">1. {languageStrs.owner}</div>
              <div className="">2. {languageStrs.admin}</div>
              <div className="">3. {languageStrs.member}</div>
              <div className="">4. {languageStrs.read_only}</div>
            </div>
          </div>
        </div>
      </div>

      {/* add member modal */}
      <AddMemberModal openState={addMemberModal}
        onClose={() => { setAddMemberModal(false) }}
        onAddMember={onAddMember}
      />

      {/* remove member modal */}
      <ConfirmModal title="Remove Member"
        content="Could you remove this member?"
        onClose={() => { setRemoveMemberModal(false) }}
        openState={removeMemberModal}
        onConfirm={onRemoveMember}
      />

      {/* change member level modal */}
      <ChangeMemberLevel openState={changeLevel}
        onClose={() => { setChangelevel(false) }}
        onChangeLevel={onChangeLevel}
        memberData={activeInfo}
      />
    </div>
  )
}

export const InvitationList = (props) => {
  const languageStrs = useSelector((store) => store.editdata.languageStrs);
  const { resetState, workspace_uuid, isOwner } = props
  const [globalState, { onLoading }] = useGlobalContext()

  const [invitations, setinvitations] = useState([])
  const [activeInvite, setActiveInvite] = useState(null)
  const [removeModal, setRemoveModal] = useState(false)

  useEffect(() => {
    setinvitations([])
    setActiveInvite(null)
    getInvitationData(workspace_uuid)
  }, [workspace_uuid, resetState])

  const getInvitationData = async (workspace_uuid) => {
    try {
      const result = await getInvitationList(workspace_uuid)

      if (result.state === 'success') {
        if (typeof result.data === 'object') {
          setinvitations(result.data)
          console.log("🚀 getInvitationData", result.data)
        }
      }

      return true
    } catch (err) {
      console.log(err.message)
      return false
    }
  }

  const changeActive = (invitation) => {
    if (invitation.uuid !== activeInvite) {
      setActiveInvite(invitation.uuid)
    }
  }

  const removeConfirm = async () => {
    try {
      onLoading(true)

      if (!workspace_uuid || typeof workspace_uuid !== 'string') {
        throw new Error("worksapce uuid error")
      }

      if (!isOwner) {
        throw new Error('Can`t remove this member')
      }

      let checkMember = invitations.find((invitation) => (
        invitation.uuid === activeInvite
      ))

      if (checkMember?.email) {
        let result = await removeInvitation(workspace_uuid, checkMember.email)

        if (result.state === 'success') {
          setActiveInvite(null)
          await getInvitationData(workspace_uuid)
          tips("success", 'remove member successed')
        }
      } else {
        throw new Error('member not exists')
      }

      onLoading(false)
      return true
    } catch (err) {
      tips("warning", err.message)
      onLoading(false)
      return false
    }
  }

  return (
    <div className="flex flex-col gap-10 pt-30">
      <span className="">{languageStrs.invitation_list}</span>

      <div className="flex flex-col-reverse md:grid md:grid-cols-6 gap-5 md:gap-20">
        <div className="md:col-span-4 flex flex-col justify-center border border-black/50 py-10 md:py-20">
          {invitations.map((invitation, key) => (
            <div onClick={() => { changeActive(invitation) }} key={key}
              className={`flex flex-row gap-10  px-15 md:px-30 py-5 cursor-pointer ${invitation.uuid === activeInvite && ('text-green-600')}`}
            >
              <span>{key + 1}. {invitation.email}</span>
            </div>
          ))}
        </div>

        <div className="md:col-span-2 flex flex-col flex-wrap gap-5 md:gap-15 justify-center md:py-10">
          {(!!activeInvite && isOwner) && (
            <CustomButton onClick={() => { setRemoveModal(true) }}>
              {languageStrs.remove_invitation}
            </CustomButton>
          )}
        </div>
      </div>

      {/* remove invitation modal */}
      <ConfirmModal title="Remove Invitation"
        content="Could you remove this invitation?"
        onClose={() => { setRemoveModal(false) }}
        onConfirm={removeConfirm}
        openState={removeModal}
      />
    </div>
  )
}

export const ProjectsList = (props) => {
  const languageStrs = useSelector((store) => store.editdata.languageStrs);
  const { getWorkSpaces, workspaces, workspace_uuid, isOwner } = props
  const [globalState, { onLoading }] = useGlobalContext()

  const [projectList, setProjectList] = useState([])
  const [active_uuid, setActive_uuid] = useState(null)
  const [openState, setOpenState] = useState(false)

  useEffect(() => {
    setProjectList([])
    setActive_uuid(null)
    getProjectData(workspace_uuid)
  }, [workspace_uuid])

  const getProjectData = async (workspace_uuid) => {
    try {
      const result = await getProjectList(workspace_uuid)

      if (result.state === 'success') {
        if (typeof result.data === 'object') {
          setProjectList(result.data)
          console.log("🚀 project data:", result.data)
        }
      }
    } catch (err) {
      console.log(err.message)
    }
  }

  const changeActive_uuid = (project) => {
    if (project.uuid !== active_uuid) {
      setActive_uuid(project.uuid)
    }
  }

  const onChange = async (uuid) => {
    try {
      const checkUUID = workspaces.find((workspace) => (
        workspace.uuid === uuid
      ))

      if (active_uuid && checkUUID && uuid !== workspace_uuid) {
        onLoading(true)

        let result = await ChangeProjectWorkspace(active_uuid, workspace_uuid, uuid)
        if (result.state === 'success') {
          setOpenState(false)
          setActive_uuid(null)
          await getWorkSpaces()

          onLoading(false)
          tips("success", 'workspace change successed')
        } else {
          throw new Error('workspace change failure!')
        }
      }
    } catch (err) {
      tips("warning", err.message)
      onLoading(false)
    }
  }

  return (
    <div className="flex flex-col gap-10 pt-30">
      <span className="">Projects</span>

      <div className="flex flex-col-reverse md:grid md:grid-cols-6 gap-20">
        <div className="md:col-span-4 flex flex-col justify-center border border-black/50 py-20">
          {projectList.map((project, key) => (
            <div onClick={() => { changeActive_uuid(project) }} key={key}
              className={`flex flex-row gap-10  px-15 md:px-30 py-5 cursor-pointer ${project.uuid === active_uuid && ('text-green-600')}`}
            >
              {key + 1}. {`${project?.title} (${project?.uuid})`}
            </div>
          ))}
        </div>

        <div className="md:col-span-2 flex flex-col flex-wrap gap-5 md:gap-15 justify-center md:py-10">
          {(isOwner && active_uuid) && (
            <CustomButton onClick={() => { setOpenState(true) }}>
              {languageStrs.change_workspace}
            </CustomButton>
          )}
        </div>
      </div>

      {isOwner && (
        <ChangeWorkspace
          openState={openState}
          workspaces={workspaces}
          workspace={workspace_uuid}
          onClose={() => { setOpenState(false) }}
          onChange={onChange}
        />
      )}
    </div>
  )
}

const CustomButton = (props) => {
  return (
    <div className="flex flex-row" {...props}>
      <div className="max-w-150 w-full h-40 md:h-45 flex flex-col items-center justify-center px-10 text-center leading-4 border border-black/50 rounded-lg hover:opacity-75 cursor-pointer">
        {props.children}
      </div>
    </div>
  )
}