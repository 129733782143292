import { Box, Button, Card, CardMedia, Grid, Typography } from "@mui/material";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Link } from "react-router-dom";

import './add-image-to-video.scss';
import UploadImg from './../../../assets/image/tools/upload-img.png';
import facebookLogo from "./../../../assets/image/Logos/facebook_logo.png";
import instagramLogo from "./../../../assets/image/Logos/instagram_logo.png";
import tiktokLogo from "./../../../assets/image/Logos/tiktok_logo.png";
import twitterLogo from "./../../../assets/image/Logos/twitter_logo.png";
import youtubeLogo from "./../../../assets/image/Logos/youtube_logo.png";
import whatsappLogo from "./../../../assets/image/Logos/whatsapp_logo.png";
import { createNewProject } from './../VideoEditorHelper';
import { Header } from '../../../component/home-header/header';
import { Footer } from '../../../component/home-footer/footer';

import { GetProjectLink } from "../../../axios/ApiProvider";

export const EDIT_FRONTEND_URL = process.env.REACT_APP_BASE_FRONTEND_EDITOR_URL
export const BASE_STATIC_STORAGE_URL = process.env.REACT_APP_STATIC_STORAGE_URL;

export const how_to_1 = BASE_STATIC_STORAGE_URL + '/images/features/Add Your File.png';
export const how_to_2 = BASE_STATIC_STORAGE_URL + '/images/features/Join-Merge Video.png';
export const how_to_3 = BASE_STATIC_STORAGE_URL + '/images/features/Export Loop Video.png';

const VideooEditor_AddImage_Welcome = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddImage.png'
const VideooEditor_AddImage_Step1 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddImage-1-MainPage.png'
const VideooEditor_AddImage_Step2 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddImage-2-SignupPage.png'
const VideooEditor_AddImage_Step3 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddImage-3-CreateBlankProject.png'
const VideooEditor_AddImage_Step4 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddImage-4-CreateProjectFromTemplates.png'
const VideooEditor_AddImage_Step5 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddImage-5-CreateProjectFromStock.png'
const VideooEditor_AddImage_Step6 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddImage-6-MediaMenu.png'
const VideooEditor_AddImage_Step7 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddImage-7-AddSecondImage.png'
const VideooEditor_AddImage_Step8 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddImage-8-SearchStockSound.png'
const VideooEditor_AddImage_Step9 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddImage-9-AddAStockSoundPopup.png'
const VideooEditor_AddImage_Step10 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddImage-10-AddStockSound.png'
const VideooEditor_AddImage_Step11 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddImage-11-Export.png'
const VideooEditor_AddImage_Step12 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddImage-12-ExportResult.png'
const VideooEditor_AddImage_Step13 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddImage-14-Trimmer-Resizer-Rotator-Filters.png'

const VideooEditor_AddImage_PexelsVideo = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-PexelsVideo.png'
const VideooEditor_AddImage_PixabayVideo = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-PixabayVideo.png'
const VideooEditor_AddImage_FreeSoundAudio = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-FreeSoundAudio.png'

const VideooEditor_AddTransitions_Image = BASE_STATIC_STORAGE_URL + '/images/features/Add%20Transition%20to%20Video.png'
const VideooEditor_AddMusic_Image = BASE_STATIC_STORAGE_URL + '/images/features/Add%20Music%20to%20Video%202.png'
const VideooEditor_AddText_Image = BASE_STATIC_STORAGE_URL + '/images/features/Add%20Text%20to%20Video.png'
const VideooEditor_AddStock_Image = BASE_STATIC_STORAGE_URL + '/images/features/Export%20Stock.png'


export const AddImageToVideo = () => {
    const handleCreateNewProject = async () => {
        let result = await GetProjectLink();
        if (!result || result.state == 'error') window.toastr.error(result.data.message);
        else if (!!Object.keys(result.data)[0]) window.location.href = `${EDIT_FRONTEND_URL}/${Object.keys(result.data)[0]}?from=new`
    }

    return (
        <Box className="T_containner">
          <Box className="Header"><Header /></Box>

          <Box className="content-containner">
            <Box className="section-15" sx={{marginTop: '4rem'}}> 
                <Box className="div-1232">
                    <Grid container spacing={1} justifyContent={'center'} alignItems={'center'} height={400}>
                        <Grid item xs={12} md={6}>
                            <Card className="p-6">
                                <Box className="div-1232">
                                    <Typography variant="h3" gutterBottom fontWeight={'bold'}>
                                    Add Images to Your <br />Videos Online
                                    </Typography>
                                </Box>
                                <Box className="div-1232" sx={{marginBottom: '1rem'}}>
                                    <Typography variant="body1" gutterBottom >
                                    Using Videoo.io you can easily add images to your videos online. Simply upload your video, add your images, export & download your video with images added.
                                    </Typography>
                                </Box>
                                <Box className="div-1232" onClick={handleCreateNewProject}>
                                    <Button variant="contained" className="create-now" color="success">Create Now</Button>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6} height={'100%'}>
                            <Card className="p-6">
                                {/*<img src={Illustration} className="mx-auto h-full " />*/}
                                <div style={{ width: '100%', paddingTop: '56.25%', position: 'relative' }}>
                                <iframe
                                    src="https://www.youtube.com/embed/T7VJLwMyZdc"
                                    title="Videoo.io, Online Cloud Based Video Editor"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                                ></iframe>
                                </div>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box className='section-15 pt-12'>
              <Box className='div-1232'>
                <Box className='breadcrumbs'>
                  <a href={'/'}>
                    <Box className='breadcrumb-text-block'>Videoo</Box>
                  </a>
                  <Box className='breadcrumb-arrow'></Box>
                  <a href={'/'}>
                    <Box className='breadcrumb-text-block'>Video Editor</Box>
                  </a>
                  <Box className='breadcrumb-arrow'></Box>
                  <a href={'/'}>
                    <Box className='breadcrumb-text-block'>Add Image to Video</Box>
                  </a>
                </Box>
              </Box>
            </Box>
            <Box className="section-9 directory std-hero-section" sx={{paddingY: '3rem'}}>
              <Box className="div-1232">
                <Box className="mx-auto text-center" sx={{width: '80%'}}>
                  <Typography variant="h3" gutterBottom fontWeight={600} color={'#4D4D4D'}>Add Images To Your Video with Drag-and-Drop editing</Typography>
                </Box>
                <Box sx={{textAlign: 'center', marginBottom: '3rem'}}>
                  <Typography variant="body1" gutterBottom >Easily add images with a single click</Typography>
                </Box>
                <Box className="container">
                  <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                    <Grid item md={6} xs={12} >
                      <img width={'100%'} src={VideooEditor_AddImage_Welcome} />
                    </Grid>
                    <Grid item md={6} xs={12} >
                      <Box sx={{paddingBottom: '0.5rem'}}>
                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>With VIDEOO's intuitive drag-and-drop editing feature, adding images to your videos has never been easier.</Typography>
                      </Box>
                      <Box sx={{paddingBottom: '1rem'}}>
                        <Typography variant="body1" gutterBottom>
                            With VIDEOO's intuitive drag-and-drop editing feature, adding images to your videos has never been easier. Simply drag your images using your preferred desktop or mobile browser and drop them into your video timeline. Our platform supports seamless addition of images also considering alpha layers and opacity, allowing you to enhance your video content effortlessly. Perfect for creating engaging and visually vivid videos, VIDEOO ensures a smooth and user-friendly editing experience directly working in your browser. You don't need to install any additional software to your device and this also guarantees your software is always up-to-date.
                        </Typography>
                      </Box>
                      <Box sx={{paddingTop: '1rem'}}>
                        <Button variant="contained" className="py-10 px-33" color="success">Learn More</Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
            <Box className="section-9 directory std-hero-section" >
              <Box className="div-1232">
                <Box className="text-center">
                <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>How to Add a Photo or Image to a Video, a step-by-step Guide</Typography>
                </Box>
                <Box className="text-center" sx={{marginBottom: '3rem'}}>
                  <Typography variant="body1" gutterBottom >You can use Videoo.io to enhance your creativity by adding custom or stock images to your video projects.</Typography>
                </Box>

                <Box className="section-9 directory std-hero-section" >
                    <Box className="div-1232">
                        <Box className="container">
                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddImage_Step1} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 1 - Welcome to Videoo.io</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body1" gutterBottom>
                                        - Signup & Login to Videoo.io, and create either a blank or template project. Go to Media Section on left side menu. For stock images, go to Stock Menu, and select Pixabay or Pexels Image section.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                        - Go to Media Section on left side menu. For stock images, go to Stock Menu, and select Pixabay or Pexels Image section.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddImage_Step2} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 2 - Sign up to www.videoo.io</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body1" gutterBottom>
                                        - To start using VIDEOO, visit videoo.io and sign up for an account. You will receive an email to the address you used to sign up for the service. Use the link in the email to sign in to www.videoo.io.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                        - If you used Google Signup, you will be immediately redirected to the service, and you will only receive a welcome email in this case. After creating your account, log in with your credentials.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                        - Once logged in, you can begin by creating a new project. Choose between starting a blank project or using one of the provided templates. This will take you to the project dashboard, where you can start editing your videos.
                                        </Typography>
                                    </Box>
                                    <Box sx={{paddingTop: '1rem'}}>
                                        <Button to="../signup" component={Link} variant="contained" className="py-10 px-33" color="success">Sign up to Videoo.io</Button>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddImage_Step3} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 3 - Create a Blank Project</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body1" gutterBottom>
                                        After logging in to VIDEOO, navigate to the dashboard and click on "Create New Project." Select the option to start a "Blank Project." This will open a new project workspace where you can upload your video files and images. By starting with a blank project, you have the flexibility to customize your video from scratch, including adding images exactly where you want them in the timeline.
                                        </Typography>
                                    </Box>
                                    <Box className="div-1232" onClick={handleCreateNewProject}>
                                        <Button variant="contained" className="create-now" color="success">Create a Blank Project</Button>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddImage_Step4} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 4 - Create from Templates (optional)</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body1" gutterBottom>
                                        To create a blank project using VIDEOO templates, log in to VIDEOO and navigate to the dashboard. Click on "Create New Project" and choose the option to start with a template. Browse through the available templates and select one that suits your needs. Once the template loads, you can remove any pre-existing elements to create a blank canvas. This approach gives you a structured starting point while allowing you to add images and other elements to your video as desired.
                                        </Typography>
                                    </Box>
                                    <Box sx={{paddingTop: '1rem'}}>
                                        <Button to="../dashboard/templates" component={Link} variant="contained" className="py-10 px-33" color="success">Create a Template Project</Button>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddImage_Step5} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 5 - Create from Stock Content (optional)</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                            To enrich your video with high-quality visuals and audio, VIDEOO offers integration with several stock content providers. On the left-side menu, find the "Stock" section. Here, you can access a variety of stock images and videos from reputable sources such as <a href="https://pixabay.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}><strong>Pixabay</strong></a>, <a href="https://www.pexels.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}><strong>Pexels</strong></a>, <a href="https://freesound.org" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}><strong>Freesound</strong></a>, and <a href="https://stock.adobe.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}><strong>Adobe Stock</strong></a>.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            - <a href="https://pixabay.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}><strong>Pixabay Images and Videos</strong></a>
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            - <a href="https://www.pexels.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}><strong>Pexels Images and Videos</strong></a>
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            - <a href="https://freesound.org" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}><strong>Freesound Audio Stock</strong></a>
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            - <a href="https://stock.adobe.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}><strong>Adobe Stock:</strong></a> For more premium options, <a href="https://stock.adobe.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>Adobe Stock</a> offers high-quality images, videos, and audio clips. Although some content may require a subscription or purchase, the quality and variety available can greatly enhance your project.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            By utilizing these stock content resources, you can quickly and easily enhance your video with professional-grade visuals and sounds, all within the VIDEOO platform. This makes it easier to create high-quality videos that captivate your audience without the need for extensive resources or time.
                                        </Typography>
                                    </Box>
                                    <Box sx={{paddingTop: '1rem'}}>
                                        <Button to="../dashboard/stocks" component={Link} variant="contained" className="py-10 px-33" color="success">Create a Stock Project</Button>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Box className="container">
                                <Grid container spacing={5}>
                                    <Grid item md={4} xs={12} className="text-center">
                                    <Card className="p-6">
                                        <Box className="text-center py-4">
                                        <img className="mx-auto" src={VideooEditor_AddImage_PixabayVideo}/>
                                        </Box>
                                        <Box className="text-center py-4">
                                        <Typography variant="h5" gutterBottom fontWeight={600} color={'#4D4D4D'}>Pixabay Stock</Typography>
                                        </Box>
                                        <Box className="text-center py-4">
                                        <Typography variant="body2" gutterBottom>
                                            - <a href="https://pixabay.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}><strong>Pixabay Images and Videos:</strong></a> Browse through a vast collection of free images and videos. Simply select the ones you want to use and add them to your project. <a href="https://pixabay.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>Pixabay</a> offers a wide range of high-quality visuals suitable for any type of video content.
                                        </Typography>
                                        </Box>
                                    </Card>
                                    </Grid>
                                    <Grid item md={4} xs={12} className="text-center">
                                    <Card className="p-6">
                                        <Box className="text-center py-4">
                                        <img className="mx-auto" src={VideooEditor_AddImage_PexelsVideo}/>
                                        </Box>
                                        <Box className="text-center py-4">
                                        <Typography variant="h5" gutterBottom fontWeight={600} color={'#4D4D4D'}>Pexels Stock</Typography>
                                        </Box>
                                        <Box className="text-center py-4">
                                        <Typography variant="body2" gutterBottom>
                                            - <a href="https://www.pexels.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}><strong>Pexels Images and Videos:</strong></a> Similar to Pixabay, <a href="https://www.pexels.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>Pexels</a> provides a large library of free stock images and videos. Their content is known for its professional quality, making it easy to enhance your video with stunning visuals.
                                        </Typography>
                                        </Box>
                                    </Card>
                                    </Grid>
                                    <Grid item md={4} xs={12} className="text-center">
                                    <Card className="p-6">
                                        <Box className="text-center py-4">
                                        <img className="mx-auto" src={VideooEditor_AddImage_FreeSoundAudio}/>
                                        </Box>
                                        <Box className="text-center py-4">
                                        <Typography variant="h5" gutterBottom fontWeight={600} color={'#4D4D4D'}>FreeSound Stock</Typography>
                                        </Box>
                                        <Box className="text-center py-4">
                                        <Typography variant="body2" gutterBottom>
                                            - <a href="https://freesound.org" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}><strong>Freesound:</strong></a> This resource offers a variety of sound effects and audio clips that you can incorporate into your video. Adding unique sounds can significantly improve the engagement and professionalism of your content.
                                        </Typography>
                                        </Box>
                                    </Card>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddImage_Step6} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 6 - Media Menu to select your custom Image</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                            VIDEOO makes it easy to incorporate images from your own device into your video projects. Here's how you can select and upload local images using the Media Menu:
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            - <strong>Step 1:</strong> Navigate to the Media Menu on the left side of the VIDEOO interface.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            - <strong>Step 2:</strong> Click on the "Upload Image" button. A file selection dialog will appear, allowing you to browse and select images from your local device.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            - <strong>Step 3:</strong> Choose the image you want to upload and click "Open." The selected images will be uploaded to your media library within VIDEOO.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            - <strong>Step 4:</strong> Once uploaded, you can drag and drop these images directly into your project timeline, just like with stock images. You can adjust their size, position, and duration within the video to fit your creative vision.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            By following these steps, you can seamlessly integrate your own images into your video projects, making your content more personalized and unique. This feature enhances the flexibility of VIDEOO, allowing you to use custom visuals that perfectly match your project requirements.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddImage_Step7} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 7 - Adding a second image (optional)</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                            To add a second image to your video project in VIDEOO, simply navigate to the Media Menu on the left side of the interface. In the "Images" section, locate your desired image in the media library or upload a new one from your device. Once the image is available, drag and drop it into the timeline at the desired position. Adjust its size, position, and duration as needed to seamlessly integrate it into your video.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddImage_Step8} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 8 - Adding Stock Sound to your Image (optional)</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                            Enhance your video by adding stock sound from <a href="https://freesound.org" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}><strong>Freesound</strong></a> to your images. Here's how you can do it:
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            Navigate to the Stock Menu on the left side of the VIDEOO interface and select the "FreeSound" section. <a href="https://freesound.org" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}><strong>Freesound</strong></a>. Browse through the extensive library of sound effects and audio clips to find the perfect sound for your project. Once you've found the sound you want to use, click "Add to Project" to include it in your media library.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            Drag and drop the selected sound clip into the timeline, positioning it to align with your image. Adjust the volume, duration, and any other settings as needed to ensure the sound complements your visual content perfectly.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            By integrating high-quality stock sounds from <a href="https://freesound.org" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}><strong>Freesound</strong></a>, you can significantly enhance the overall impact and engagement of your video.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddImage_Step9} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 9 - Add Stock Audio Popup (optional)</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                            Enhance your video by adding stock sound from <a href="https://freesound.org" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}><strong>Freesound</strong></a> to your images. Here's how you can do it:
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            Navigate to the Stock Menu on the left side of the VIDEOO interface and select the "FreeSound" section. <a href="https://freesound.org" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}><strong>Freesound</strong></a>. Browse through the extensive library of sound effects and audio clips to find the perfect sound for your project. Once you've found the sound you want to use, click "Add to Project" to include it in your media library.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            Drag and drop the selected sound clip into the timeline, positioning it to align with your image. Adjust the volume, duration, and any other settings as needed to ensure the sound complements your visual content perfectly.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            By integrating high-quality stock sounds from <a href="https://freesound.org" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}><strong>Freesound</strong></a>, you can significantly enhance the overall impact and engagement of your video.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>


                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddImage_Step10} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 10 - Add Stock Audio Popup (optional)</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                            Enhancing your video projects with stock audio is a simple and effective way to add depth and engagement to your visuals. Here's how you can select and add stock audio to the timeline alongside your images using VIDEOO:
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong> - </strong> Once you have found the perfect audio clip, click "Add" to include it in your timeline.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong> - </strong> Adjust the audio clip to synchronize it with your images. You can modify the volume, trim the audio to match the duration of your image display, and add effects or transitions to create a seamless blend of audio and visuals.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            By following these steps, you can effectively incorporate stock audio into your video projects, enhancing the overall experience and making your content more engaging and professional.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddImage_Step11} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 11 - Exporting your Video</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                            After you have finished adding images and stock audio to your video project in VIDEOO, the final step is to export your project. Here’s how you can do it:
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong> - </strong> Review your entire timeline to ensure all images and (and if available) audio clips are correctly placed and synced. Make any final adjustments needed for a polished result.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong> - </strong> Once you’re satisfied with the editing, navigate to the top right corner of the VIDEOO interface and click on the "Export" button.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong> - </strong> In the export settings, choose the desired video resolution. VIDEOO offers various options to match your specific requirements, whether it's for social media, web, or high-definition displays.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong> - </strong> Configure any additional settings such as frame rate, and encoding quaity options to optimize the quality and size of your exported video.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong> - </strong> Click "Produce" to begin the rendering process. VIDEOO will process your video, combining all images, audio, and effects into a final, cohesive file.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddImage_Step12} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 12 - Export Output</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                            <strong> - </strong> Once the export is complete, you will be prompted to download to your device or share the video on social media.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            By following these steps, you can easily export a professional-quality video project that includes your carefully selected images and stock audio. This final product is ready to be shared on various platforms, showcasing your creative work to your audience.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
              </Box>
            </Box>
            <Box className="section-9  std-hero-section">
              <Box className="div-1232">
                <Typography className="text-center" variant="h4" gutterBottom fontWeight={600} >Easy-to Use Add Image to Video Features</Typography>
                <Box className="container py-15" sx={{marginTop: '2rem'}}>
                  <Grid container spacing={4}>
                    <Grid item md={6} xs={12}>
                      <Box className="mb-5">
                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>
                        Image Trimmer
                        </Typography>
                      </Box>
                      <Box className="py-10">
                        <Typography variant="body2" gutterBottom>
                            The trimmer tool is located directly on the timeline and is designed to help you easily stretch and expand timeline elements for images. Here’s how it works:
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            - <strong>Precise Adjustment:</strong> The trimmer allows you to click on the edges of your image element in the timeline and drag to adjust its start and end points. This precise control ensures that your image appears exactly when you want it in your video.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            - <strong>Stretch and Expand:</strong> You can stretch or expand the duration of your image by dragging the edges of the timeline element. This feature is particularly useful for syncing images with audio or other visual elements.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            - <strong>Real-Time Preview:</strong> As you adjust the timeline elements, you can preview the changes in real-time. This immediate feedback helps you make accurate adjustments without guesswork.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            - <strong>User-Friendly Interface:</strong> The interface is designed to be intuitive, even for beginners. The trimmer tool is easily accessible and provides a smooth editing experience.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            By utilizing the trimmer tool and other easy-to-use features in VIDEOO, you can efficiently add and adjust images in your video projects, ensuring a polished and professional final product.
                        </Typography>
                      </Box>
                      <Box className="mx-auto text-center mt-10" onClick={handleCreateNewProject}>
                        <Button variant="contained" className="py-10 px-33" color="success">Create with Image Trimmer</Button>
                      </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Box className="mb-5">
                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>
                        Image Resizer
                        </Typography>
                      </Box>
                      <Box className="py-10">
                          <Typography variant="body2" gutterBottom>
                            The resizer tool is located directly in the player window and allows you to easily adjust the size of your images within your video project. Here’s how it works:
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            - <strong>Easy Resizing:</strong> To resize an image, simply click on the image element in the player window. Small handles will appear at the corners of the image.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            - <strong>Corner Handles:</strong> Drag the handles at the corners of the image to resize it. You can make the image larger or smaller by dragging the handles inward or outward, respectively.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            - <strong>Proportional Scaling:</strong> The resizer tool maintains the aspect ratio of the image while resizing, ensuring that the image does not become distorted.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            - <strong>Real-Time Preview:</strong> As you resize the image, you can see the changes in real-time in the player window. This allows you to make precise adjustments and see exactly how the image will look in your final video.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            - <strong>User-Friendly Interface:</strong> The interface is designed to be intuitive, making it easy for users of all experience levels to resize images quickly and accurately.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            By using the resizer tool and other easy-to-use features in VIDEOO, you can efficiently add and adjust images in your video projects, ensuring a polished and professional final product.
                        </Typography>
                      </Box>
                      <Box className="mx-auto text-center mt-10" onClick={handleCreateNewProject}>
                        <Button variant="contained" className="py-10 px-33" color="success">Create with Image Resizer</Button>
                      </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Box className="mb-5">
                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>
                            Image Rotator
                        </Typography>
                      </Box>
                      <Box className="py-10">
                        <Typography variant="body2" gutterBottom>
                            The rotator tool is located in the player window and is designed to help you rotate your images to the desired angle. Here’s how it works:
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            - <strong>Easy Rotation:</strong> To rotate an image, click on the image element in the player window. Rotation handles will appear at the corners of the image.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            - <strong>Corner Handles:</strong> Click and drag the rotation handles at the corners of the image to rotate it. This allows you to tilt the image to the exact angle you need.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            - <strong>Precise Control:</strong> The rotator tool provides precise control over the rotation angle, ensuring that you can make subtle adjustments for perfect alignment.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            - <strong>Real-Time Preview:</strong> As you rotate the image, the changes are displayed in real-time in the player window. This immediate feedback helps you achieve the desired orientation without any guesswork.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            - <strong>User-Friendly Interface:</strong> The interface is designed to be intuitive and accessible, making it easy for users of all experience levels to rotate images accurately.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            By utilizing the rotator tool and other easy-to-use features in VIDEOO, you can efficiently add and adjust images in your video projects, ensuring a polished and professional final product.
                        </Typography>
                      </Box>
                      <Box className="mx-auto text-center mt-10" onClick={handleCreateNewProject}>
                        <Button variant="contained" className="py-10 px-33" color="success">Create with Image Rotator</Button>
                      </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Box className="mb-5">
                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>
                            Image Color Filter
                        </Typography>
                      </Box>
                      <Box className="py-10">
                        <Typography variant="body2" gutterBottom>
                            The color filter tool is located in the player window and enables you to apply various color effects to your images. Here’s how it works:
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            - <strong>Applying Color Filters:</strong> To add a color filter, click on the image element in the player window or on the timeline. Then select "Filters" on the left hand-side menu.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            - <strong>Filter Options:</strong> Choose from a range of preset color filters to apply different tones and effects to your image. Options might include black and white, sepia, vibrant, and more.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            - <strong>Custom Adjustments:</strong> Some filters may allow for further customization, such as adjusting the intensity or blending multiple filters for a unique look.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            - <strong>Real-Time Preview:</strong> As you apply and adjust the filters, you can see the changes in real-time in the player window. This allows you to fine-tune the effect until you achieve the desired appearance.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            - <strong>Resizing and Positioning:</strong> While applying color filters, you can also resize the image by dragging the handles at the corners of the image element. This ensures that your image fits perfectly within your video frame.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            By utilizing the color filter tool and other easy-to-use features in VIDEOO, you can enhance your images, making your video projects more visually appealing and professional.
                        </Typography>
                      </Box>
                      <Box className="mx-auto text-center mt-10" onClick={handleCreateNewProject}>
                        <Button variant="contained" className="py-10 px-33" color="success">Create with Image Rotator</Button>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item md={6} xs={12} >
                    <Box className="py-10">
                        <img width={'100%'} src={VideooEditor_AddImage_Step13} />
                    </Box>
                  </Grid>
                </Box>
              </Box>
            </Box>


            <Box className="section-9 directory" >
                <Box className="div-1232 px-18">
                    <Box>
                    <Typography className="text-center" variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Other Video Tools</Typography>
                    </Box>
                    <Box className="text-center">
                    <Typography className="text-center" variant="body1" gutterBottom color={'#4D4D4D'}>There are plenty of other features that are avaliable with Videoo.io</Typography>
                    </Box>
                </Box>
                <Box >
                    <Grid container spacing={3}>
                    <Grid item md={3} xs={12}>
                        <Card className="p-6" sx={{background: '#abbed133'}}>
                        <Grid >
                            <img width={'100%'} src={VideooEditor_AddMusic_Image} />
                        </Grid>
                        <Typography sx={{paddingY: '1rem'}} className="text-center" variant="h5" gutterBottom fontWeight={500} color={'#717171'}>
                            Add Music to Video
                        </Typography>
                        <Box className="mx-auto text-center">
                            <Button to="../add-music-to-video" component={Link}>Create <ArrowRightAltIcon /></Button>
                        </Box>
                        </Card>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Card className="p-6" sx={{background: '#abbed133'}}>
                        <Grid >
                            <img width={'100%'} src={VideooEditor_AddText_Image} />
                        </Grid>
                        <Typography sx={{paddingY: '1rem'}} className="text-center" variant="h5" gutterBottom fontWeight={500} color={'#717171'}>
                        Add Text to Video
                        </Typography>
                        <Box className="mx-auto text-center">
                            <Button to="../add-text-to-video" component={Link}>Create <ArrowRightAltIcon /></Button>
                        </Box>
                        </Card>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Card className="p-6" sx={{background: '#abbed133'}}>
                        <Grid >
                            <img width={'100%'} src={VideooEditor_AddStock_Image} />
                        </Grid>
                        <Typography sx={{paddingY: '1rem'}} className="text-center" variant="h5" gutterBottom fontWeight={500} color={'#717171'}>
                        Add Free Stock Content to Video
                        </Typography>
                        <Box className="mx-auto text-center">
                            <Button to="../add-free-stock-to-video" component={Link}>Create <ArrowRightAltIcon /></Button>
                        </Box>
                        </Card>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Card className="p-6" sx={{background: '#abbed133'}}>
                        <Grid>
                            <img width={'100%'} src={VideooEditor_AddTransitions_Image} />
                        </Grid>
                        <Typography sx={{paddingY: '1rem'}} className="text-center" variant="h5" gutterBottom fontWeight={500} color={'#717171'}>
                            Add Transitions to Video
                        </Typography>
                        <Box className="mx-auto text-center">
                            <Button to="../add-transition-to-video" component={Link}>Create <ArrowRightAltIcon /></Button>
                        </Box>
                        </Card>
                    </Grid>
                    </Grid>
                </Box>
            </Box>


            <Box className="section-9 directory" sx={{paddingY: '3rem'}}>
              <Box className="div-1232 px-18">
                <Box className="text-center">
                  <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Frequently Asked Questions</Typography>
                </Box>
                <Box className="text-center mx-auto" sx={{marginBottom: '3rem', width: '60%'}}>
                  <Typography variant="body1" gutterBottom >Welcome to our FAQ section about adding an Image to a Video! Here, we answer the most common questions about using VIDEOO. Whether you're new to video editing or a seasoned pro, you'll find helpful tips and insights to make the most of our platform.</Typography>
                </Box>
                    <Box className="py-15">
                        <Typography variant="h5" gutterBottom>
                            Question #1: How do I add images to a video using VIDEOO?
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            To add images to a video using VIDEOO, simply use the drag-and-drop feature. Drag your desired images from your library and drop them into the video timeline. This allows for easy and seamless integration of images into your video content.
                        </Typography>
                    </Box>
                    <Box className="py-15">
                        <Typography variant="h5" gutterBottom>
                            Question #2: Can I customize the images I add to my videos in VIDEOO?
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Yes, VIDEOO allows you to customize the images you add to your videos. You can adjust the size, position, and duration of the images in the timeline to fit your video's needs perfectly.
                        </Typography>
                    </Box>
                    <Box className="py-15">
                        <Typography variant="h5" gutterBottom>
                            Question #3: What formats are supported for image uploads in VIDEOO?
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            VIDEOO supports a variety of image formats, including JPEG, PNG, and GIF. This ensures compatibility and flexibility when adding images to your videos.
                        </Typography>
                    </Box>
                    <Box className="py-15">
                        <Typography variant="h5" gutterBottom>
                            Question #4: Can I add multiple images to a single video using VIDEOO?
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Absolutely! VIDEOO allows you to add multiple images to a single video. You can easily drag and drop multiple images into the timeline and arrange them as needed.
                        </Typography>
                    </Box>
                    <Box className="py-15">
                        <Typography variant="h5" gutterBottom>
                            Question #5: How does VIDEOO ensure the quality of the images added to videos?
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            VIDEOO maintains the quality of the images added to videos by supporting high-resolution uploads and providing tools to optimize the images for the best visual output.
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box className="section-9 directory std-hero-section" >
              <Box className="section-15 py-12 mx-auto">
                <Box className="div-1232">
                  <Box className="mx-auto">
                    <Typography sx={{textAlign: 'center'}} variant="h3" gutterBottom fontWeight={'bolder'}>Make Your Videos Easily</Typography>
                  </Box>
                  <Box className="mx-auto text-center mt-10" onClick={handleCreateNewProject}>
                    <Button variant="contained" className="py-10 px-33" color="success">Create Now <ArrowRightAltIcon /></Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="footer-containner"><Footer /></Box>
        </Box>
        
    )
}