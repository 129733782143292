import { Box } from '@mui/system';

import './auto-subtitles.scss';
import UploadImg from './../../../assets/image/tools/upload-img.png';
import facebookLogo from "./../../../assets/image/Logos/facebook_logo.png";
import instagramLogo from "./../../../assets/image/Logos/instagram_logo.png";
import tiktokLogo from "./../../../assets/image/Logos/tiktok_logo.png";
import twitterLogo from "./../../../assets/image/Logos/twitter_logo.png";
import youtubeLogo from "./../../../assets/image/Logos/youtube_logo.png";
import whatsappLogo from "./../../../assets/image/Logos/whatsapp_logo.png";

import ImageOne from "./../../../assets/image/pages/autoSubtitle/image1.jpg";
import ImageTwo from "./../../../assets/image/pages/autoSubtitle/image2.jpg";
import ImageThree from "./../../../assets/image/pages/autoSubtitle/image3.jpg";
import ImageFour from "./../../../assets/image/pages/autoSubtitle/image4.jpg";


import { createNewProject } from './../SubtitlesTranscriptionHelper';
import { Header } from '../../../component/home-header/header';
import { Footer } from '../../../component/home-footer/footer';

export const BASE_STATIC_STORAGE_URL = process.env.REACT_APP_STATIC_STORAGE_URL;
export const how_to_1   = BASE_STATIC_STORAGE_URL + '/images/features/Add Your File Auto Subtitles.png';
export const how_to_2   = BASE_STATIC_STORAGE_URL + '/images/features/Auto Subtitles.png';
export const how_to_3   = BASE_STATIC_STORAGE_URL + '/images/features/Export Auto Subtitles.png';

export const AutoSubtitles = () => {
  return (
    <Box className="T_containner">
      <Box className="Header"><Header /></Box>

      <Box className="content-containner">
      <>
      <Box className='section-15'>
        <Box className='div-1232 _1300'>
          <Box className='breadcrumbs'>
            <a href={'/'}>
              <Box className='breadcrumb-text-block'>Videoo</Box>
            </a>
            <Box className='breadcrumb-arrow'></Box>
            <a href={'/'}>
              <Box className='breadcrumb-text-block'>Tools</Box>
            </a>
            <Box className='breadcrumb-arrow'></Box>
            <Box className='breadcrumb-text-block'>Subtitle Generator</Box>
          </Box>
        </Box>
      </Box>

      <Box className='section-9 directory std-hero-section'>
        <Box className='div-1232'>
          <Box className='hero-grid'>
            <Box className='hero-grid-text'>
              <Box className='hero-header-title'>AI Powered Subtitle Generator for Your Videos</Box>
              <Box className='hero-content-title'>Free online video editing software - create videos online</Box>

              <Box onClick={createNewProject} className='choose-btn'>
                <Box className='choose-btn-text'>Create Project</Box>
                <Box className='choose-btn-img'>
                  <img src={UploadImg} alt='uploadImg' />
                </Box>
              </Box>
            </Box>
            <Box className='hero-img'>
              <iframe
                  src={`https://www.youtube.com/embed/k0nU21y8FgU`}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                  style={{margin: 'auto', width: '100%', aspectRatio: 1.7}}
              />
            </Box>
          </Box>

          {/* <Box className="logos-grid">
              <Box className="usedByLogos-item">
                  <img src={instagramLogo} alt="instagram logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={youtubeLogo} alt="youtube logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={facebookLogo} alt="facebook logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={twitterLogo} alt="twitter logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={tiktokLogo} alt="tiktok logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={whatsappLogo} alt="whatsapp logo" width={100} />
              </Box>
              {{usedLogs.map((logo, index) => (
                  <Box className="usedByLogos-item" key={index}>{logo}</Box>
              ))}}
          </Box> */}

        </Box>
      </Box>

      <Box className='wf-section'>
        <Box className='div-1232'>
          <Box className='div-block-67'>
            <h1 className="heading-20">Transcribe Video to Text and Add AI-Generated Auto Subtitles Using Videoo.io!</h1>
            <p >
              Videos with subtitles tend to have higher viewership compared to those without. Adding subtitles to your videos can sometimes be challenging, especially when synchronizing the audio with the text. Videoo.io offers a solution to this problem. You can easily transcribe your video with AI-Generated Auto Subtitles.
              Videoo.io uses the technique of speech recognition to transcribe video to text. It recognizes the words, converts the video to text, and writes them as subtitles at the appropriate time intervals.
            </p>
            <img className='mx-auto' src={ImageFour}/>
            <h1 className='heading-20'>How to Add Auto Subtitles to a Video using Videoo.io?</h1>
            <p>
              You can easily create Auto Subtitles with the following steps on Videoo.io:
              <ul>
                <li>Upload media: Go to the “Media” tab and click on the “Upload Video” or “Upload Audio” button. Select your media and upload.</li>
                <li>Navigate to the “Subtitles” tab: Click on the “Subtitles” tab on the left menu.</li>
                <li>Select a language: Select the language spoken in the video.</li>
                <li>Customize your style (optional): You can customize the font, color, and background of the subtitle if you want.</li>
                <li>Click on the “Auto Subtitles” button: After completing the steps, you can click on the button and create your auto captions.</li>
              </ul>
            </p>
            <h1 className='heading-20'>How to Use Subtitle Editor?</h1>
            <p>
              Videoo.io has a Subtitle Editor. It allows you to edit the AI-generated subtitles with a single click. This subtitle editor allows you to adjust each caption's font size, style, and content. To use this feature, you can do the following steps:
              <ul>
                <li>Click on a text generated by the auto subtitles: After you generate your subtitles, click on a text on the timeline.</li>
                <li>Click on the “Edit Subtitle” button on the mini helper menu: On the top of the timeline, there is an “Edit Subtitle” button.</li>
                <li>Edit the size, style, and content of the captions: Select the style that fits best to your content and adjust the font size.</li>
                <li>Save it: Save the changes and it will apply the changes to all the text.</li>
              </ul>
            </p>
            <img className='mx-auto' src={ImageTwo}/>
            <h1 className='heading-20'>How to Add Subtitles in Two Languages?</h1>
            <p>You may want to reach a wider audience by adding two subtitles in two different languages, for example, one in German and the other in Turkish. To achieve that goal, you can use the Translate feature. This works with the same technique as the subtitles, it creates video to text, but this one translates the subtitles into the desired language.</p>
            <img className='mx-auto' src={ImageOne} />
            <h1 className='heading-20'>Increase Your Engagement with Transcribed Videos!</h1>
            <p>Captivate your viewers on popular platforms like Instagram Reels, TikTok, YouTube, and Shorts. Videoo.io's AI-generated subtitles ensure your content remains engaging even when viewers have the sound muted or are in noisy environments. Additionally, you can even generate content in different aspect ratios using the same AI-generated subtitles. For example, you can create TikTok content and after that, you can convert it into a YouTube video too without the need of generating another subtitle. Your auto captions stay the same.</p>
            <img className='mx-auto' src={ImageThree}/>
            <h1 className='heading-20'>Conclusion</h1>
            <p>Videoo.io's AI-generated subtitles feature simplifies creating and customizing subtitles, saving you time. You don’t have to edit the subtitles one by one. Subtitle Editor can customize your auto captions with a single click. Unleash the power of AI-generated subtitles with Videoo.io and elevate your engagement.</p>
            <h1 className='heading-20'>FAQ</h1>
            <p>
              <ul>
                <li>
                  <span>Q: How can I create AI-generated auto subtitles?</span><br />
                  <span>A: You can easily transcribe videos by clicking on the Auto Subtitles button in the Subtitles tab.</span>
                </li>
                <li>
                  <span>Q: Can I edit the subtitles?</span><br />
                  <span>A: Absolutely! Videoo.io offers a Subtitle Editor to edit the AI-generated subtitles with a single click and allows you to edit them in seconds. This subtitle editor allows you to adjust each caption's size, style, and content.</span>
                </li>
                <li>
                  <span>Q: Which languages can I transcribe?</span><br />
                  <span>A: You can now create auto captions in more than 10 languages including English, Spanish, French, German, Italian, Japanese, and more.</span>
                </li>
                <li>
                  <span>Q: How do I add auto subtitles in multiple languages?</span><br />
                  <span>A: Videoo.io's Translate feature utilizes AI to translate your transcribed text into your chosen language. This allows you to seamlessly integrate multilingual subtitles into your videos.</span>
                </li>
                <li>
                  <span>Q: Can I generate voiceovers with these AI-generated subtitles?</span> <br />
                  <span>A: Yes! You can use our Text-to-speech tool to vocalize your texts.</span>
                </li>
              </ul>
            </p>
          </Box>
        </Box>
      </Box>

      <Box className='wf-section'>
        <Box className='div-1232 wide _3'>
          <Box className='div-block-70'>
            <Box className='div-block-69'>
              <h2 className="heading-20">How to Add Auto Subtitles To a Video</h2>
            </Box>

            <Box className='div-block-68'>
              <Box className='how-to-div-item'>
                <img src={how_to_1} alt='howTo1' className="how-to-img" />
                <h3 className="heading-21 m-y-0">Add Your File</h3>
                <p className="how-to-content">Select your video and upload it. You can drag &amp; drop, or choose the file from your computer or mobile device</p>
              </Box>

              <Box className='how-to-div-item'>
                <img src={how_to_2} alt='howTo2' className="how-to-img" />
                <h3 className="heading-21 m-y-0">Add Auto Subtitles to Video</h3>
                <p className="how-to-content">Simply click on the video’s image and drag the corners to adjust. Or click ‘Settings’ and choose a preset canvas size (like ‘YouTube’, or ‘Instagram Stories’) to merge automatically</p>
              </Box>

              <Box className='how-to-div-item'>
                <img src={how_to_3} alt='howTo3' className="how-to-img" />
                <h3 className="heading-21 m-y-0">Share!</h3>
                <p className="how-to-content">That’s it. You’re ready to share your video with your friends and followers. Hit ‘Export’ and your video will start to download</p>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
      </Box>

      <Box className="footer-containner"><Footer /></Box>
    </Box >
    
  )
}