import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Box } from "@mui/material";

import SearchIcon from '@mui/icons-material/Search';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';

import { LightiningIcon } from "./../../../Icons";
import { UserAvatar } from "../../home-header/header";

export const DashboardHeader = ({ userData }) => {
  const loginState = useSelector((store) => store.maindata.loginState);

  let username = userData?.username || ''
  let firName = userData?.firstname || ''
  let lastname = userData?.lastname || ''

  return (
    <section className="d-cotent-header-section">
      <Box className="dC-header-search-wrapper">
        {/* <Box className="header-search-btn">
          <SearchIcon />
          <span>Search videos and folders</span>
        </Box> */}
      </Box>

      <Box className="dC-header-ActionContainer">
        <NavLink to='/pricing' className="ActionContainer-upgradeBtn">
          <span>{!!loginState ? 'Pricing' : 'Upgrade'}</span>
          <LightiningIcon />
        </NavLink>

        {/* <Box className="ActionContainer-inviteBtn">
          <span>Invite</span>
          <PersonAddAltOutlinedIcon />
        </Box> */}

        {/* <Box className="ActionContainer-questionBtn">
          <HelpOutlineOutlinedIcon />
        </Box> */}

        {/* <Box className="ActionContainer-notificationBtn">
          <NotificationsOutlinedIcon />
        </Box> */}

        <Box className="ActionContainer-userAvata-contain">
          <UserAvatar
            userData={userData}
            page='dashboard'
          />

          {/* {!userData?.picture && (
            <Box className="ActionContainer-user-Avata uppercase">
              {username.slice(0, 1)}
            </Box>
          )} */}
        </Box>
      </Box>
    </section>
  )
}