import copy from "copy-to-clipboard";
import axios from 'axios';

import { useSelector } from "react-redux";
import { useEffect, useRef, useState, useLocation } from "react";
import { NavLink, Link, useNavigate, useParams } from "react-router-dom";
import { Box, Typography, LinearProgress, Alert, Snackbar } from "@mui/material";
import moment from "moment";
import 'moment-timezone';

import { EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";

import HomeIcon from '@mui/icons-material/Home';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { ShareIcon, LinkIcon, FacebookIcon, TwitterIcon, EmailIcon, LinkedinIcon, CutIcon, LightiningIcon } from "../../Icons";
import Logo from '../../assets/image/Logo.png';
import ExportLogo from "../../assets/image/ExportLogo.png"

import './projectView.scss';
import { updateProjectJSON } from "../../axios/ApiProvider";
import { copyObject } from '../../component/workspaces/dashboard.config';
import { getWebHookData, getProjectJSON, getAssetData } from "../../axios/ApiProvider";
import PricingModal from "../Pricing/pricingModal";
const EDIT_FRONTEND_URL = process.env.REACT_APP_BASE_FRONTEND_EDITOR_URL
export const ProjectView = () => {
  let history = useNavigate();
  const exprtVideoEl = useRef();
  let { uuid, projectUUID } = useParams();
  const [openHelper, setOpenHelper] = useState(false);

  const [progressing, setProgressing] = useState(false);
  const [webhookUrl, setWebhookUrl] = useState('');
  const [webhookInfo, setWebhookInfo] = useState(null);

  const [userData, setUserData] = useState({})
  const loginState = useSelector((store) => store.maindata.loginState)
  const [showPricingPageModal, setShowPricingPageModal] = useState(false)

  useEffect(() => {
    let tempData = {}
    let item = localStorage.getItem('user_data')
    if (!!item && JSON.parse(item)?.state === 'loggedin') tempData = JSON.parse(item)

    setUserData(tempData)
  }, [loginState])
  const [ctTime, setCtTime] = useState(0);
  useEffect(() => {
    let handler; const currentT = +new Date();
    if(!progressing&&!webhookUrl){
      
      handler = setTimeout(async () => {
        const response = await getAssetData(uuid);

        if (response.state === 'success') {
          const webHookData = response.data;
          if (!!webHookData && !!webHookData?.signedurl){
            setWebhookInfo(webHookData);
            setWebhookUrl(webHookData?.signedurl);
            setProgressing(true);
          }else{
            setWebhookInfo(webHookData);
            setCtTime(currentT);
          }
        } else { setCtTime(currentT); }
      }, 3000)
    }else{
      setCtTime(null);
    }
    return () => handler && clearTimeout(handler);
  }, [ctTime ,webhookInfo]);
 
  const getOriginalFilename = (url) => {
    const filelink = url.split("?")[0];
    const filename = filelink.split("/").reverse()[0];

    return filename
  }

  const [videoBlob, setVideBlob] = useState(null);
  const [time, setTime] = useState(+new Date());
  const [progressVal, setProgressVal] = useState(0);
  const [videoDownloading, setVideoDownloading] = useState(false);
  const downloadFunc = () => {
    let videoUrl = webhookInfo?.signedurl;
    if (!progressing || !videoUrl) {window.toastr.error("Please wait until export to finish.");  return;}
    
    const filename = getOriginalFilename(webhookInfo?.signedurl)
   
    if(videoBlob){
      const aElement = document.createElement('a');
      aElement.setAttribute('download', filename);
      const href = URL.createObjectURL(videoBlob);
      aElement.href = href;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(href);
      setVideoDownloading(false);
    }else{
      setProgressVal(0);
      axios.get(videoUrl, 
        {responseType: 'blob',
          onDownloadProgress: progressEvent => {
            setVideoDownloading(true);
            setProgressVal(Math.floor(progressEvent.progress * 100));
          }
        }).then(res=>{
          setVideBlob(res.data);
          const aElement = document.createElement('a');
          aElement.setAttribute('download', filename);
          const href = URL.createObjectURL(res.data);
          aElement.href = href;
          aElement.setAttribute('target', '_blank');
          aElement.click();
          URL.revokeObjectURL(href);
          setVideoDownloading(false);
      })
    }
  }

  let project_url = window.location.href;
  const [projectUrl, setProjectUrl] = useState(window.location.href);
  const [opanAlert, setOpenAlert] = useState(false);
  const [shareDisable, setShareDisable] = useState(false);
  const copyLinkFunc = () => {
    let copy_res = copy(project_url);
    if (!copy_res || opanAlert) return;

    setOpenAlert(true);
    setTimeout(() => { setOpenAlert(false); }, 1500);
  }

  const [leftTime, setLeftTime] = useState(0);
  const [projectJSON, setProjectJSON] = useState({});

  useEffect(() => {
    firstLoadFunc()
  }, [])

  const firstLoadFunc = async () => {
    try {
      const JSONdata = await getProjectJSON(projectUUID);
      if (JSONdata.state !== "success" || !JSONdata.data[projectUUID]) return;
      if (!JSONdata.data[projectUUID]?.created_at) return;

      let curret_time = new Date();
      setProjectJSON(JSONdata.data[projectUUID]);
      let projectData = JSONdata.data[projectUUID]
      let created_at = new Date(projectData.created_at + ' GMT');
      setLeftTime(created_at - curret_time + 3600 * 48 * 1000);

      let image_url = '';
      let thumbnail = '';
      let asset_uuid = '';
      let thumbnail_Keys = 0;
      let tempJSON = JSONdata.data[projectUUID];
      let videoKeys = Object.keys(tempJSON?.videos ? tempJSON?.videos : {})
      if (videoKeys.length > 0) asset_uuid = tempJSON.videos[videoKeys[0]].asset_uuid;
      if (!!asset_uuid) thumbnail = tempJSON?.asset[asset_uuid].thumbnail;
      if (!!thumbnail) thumbnail_Keys = Object.keys(thumbnail);
      if (thumbnail_Keys.length > 0) image_url = thumbnail[thumbnail_Keys[0]].signed_url;

      if (!!image_url) {
        let blob = await fetch(image_url).then(r => r.blob());
        let image_file = new File([blob], "image.png", { type: 'image/png', lastModified: new Date().getTime() });
        let reader = new FileReader();

        reader.onloadend = function () {
          setImage(reader.result);
        }

        reader.readAsDataURL(image_file);
      } else {
        let defaultImg = 'https://www.messagetech.com/wp-content/themes/ml_mti/images/no-image.jpg'
        setImage(defaultImg)
      }
    } catch (err) {
      console.log(err.message)
    }
  }

  const [projectName, setProjectName] = useState('');
  useEffect(() => {
    if (!projectJSON?.title) setProjectName('');
    if (!!projectJSON?.title) setProjectName(projectJSON.title);
  }, [projectJSON]);

  const onBlue = () => {
    if (typeof projectJSON?.title !== 'string') return;
    if (projectJSON?.title === projectName) return;
    let tempJSON = copyObject(projectJSON);
    tempJSON.title = projectName;
    setProjectJSON(tempJSON);

    updateProjectJSON(projectUUID, tempJSON);
  }

  const targetTime = Date.now() + 24 * 60 * 60 * 1000;
  const [showTime, setShowTime] = useState(targetTime - Date.now());
  const [image, setImage] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = Date.now();
      const timeLeft = targetTime - currentTime;

      if (timeLeft <= 0) {
        clearInterval(interval);
        setShowTime(0);
      } else {
        setShowTime(timeLeft);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // Convert milliseconds to hours, minutes, and seconds
  const hours = Math.floor(showTime / (1000 * 60 * 60));
  const minutes = Math.floor((showTime % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((showTime % (1000 * 60)) / 1000);


  const getDate = () => {
    let date = new Date();
    const day = date.getDate();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const str = `${day}/${month}/${year}`;

    return str;
  }

  const handleClickRemoveWatermark = () => {
    setShowPricingPageModal(true)
  }

  const handleClosePricingModal = () => {
    setShowPricingPageModal(false)
  }

  return (
    <>
      <Box className="project-view-wrapper">
        <Box component='section' className="view-body-section">
          <Box className="header-section">
            <Box className="header-left">
              <Link to={'..'} className="home-icons">
                <HomeIcon />
                <Box component='span' />
                <img src={Logo} alt='logo' />
              </Link>

              <Box className="project-title">
                <input className='flex-1 outline-none'
                  value={projectName} onBlur={onBlue}
                  onChange={(e) => { setProjectName(e.target.value); }}
                />
              </Box>
            </Box>

            <Box className="header-right">
              {!loginState && (
                <NavLink to={`/signin/?project_uuid=${projectUUID}`} className="login-btn">Log in</NavLink>
              )}

              <Box className="share-btn" onClick={copyLinkFunc}>Share <ShareIcon /></Box>
            </Box>
          </Box>

          <Box className="video-section">
            <Box className="video-container">
              <video ref={exprtVideoEl} controls src={!!webhookInfo && !!webhookInfo?.signedurl ? webhookInfo?.signedurl : ''}
                style={{ visibility: progressing ? 'visible' : 'hidden' }}
              />

              {!progressing && (
                <Box className="upload-progressing">
                  <Box className="progress-title">
                    <Typography component='h2'>{!!webhookInfo ? webhookInfo.progress : 0}%</Typography>
                  </Box>

                  <Box className="progress-bar">
                    <LinearProgress variant="determinate" value={!!webhookInfo ? webhookInfo.progress : 0} />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>

          <Box className="footer-section">
            <Box className="footer-contain">
              {!!loginState && (
                <Typography component='h2' sx={{ textTransform: 'uppercase' }}>
                  {(userData?.username || '').slice(0, 1)}
                </Typography>
              )}

              <Box component='span'>
                <Typography component='h3'>{!!userData?.username ? userData?.username : 'Anonymous'}</Typography>
                <Box className="content">
                  <Typography component='h5'><LanguageOutlinedIcon /> Unlisted Video</Typography>
                  <Typography component='h6'>{getDate()}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box component='section' className="view-sidebar-section">
          <Box className="sidebar-header-contain">
            <Box className="header-background" />
            <img src={ExportLogo} className="m-auto" alt="export logo" />
            <Typography component='h5'>Your video is about to be ready</Typography>
          </Box>

          <Box component='ul' className="sidebar-link-contain">
            <Box component='li' className="link-item" onClick={copyLinkFunc}>
              <Box component='span' style={{ backgroundColor: '#fff5eb', color: '#ff9e0d' }}><LinkIcon /></Box>
              <Typography component='h2'>Copy Link</Typography>
            </Box>

            <FacebookShareButton disabled={shareDisable}
              disabledStyle={{ opacity: 0.6 }}
              quote={projectJSON?.title}
              url={projectUrl}
              hashtag=''
            >
              <Box component='li' className="link-item">
                <Box component='span' style={{ backgroundColor: '#eaf2fe', color: '#237eef' }}><FacebookIcon /></Box>
                <Typography component='h2'>Facebook</Typography>
              </Box>
            </FacebookShareButton>

            <TwitterShareButton disabled={shareDisable}
              disabledStyle={{ opacity: 0.6 }}
              title={projectJSON?.title}
              url={projectUrl}
              hashtags={[]}
              related={[]}
              via=''
            >
              <Box component='li' className="link-item">
                <Box component='span' style={{ backgroundColor: '#ecf5fe', color: '#1fa0f2' }}><TwitterIcon /></Box>
                <Typography component='h2'>Twitter</Typography>
              </Box>
            </TwitterShareButton>

            <EmailShareButton disabled={shareDisable}
              disabledStyle={{ opacity: 0.6 }}
              subject={projectJSON?.title}
              url={projectUrl}
              separator=''
              body=''
            >
              <Box component='li' className="link-item">
                <Box component='span' style={{ backgroundColor: '#fff1ec', color: '#ff7434' }}><EmailIcon /></Box>
                <Typography component='h2'>Email</Typography>
              </Box>
            </EmailShareButton>

            <LinkedinShareButton disabled={shareDisable}
              disabledStyle={{ opacity: 0.6 }}
              title={projectJSON?.title}
              url={projectUrl}
              summary=''
              source=''
            >
              <Box component='li' className="link-item">
                <Box component='span' style={{ backgroundColor: '#eaf2fe', color: '#237eef' }}><LinkedinIcon /></Box>
                <Typography component='h2'>Linkedin</Typography>
              </Box>
            </LinkedinShareButton>
          </Box>

          {!loginState && (
            <Box className="sidebar-content-section">
              <Box className="content-body-contain">
                <Box className="time-count-section">
                  <Box component='div'>
                    <Typography component='h3'>{hours}</Typography>
                    <Typography component='h4'>Hours</Typography>
                  </Box>

                  <Box component='span' />
                  <Box component='div'>
                    <Typography component='h3'>{minutes}</Typography>
                    <Typography component='h4'>Minutes</Typography>
                  </Box>

                  <Box component='span' />
                  <Box component='div'>
                    <Typography component='h3'>{seconds}</Typography>
                    <Typography component='h4'>Seconds</Typography>
                  </Box>
                </Box>

                <Box className="content-section">
                  {leftTime !== '0 hours 0 minutes 0 seconds' ? (
                    <>
                      <Typography component='h2'>Your project and video will be deleted,</Typography>
                      <Typography component='h2'>once the countdown ends. </Typography>
                      <Typography component='h3'>Sign up now to save your project.</Typography>
                    </>
                  ) : (
                    <Typography component='h3'>Your project deleted</Typography>
                  )}
                </Box>

                {!loginState && (
                  <NavLink className="signUp-btn" to={`/signup/?project_uuid=${projectUUID}`}>Sign Up</NavLink>
                )}
              </Box>

              <Box className="content-footer-contain" onClick={() => { setOpenHelper(!openHelper); }}>
                <Box component='button'>
                  <Typography component='h2'>Want some free stuff?</Typography>
                  {openHelper ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                </Box>

                {openHelper && <Box component='ul' className="helper-contain">
                  <Box component='li'>Record your screen, webcam, or both</Box>
                  <Box component='li'>Add subtitles to your videos</Box>
                  <Box component='li'>Create your Team Workspace</Box>
                  <Box component='li'>Collaborate on videos</Box>
                  <Box component='li'>Generate a transcript</Box>
                  <Box component='span'>Sign up (for free) to get all this and more!</Box>
                </Box>}
              </Box>
            </Box>
          )}

          <Box className="sidebar-button-group">
            {videoDownloading && (
              <Box component='button' style={{display: 'block'}}>
                <Box className="flex" style={{flexDirection: 'row-reverse', justifyContent: "space-between"}}>
                <Typography component='h2'>{progressVal}%</Typography>
                <Typography component='h2'>Downloading... </Typography>
                </Box>
                
                <Box className="w-full">
                  <LinearProgress variant="determinate" value={progressVal} />
                </Box>
              </Box>
            )}
            {!videoDownloading && (
              <Box component='button' onClick={downloadFunc}>
                <FileDownloadOutlinedIcon />
                <Typography component='h2'>Download</Typography>
              </Box>
            )}
            

            <a href={`${EDIT_FRONTEND_URL}/${projectUUID}`} style={{ textDecoration: 'none' }}>
              <Box component='button'><CutIcon /><Typography component='h2'>Re-edit video</Typography></Box>
            </a>
          </Box>


          {!loginState && (
            <NavLink to={`/signin/?project_uuid=${projectUUID}`} className="watermark-btn">
              <LightiningIcon />
              <Typography component='h2'>Remove watermark</Typography>
            </NavLink>
          )}

          {!!loginState && !(userData && userData.subscription > 0)&& (
            <Box className="watermark-btn" onClick={handleClickRemoveWatermark}>
              <LightiningIcon />
              <Typography component='h2'>Remove watermark</Typography>
            </Box>
          )}

          {!loginState && (
            <Box component='footer' className="sidebar-footer">
              <Typography component='h5'>
                Already have an account?
                <NavLink to={`/signin/?project_uuid=${projectUUID}`} className='cursor-pointer ml-10'>Log In</NavLink>
              </Typography>
            </Box>
          )}
        </Box>

        <Snackbar
          open={opanAlert}
          onClose={() => { setOpenAlert(false); }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
        >
          <Alert variant="filled" severity="success" sx={{ width: '250px' }}>Link Copied</Alert>
        </Snackbar>
      </Box >
      <PricingModal open={showPricingPageModal} onCloseModal={handleClosePricingModal} />
    </>
  )
}