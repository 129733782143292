import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
export const EmptyCom = () => {
  const languageStrs = useSelector((store) => store.editdata.languageStrs);
  return (
    <>
        <Typography variant='h3' textAlign="center">{languageStrs.you_login_manage_your_profile}</Typography>
        <div className='m-auto text-center' style={{padding: '3rem',}}>
            <Link to={'/signup'}><button className='btn btn-primary m-2'>{languageStrs.sign_up}</button></Link>
            <b>&</b> 
            <Link to={'/signin'}><button className='btn btn-primary m-2'>{languageStrs.sign_in}</button></Link>
        </div>
    </>
  )
}