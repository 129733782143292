import './videoocareer.scss'
import { Layouts } from '../../component/layouts/layouts'

export const Career8 = () => {
  return (
    <Layouts>
      <div className='flex flex-col items-center justify-center px-10 sm:px-20 md:px-30 lg:px-40'>
        <div className='w-full max-w-1000 flex flex-col gap-10 md:gap-20 lg:gap-30 py-30'>
          <div className='text-center text-20 md:text-30 lg:text-40 font-semibold'>
            Chief Financial Officer (CFO)
          </div>
          <div className='flex flex-col gap-10 mm:gap-20'>
            <div className='text-15 md:text-18'>
              We are seeking an experienced and strategic Chief Financial Officer (CFO) to join our executive team. As the CFO, you will be responsible for overseeing all financial aspects of our organization, including financial planning, budgeting, accounting, and forecasting. You will play a key role in shaping our financial strategy and driving our company's growth.

              This is a critical leadership role that requires strong financial acumen, business insight, and the ability to collaborate effectively with other departments. The ideal candidate is a proactive and results-driven professional with a proven track record in financial management and strategic decision-making.
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Responsibilities:</span>
              <div className='text-15 md:text-18'>
                - Develop and implement financial strategies, plans, and policies to support business goals and drive growth
              </div>
              <div className='text-15 md:text-18'>
                - Oversee financial planning, budgeting, and forecasting processes, ensuring accuracy and alignment with strategic objectives
              </div>
              <div className='text-15 md:text-18'>
                - Provide financial analysis, insights, and recommendations to support decision-making at the executive level
              </div>
              <div className='text-15 md:text-18'>
                - Manage financial reporting, including monthly, quarterly, and annual financial statements, and ensure compliance with regulatory requirements
              </div>
              <div className='text-15 md:text-18'>
                - Monitor cash flow, manage investments, and optimize working capital to maximize financial performance
              </div>
              <div className='text-15 md:text-18'>
                - Assess and mitigate financial risks, including market risks, operational risks, and regulatory risks
              </div>
              <div className='text-15 md:text-18'>
                - Build and maintain strong relationships with external stakeholders, including investors, lenders, and auditors
              </div>
              <div className='text-15 md:text-18'>
                - Lead and develop a high-performing finance team, providing guidance, coaching, and professional development opportunities
              </div>
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Qualifications:</span>
              <div className='text-15 md:text-18'>
                - Bachelor's degree in Finance, Accounting, or a related field (MBA or CPA preferred)
              </div>
              <div className='text-15 md:text-18'>
                - Proven experience as a CFO or in a similar senior financial leadership role
              </div>
              <div className='text-15 md:text-18'>
                - Strong knowledge of financial management principles, practices, and regulations
              </div>
              <div className='text-15 md:text-18'>
                - Demonstrated ability to develop and execute financial strategies to drive business growth
              </div>
              <div className='text-15 md:text-18'>
                - Excellent analytical, problem-solving, and decision-making skills
              </div>
              <div className='text-15 md:text-18'>
                - Solid understanding of financial systems and proficiency in financial software and tools
              </div>
              <div className='text-15 md:text-18'>
                - Strong leadership and communication skills, with the ability to influence and collaborate with stakeholders at all levels
              </div>
              <div className='text-15 md:text-18'>
                - High level of integrity, ethics, and professionalism
              </div>
            </div>

            <div className='text-15 md:text-18'>
              If you are a strategic and results-oriented finance executive with a passion for driving financial performance and contributing to the overall success of an organization, we would love to hear from you. Join our team and be part of a dynamic and growing company that is shaping the future of our industry.

              To apply, please submit your resume and cover letter to info@videoo.io. Please include "CFO Application" in the subject line. Only shortlisted candidates will be contacted.

              We look forward to reviewing your application!
            </div>
          </div>
        </div>
      </div>
    </Layouts>
  )
}