import './videoocareer.scss'
import { Layouts } from '../../component/layouts/layouts'

export const Career7 = () => {
  return (
    <Layouts>
      <div className='flex flex-col items-center justify-center px-10 sm:px-20 md:px-30 lg:px-40'>
        <div className='w-full max-w-1000 flex flex-col gap-10 md:gap-20 lg:gap-30 py-30'>
          <div className='text-center text-20 md:text-30 lg:text-40 font-semibold'>
            Product Owner
          </div>
          <div className='flex flex-col gap-10 mm:gap-20'>
            <div className='text-15 md:text-18'>
              We are seeking a highly motivated and experienced Product Owner to join our team. As a Product Owner, you will be responsible for defining and prioritizing the product roadmap, collaborating with cross-functional teams, and ensuring the successful delivery of high-quality products. You will work closely with stakeholders, including customers, to gather requirements and translate them into actionable product features.

              This is an exciting opportunity to drive the development and growth of our products, working in a dynamic and fast-paced environment. You will have the chance to make a significant impact on the success of our products and contribute to the overall strategy and vision of the company.
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Responsibilities:</span>
              <div className='text-15 md:text-18'>
                - Define and prioritize the product roadmap based on market trends, customer needs, and business goals
              </div>
              <div className='text-15 md:text-18'>
                - Gather and analyze customer requirements, market research, and feedback to identify product opportunities
              </div>
              <div className='text-15 md:text-18'>
                - Collaborate with cross-functional teams, including development, design, and marketing, to deliver high-quality products
              </div>
              <div className='text-15 md:text-18'>
                - Create and maintain product documentation, including user stories, feature specifications, and product backlogs
              </div>
              <div className='text-15 md:text-18'>
                - Conduct user acceptance testing and gather feedback to iterate on product features
              </div>
              <div className='text-15 md:text-18'>
                - Monitor and analyze product metrics and performance to identify areas for improvement
              </div>
              <div className='text-15 md:text-18'>
                - Collaborate with sales and marketing teams to develop go-to-market strategies and product messaging
              </div>
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Qualifications:</span>
              <div className='text-15 md:text-18'>
                - Bachelor's degree in Business, Computer Science, or a related field (MBA preferred)
              </div>
              <div className='text-15 md:text-18'>
                - Proven experience as a Product Owner or similar role in Agile development environments
              </div>
              <div className='text-15 md:text-18'>
                - Strong understanding of product management methodologies and best practices
              </div>
              <div className='text-15 md:text-18'>
                - Excellent communication and collaboration skills, with the ability to effectively work with stakeholders at all levels
              </div>
              <div className='text-15 md:text-18'>
                - Strong analytical and problem-solving abilities
              </div>
              <div className='text-15 md:text-18'>
                - Solid organizational and project management skills, with the ability to prioritize and manage multiple tasks simultaneously
              </div>
              <div className='text-15 md:text-18'>
                - Experience with Agile/Scrum methodologies and tools (e.g., Jira, Confluence)
              </div>
              <div className='text-15 md:text-18'>
                - Passion for delivering high-quality products and driving customer satisfaction
              </div>
            </div>

            <div className='text-15 md:text-18'>
              If you are a strategic thinker with a passion for product management and a track record of driving successful product launches, we would love to hear from you. Join our team and be part of a dynamic and innovative company that is shaping the future of our industry.

              To apply, please submit your resume and cover letter to info@videoo.io. Please include "Product Owner Application" in the subject line. Only shortlisted candidates will be contacted.

              We look forward to reviewing your application!
            </div>
          </div>
        </div>
      </div>
    </Layouts>

  )
}