import Free_plan from "./../../assets/image/pages/pricing/free-plan.png";
import Basic_plan from "./../../assets/image/pages/pricing/basic-plan.png";
import Expert_plan from "./../../assets/image/pages/pricing/expert-plan.png";
import Business_plan from "./../../assets/image/pages/pricing/business-plan.png";

import { BBCLogo, GoogleLogo, DisnetLogo, NetflixLogos, MetaLogo, AmazonLogo } from "../../Icons";
import { FreeChecked, BasicChecked, ProChecked, BusinessChecked } from "../../Icons";
import { BasicChecke2, ProChecke2 } from "../../Icons";
import { Empty, Information } from "../../Icons";

export const GetSelectedPlan = () => {
	let selectedPlan = localStorage.getItem('selected_plan');
	return selectedPlan;
}

export const SetSelectedPlan = (plan) => {
	localStorage.setItem('selected_plan', plan);
}

export const plandata_config = [
	[
		{
			class: "",
			icon_path: Free_plan,
			name: "Free",
			description: "Perfect for quick one off projects under 10 minutes",
			plan: 0,
			monthlyprice: 0,
			Annuallyprice: 0,
			price_description: "per editor/mont",
			btnTxt: "Sign Up For Free",
			featureList: {
				icon: <FreeChecked />,
				items: [
					"Watermarked",
					"10 minutes of subtitles",
					"Upload limit - 1GB",
					"Export length - 10 minutes"
				]
			},
			footerTxt: "See All Features",

			modalInfo: {
				status: false
			}
		}, {
			class: "",
			icon_path: Basic_plan,
			name: "Basic",
			description: "Simple online video editing for individuals",
			plan: 1,
			monthlyprice: 20,
			Annuallyprice: 12,
			saveUp: 28,
			price_description: "per editor/month",
			btnTxt: "Go Basic",
			featureList: {
				icon: <BasicChecked />,
				items: [
					"Export Quality - 1080p",
					"No watermark",
					"720 minutes of subtitles",
					"Upload file size - unlimited",
					"Export length - 25 minutes"
				]
			},
			footerTxt: "See All Features",

			modalInfo: {
				status: true,
				description: "For users who need simple online video editing",
				checkIcon: <BasicChecke2 />,
				planmin: 60,
				planIndex: 2,
				lists: [
					"Unlimited upload file size",
					"No watermarks",
					"Auto Subtitles - Up to ",
					"Export videos up to 25 minutes long",
					"20GB of storage",
					"Clean Audio",
					"Export videos in 1080p"
				]
			}
		}
	], [
		{
			class: "most-popular",
			icon_path: Expert_plan,
			name: "Expert",
			description: "Translations, Text to Speech and more for expert tools",
			plan: 2,
			monthlyprice: 30,
			Annuallyprice: 18,
			saveUp: 21,
			price_description: "per editor/month",
			btnTxt: "Go Pro",
			featureList: {
				icon: <ProChecked />,
				items: [
					"1,440 minutes of subtitles",
					"Translations 50+ languages",
					"Export length - 120 minutes",
					"AI Voiceover 100K+ chars",
				]
			},
			footerTxt: "See All Features",
			modalInfo: {
				status: true,
				description:
					"For professionals that need translations, subtitle exports, longer videos and brand tools",
				checkIcon: <ProChecke2 />,
				planmin: 120,
				planIndex: 1,
				lists: [
					"Everything in BASIC, plus ...",
					"Subtitles : Up to ",
					"Subtitle translations",
					"Edit videos up to 2 hours long",
					"50GB of storage",
					"Clean Audio",
					"Text To Speech: Up to 72,000 characters",
					"Advanced export options"
				]
			}
		}, {
			class: "",
			icon_path: Business_plan,
			name: "Business",
			description: "Advanced collaboration, access and privacy",
			plan: 3,
			monthlyprice: 60,
			Annuallyprice: 46,
			saveUp: 16,
			price_description: "per editor/month",
			btnTxt: "Go Business",
			featureList: {
				icon: <BusinessChecked />,
				items: [
					"7200 minutes of subtitles",
					"100GB storage",
					"Custom templates",
					"Collaborative editing"
				]
			},
			footerTxt: "See All Features",
			modalInfo: {
				status: true,
				description:
					"For teams with advanced collaboration, access, transcription and privacy needs",
				checkIcon: <ProChecke2 />,
				planIndex: 1,
				planmin: 600,
				lists: [
					"Everything in Expert, plus ...",
					"Subtitles : Up to ",
					"Custom templates",
					"Project storage of 100GB",
					"Add-on: Private workspaces",
				]
			}
		}
	]
];

export const plandata_config_de = [
	[
		{
			class: "",
			icon_path: Free_plan,
			name: "Frei",
			description: "Perfekt für schnelle Einzelprojekte unter 10 Minuten",
			plan: 0,
			monthlyprice: 0,
			Annuallyprice: 0,
			price_description: "pro Redakteur/Monat",
			btnTxt: "Melde dich kostenlos an",
			featureList: {
				icon: <FreeChecked />,
				items: [
					"Mit Wasserzeichen versehen",
					"10 Minuten Untertitel",
					"Upload-Limit – 1 GB",
					"Exportlänge – 10 Minuten"
				]
			},
			footerTxt: "Alle Funktionen anzeigen",

			modalInfo: {
				status: false
			}
		}, {
			class: "",
			icon_path: Basic_plan,
			name: "Basic",
			description: "Einfache Online-Videobearbeitung für Einzelpersonen",
			plan: 1,
			monthlyprice: 20,
			Annuallyprice: 12,
			saveUp: 28,
			price_description: "pro Redakteur/Monat",
			btnTxt: "Gehen Sie einfach",
			featureList: {
				icon: <BasicChecked />,
				items: [
					"Exportqualität – 1080p",
					"Kein Wasserzeichen",
					"720 Minuten Untertitel",
					"Dateigröße hochladen – unbegrenzt",
					"Exportlänge – 25 Minuten"
				]
			},
			footerTxt: "Alle Funktionen anzeigen",

			modalInfo: {
				status: true,
				description: "Für Benutzer, die eine einfache Online-Videobearbeitung benötigen",
				checkIcon: <BasicChecke2 />,
				planmin: 60,
				planIndex: 2,
				lists: [
					"Unbegrenzte Upload-Dateigröße",
					"Keine Wasserzeichen",
					"Automatische Untertitel – Bis zu ",
					"Exportieren Sie Videos mit einer Länge von bis zu 25 Minuten",
					"20 GB Speicherplatz",
					"Sauberes Audio",
					"Exportieren Sie Videos in 1080p"
				]
			}
		}
	], [
		{
			class: "most-popular",
			icon_path: Expert_plan,
			name: "Expertin",
			description: "Übersetzungen, Text-to-Speech und mehr für Expertentools",
			plan: 2,
			monthlyprice: 30,
			Annuallyprice: 18,
			saveUp: 21,
			price_description: "pro Redakteur/Monat",
			btnTxt: "Gehen Sie zum Profi",
			featureList: {
				icon: <ProChecked />,
				items: [
					"1.440 Minuten Untertitel",
					"Übersetzungen über 50 Sprachen",
					"Exportlänge - 120 Minuten",
					"KI-Voiceover mit mehr als 100.000 Zeichen",
				]
			},
			footerTxt: "Alle Funktionen anzeigen",
			modalInfo: {
				status: true,
				description:
					"Für Profis, die Übersetzungen, Untertitelexporte, längere Videos und Markentools benötigen",
				checkIcon: <ProChecke2 />,
				planmin: 120,
				planIndex: 1,
				lists: [
					"Alles in BASIC, plus ...",
					"Untertitel: Bis zu ",
					"Untertitelübersetzungen",
					"Bearbeiten Sie Videos mit einer Länge von bis zu 2 Stunden",
					"50 GB Speicherplatz",
					"Sauberes Audio",
					"Text-to-Speech: Bis zu 72.000 Zeichen",
					"Erweiterte Exportoptionen"
				]
			}
		}, {
			class: "",
			icon_path: Business_plan,
			name: "Geschäft",
			description: "Erweiterte Zusammenarbeit, Zugriff und Datenschutz",
			plan: 3,
			monthlyprice: 60,
			Annuallyprice: 46,
			saveUp: 16,
			price_description: "pro Redakteur/Monat",
			btnTxt: "Gehen Sie ins Geschäft",
			featureList: {
				icon: <BusinessChecked />,
				items: [
					"7200 Minuten Untertitel",
					"100 GB Speicher",
					"Benutzerdefinierte Vorlagen",
					"Kollaborative Bearbeitung"
				]
			},
			footerTxt: "Alle Funktionen anzeigen",
			modalInfo: {
				status: true,
				description:
					"Für Teams mit erweiterten Anforderungen an Zusammenarbeit, Zugriff, Transkription und Datenschutz",
				checkIcon: <ProChecke2 />,
				planIndex: 1,
				planmin: 600,
				lists: [
					"Alles in Expert, plus ...",
					"Untertitel: Bis zu ",
					"Benutzerdefinierte Vorlagen",
					"Projektspeicher von 100 GB",
					"Add-on: Private Arbeitsbereiche",
				]
			}
		}
	]
];

export const plandata_config_fr = [
	[
		{
			class: "",
			icon_path: Free_plan,
			name: "Gratuit",
			description: "Parfait pour des projets rapides de moins de 10 minutes",
			plan: 0,
			monthlyprice: 0,
			Annuallyprice: 0,
			price_description: "par éditeur/mois",
			btnTxt: "Inscrivez-vous Gratuitement",
			featureList: {
				icon: <FreeChecked />,
				items: [
					"Avec filigrane",
					"10 minutes de sous-titres",
					"Limite de téléchargement - 1GB",
					"Durée d'exportation - 10 minutes"
				]
			},
			footerTxt: "Voir Toutes les Fonctionnalités",

			modalInfo: {
				status: false
			}
		}, {
			class: "",
			icon_path: Basic_plan,
			name: "Basique",
			description: "Montage vidéo en ligne simple pour les individus",
			plan: 1,
			monthlyprice: 20,
			Annuallyprice: 12,
			saveUp: 28,
			price_description: "par éditeur/mois",
			btnTxt: "Passer au Basique",
			featureList: {
				icon: <BasicChecked />,
				items: [
					"Qualité d'exportation - 1080p",
					"Sans filigrane",
					"720 minutes de sous-titres",
					"Taille de fichier de téléchargement - illimitée",
					"Durée d'exportation - 25 minutes"
				]
			},
			footerTxt: "Voir Toutes les Fonctionnalités",

			modalInfo: {
				status: true,
				description: "Pour les utilisateurs qui ont besoin d'un montage vidéo en ligne simple",
				checkIcon: <BasicChecke2 />,
				planmin: 60,
				planIndex: 2,
				lists: [
					"Taille de fichier de téléchargement illimitée",
					"Sans filigranes",
					"Sous-titres automatiques - Jusqu'à",
					"Exporter des vidéos jusqu'à 25 minutes de longueur",
					"20GB de stockage",
					"Audio Nettoyé",
					"Exporter des vidéos en 1080p"
				]
			}
		}
	], [
		{
			class: "most-popular",
			icon_path: Expert_plan,
			name: "Expert",
			description: "Traductions, Texte en Parole et plus pour des outils experts",
			plan: 2,
			monthlyprice: 30,
			Annuallyprice: 18,
			saveUp: 21,
			price_description: "par éditeur/mois",
			btnTxt: "Devenir Pro",
			featureList: {
				icon: <ProChecked />,
				items: [
					"1.440 minutes de sous-titres",
					"Traductions en 50+ langues",
					"Durée d'exportation - 120 minutes",
					"Voix off AI 100K+ caractères",
				]
			},
			footerTxt: "Voir Toutes les Fonctionnalités",
			modalInfo: {
				status: true,
				description:
					"Pour les professionnels ayant besoin de traductions, d'exportations de sous-titres, de vidéos plus longues et d'outils de marque",
				checkIcon: <ProChecke2 />,
				planmin: 120,
				planIndex: 1,
				lists: [
					"Tout en Basique, plus ...",
					"Sous-titres : Jusqu'à",
					"Traductions de sous-titres",
					"Éditer des vidéos jusqu'à 2 heures de long",
					"50GB de stockage",
					"Audio Nettoyé",
					"Texte en Parole : Jusqu'à 72.000 caractères",
					"Options d'exportation avancées"
				]
			}
		}, {
			class: "",
			icon_path: Business_plan,
			name: "Affaires",
			description: "Collaboration avancée, accès et confidentialité",
			plan: 3,
			monthlyprice: 60,
			Annuallyprice: 46,
			saveUp: 16,
			price_description: "par éditeur/mois",
			btnTxt: "Passer aux Affaires",
			featureList: {
				icon: <BusinessChecked />,
				items: [
					"7200 minutes de sous-titres",
					"100GB de stockage",
					"Modèles personnalisés",
					"Édition collaborative"
				]
			},
			footerTxt: "Voir Toutes les Fonctionnalités",
			modalInfo: {
				status: true,
				description:
					"Pour les équipes ayant besoin de collaboration avancée, d'accès, de transcription et de confidentialité",
				checkIcon: <ProChecke2 />,
				planIndex: 1,
				planmin: 600,
				lists: [
					"Tout en Expert, plus ...",
					"Sous-titres : Jusqu'à",
					"Modèles personnalisés",
					"Stockage de projet de 100GB",
					"Add-on : Espaces de travail privés",
				]
			}
		}
	]
];

export const plandata_config_tr = [
	[
		{
			class: "",
			icon_path: Free_plan,
			name: "Ücretsiz",
			description: "10 dakikadan kısa tek seferlik projeler için mükemmel",
			plan: 0,
			monthlyprice: 0,
			Annuallyprice: 0,
			price_description: "editör/ay başına",
			btnTxt: "Ücretsiz Kaydol",
			featureList: {
				icon: <FreeChecked />,
				items: [
					"Filigranlı",
					"10 dakika altyazı",
					"Yükleme limiti - 1GB",
					"Dışa aktarma süresi - 10 dakika"
				]
			},
			footerTxt: "Tüm Özellikleri Gör",

			modalInfo: {
				status: false
			}
		}, {
			class: "",
			icon_path: Basic_plan,
			name: "Temel",
			description: "Bireyler için basit çevrimiçi video düzenleme",
			plan: 1,
			monthlyprice: 20,
			Annuallyprice: 12,
			saveUp: 28,
			price_description: "editör/ay başına",
			btnTxt: "Temel'e Geç",
			featureList: {
				icon: <BasicChecked />,
				items: [
					"Dışa Aktarma Kalitesi - 1080p",
					"Filigransız",
					"720 dakika altyazı",
					"Yükleme dosya boyutu - sınırsız",
					"Dışa aktarma süresi - 25 dakika"
				]
			},
			footerTxt: "Tüm Özellikleri Gör",

			modalInfo: {
				status: true,
				description: "Basit çevrimiçi video düzenlemeye ihtiyaç duyan kullanıcılar için",
				checkIcon: <BasicChecke2 />,
				planmin: 60,
				planIndex: 2,
				lists: [
					"Sınırsız yükleme dosya boyutu",
					"Filigransız",
					"Otomatik Altyazılar - Kadar",
					"25 dakikaya kadar videoları dışa aktar",
					"20GB depolama",
					"Temiz Ses",
					"Videoları 1080p'de dışa aktar"
				]
			}
		}
	], [
		{
			class: "most-popular",
			icon_path: Expert_plan,
			name: "Uzman",
			description: "Uzman araçları için Çeviriler, Metinden Konuşmaya ve daha fazlası",
			plan: 2,
			monthlyprice: 30,
			Annuallyprice: 18,
			saveUp: 21,
			price_description: "editör/ay başına",
			btnTxt: "Profesyonele Geç",
			featureList: {
				icon: <ProChecked />,
				items: [
					"1,440 dakika altyazı",
					"50+ dilde çeviriler",
					"Dışa aktarma süresi - 120 dakika",
					"AI Seslendirme 100K+ karakter",
				]
			},
			footerTxt: "Tüm Özellikleri Gör",
			modalInfo: {
				status: true,
				description:
					"Çevirilere, altyazı dışa aktarmalara, daha uzun videolara ve marka araçlarına ihtiyaç duyan profesyoneller için",
				checkIcon: <ProChecke2 />,
				planmin: 120,
				planIndex: 1,
				lists: [
					"TEMEL'deki her şey, artı ...",
					"Altyazılar: Kadar",
					"Altyazı çevirileri",
					"2 saat uzunluğunda videoları düzenle",
					"50GB depolama",
					"Temiz Ses",
					"Metinden Konuşmaya: 72,000 karaktere kadar",
					"Gelişmiş dışa aktarma seçenekleri"
				]
			}
		}, {
			class: "",
			icon_path: Business_plan,
			name: "İş",
			description: "Gelişmiş işbirliği, erişim ve gizlilik için",
			plan: 3,
			monthlyprice: 60,
			Annuallyprice: 46,
			saveUp: 16,
			price_description: "editör/ay başına",
			btnTxt: "İş'e Geç",
			featureList: {
				icon: <BusinessChecked />,
				items: [
					"7200 dakika altyazı",
					"100GB depolama",
					"Özel şablonlar",
					"İşbirlikçi düzenleme"
				]
			},
			footerTxt: "Tüm Özellikleri Gör",
			modalInfo: {
				status: true,
				description:
					"Gelişmiş işbirliği, erişim, transkripsiyon ve gizlilik ihtiyaçları olan takımlar için",
				checkIcon: <ProChecke2 />,
				planIndex: 1,
				planmin: 600,
				lists: [
					"UZMAN'daki her şey, artı ...",
					"Altyazılar: Kadar",
					"Özel şablonlar",
					"100GB proje depolama",
					"Ek: Özel çalışma alanları",
				]
			}
		}
	]
];

export const plandata_config_es = [
	[
		{
			class: "",
			icon_path: Free_plan,
			name: "Gratis",
			description: "Perfecto para proyectos rápidos y únicos de menos de 10 minutos",
			plan: 0,
			monthlyprice: 0,
			Annuallyprice: 0,
			price_description: "por editor/mes",
			btnTxt: "Regístrate Gratis",
			featureList: {
				icon: <FreeChecked />,
				items: [
					"Con marca de agua",
					"10 minutos de subtítulos",
					"Límite de carga - 1GB",
					"Longitud de exportación - 10 minutos"
				]
			},
			footerTxt: "Ver Todas las Características",

			modalInfo: {
				status: false
			}
		}, {
			class: "",
			icon_path: Basic_plan,
			name: "Básico",
			description: "Edición de video en línea simple para individuos",
			plan: 1,
			monthlyprice: 20,
			Annuallyprice: 12,
			saveUp: 28,
			price_description: "por editor/mes",
			btnTxt: "Ir a Básico",
			featureList: {
				icon: <BasicChecked />,
				items: [
					"Calidad de exportación - 1080p",
					"Sin marca de agua",
					"720 minutos de subtítulos",
					"Tamaño de archivo de carga - ilimitado",
					"Longitud de exportación - 25 minutos"
				]
			},
			footerTxt: "Ver Todas las Características",

			modalInfo: {
				status: true,
				description: "Para usuarios que necesitan edición de video en línea simple",
				checkIcon: <BasicChecke2 />,
				planmin: 60,
				planIndex: 2,
				lists: [
					"Tamaño de archivo de carga ilimitado",
					"Sin marcas de agua",
					"Subtítulos automáticos - Hasta",
					"Exportar videos de hasta 25 minutos de largo",
					"20GB de almacenamiento",
					"Audio limpio",
					"Exportar videos en 1080p"
				]
			}
		}
	], [
		{
			class: "most-popular",
			icon_path: Expert_plan,
			name: "Experto",
			description: "Traducciones, Texto a Voz y más para herramientas de expertos",
			plan: 2,
			monthlyprice: 30,
			Annuallyprice: 18,
			saveUp: 21,
			price_description: "por editor/mes",
			btnTxt: "Ir a Pro",
			featureList: {
				icon: <ProChecked />,
				items: [
					"1,440 minutos de subtítulos",
					"Traducciones 50+ idiomas",
					"Longitud de exportación - 120 minutos",
					"Voz en off de IA 100K+ caracteres",
				]
			},
			footerTxt: "Ver Todas las Características",
			modalInfo: {
				status: true,
				description:
					"Para profesionales que necesitan traducciones, exportaciones de subtítulos, videos más largos y herramientas de marca",
				checkIcon: <ProChecke2 />,
				planmin: 120,
				planIndex: 1,
				lists: [
					"Todo en BÁSICO, más ...",
					"Subtítulos: Hasta",
					"Traducciones de subtítulos",
					"Editar videos de hasta 2 horas de largo",
					"50GB de almacenamiento",
					"Audio limpio",
					"Texto a Voz: Hasta 72,000 caracteres",
					"Opciones de exportación avanzadas"
				]
			}
		}, {
			class: "",
			icon_path: Business_plan,
			name: "Negocios",
			description: "Colaboración avanzada, acceso y privacidad",
			plan: 3,
			monthlyprice: 60,
			Annuallyprice: 46,
			saveUp: 16,
			price_description: "por editor/mes",
			btnTxt: "Ir a Negocios",
			featureList: {
				icon: <BusinessChecked />,
				items: [
					"7200 minutos de subtítulos",
					"Almacenamiento de 100GB",
					"Plantillas personalizadas",
					"Edición colaborativa"
				]
			},
			footerTxt: "Ver Todas las Características",
			modalInfo: {
				status: true,
				description:
					"Para equipos con necesidades avanzadas de colaboración, acceso, transcripción y privacidad",
				checkIcon: <ProChecke2 />,
				planIndex: 1,
				planmin: 600,
				lists: [
					"Todo en Experto, más ...",
					"Subtítulos: Hasta",
					"Plantillas personalizadas",
					"Almacenamiento de proyectos de 100GB",
					"Add-on: Espacios de trabajo privados",
				]
			}
		}
	]
];

export const plandata_config_it = [
	[
		{
			class: "",
			icon_path: Free_plan,
			name: "Gratuito",
			description: "Perfetto per progetti rapidi di durata inferiore ai 10 minuti",
			plan: 0,
			monthlyprice: 0,
			Annuallyprice: 0,
			price_description: "per editor/mese",
			btnTxt: "Iscriviti Gratis",
			featureList: {
				icon: <FreeChecked />,
				items: [
					"Con watermark",
					"10 minuti di sottotitoli",
					"Limite di caricamento - 1GB",
					"Lunghezza dell'esportazione - 10 minuti"
				]
			},
			footerTxt: "Vedi Tutte le Funzionalità",

			modalInfo: {
				status: false
			}
		}, {
			class: "",
			icon_path: Basic_plan,
			name: "Base",
			description: "Editing video online semplice per individui",
			plan: 1,
			monthlyprice: 20,
			Annuallyprice: 12,
			saveUp: 28,
			price_description: "per editor/mese",
			btnTxt: "Passa a Base",
			featureList: {
				icon: <BasicChecked />,
				items: [
					"Qualità dell'esportazione - 1080p",
					"Senza watermark",
					"720 minuti di sottotitoli",
					"Dimensione del file di caricamento - illimitata",
					"Lunghezza dell'esportazione - 25 minuti"
				]
			},
			footerTxt: "Vedi Tutte le Funzionalità",

			modalInfo: {
				status: true,
				description: "Per utenti che necessitano di un editing video online semplice",
				checkIcon: <BasicChecke2 />,
				planmin: 60,
				planIndex: 2,
				lists: [
					"Dimensione del file di caricamento illimitata",
					"Senza watermark",
					"Sottotitoli automatici - Fino a",
					"Esporta video fino a 25 minuti di lunghezza",
					"20GB di spazio di archiviazione",
					"Audio Pulito",
					"Esporta video in 1080p"
				]
			}
		}
	], [
		{
			class: "most-popular",
			icon_path: Expert_plan,
			name: "Esperto",
			description: "Traduzioni, Testo a Voce e altro per strumenti avanzati",
			plan: 2,
			monthlyprice: 30,
			Annuallyprice: 18,
			saveUp: 21,
			price_description: "per editor/mese",
			btnTxt: "Diventa Pro",
			featureList: {
				icon: <ProChecked />,
				items: [
					"1.440 minuti di sottotitoli",
					"Traduzioni in 50+ lingue",
					"Lunghezza dell'esportazione - 120 minuti",
					"Voce fuori campo AI 100K+ caratteri",
				]
			},
			footerTxt: "Vedi Tutte le Funzionalità",
			modalInfo: {
				status: true,
				description:
					"Per professionisti che necessitano di traduzioni, esportazioni di sottotitoli, video più lunghi e strumenti di marca",
				checkIcon: <ProChecke2 />,
				planmin: 120,
				planIndex: 1,
				lists: [
					"Tutto in BASE, più ...",
					"Sottotitoli: Fino a",
					"Traduzioni dei sottotitoli",
					"Modifica video fino a 2 ore di lunghezza",
					"50GB di spazio di archiviazione",
					"Audio Pulito",
					"Testo a Voce: Fino a 72.000 caratteri",
					"Opzioni di esportazione avanzate"
				]
			}
		}, {
			class: "",
			icon_path: Business_plan,
			name: "Business",
			description: "Collaborazione avanzata, accesso e privacy",
			plan: 3,
			monthlyprice: 60,
			Annuallyprice: 46,
			saveUp: 16,
			price_description: "per editor/mese",
			btnTxt: "Diventa Business",
			featureList: {
				icon: <BusinessChecked />,
				items: [
					"7200 minuti di sottotitoli",
					"100GB di spazio di archiviazione",
					"Modelli personalizzati",
					"Editing collaborativo"
				]
			},
			footerTxt: "Vedi Tutte le Funzionalità",
			modalInfo: {
				status: true,
				description:
					"Per team con esigenze avanzate di collaborazione, accesso, trascrizione e privacy",
				checkIcon: <ProChecke2 />,
				planIndex: 1,
				planmin: 600,
				lists: [
					"Tutto in Esperto, più ...",
					"Sottotitoli: Fino a",
					"Modelli personalizzati",
					"Archiviazione del progetto di 100GB",
					"Add-on: Workspace privati",
				]
			}
		}
	]
];

export const plandata_config_pt = [
	[
		{
			class: "",
			icon_path: Free_plan,
			name: "Grátis",
			description: "Perfeito para projetos rápidos de até 10 minutos",
			plan: 0,
			monthlyprice: 0,
			Annuallyprice: 0,
			price_description: "por editor/mês",
			btnTxt: "Inscreva-se Grátis",
			featureList: {
				icon: <FreeChecked />,
				items: [
					"Com marca d'água",
					"10 minutos de legendas",
					"Limite de upload - 1GB",
					"Duração de exportação - 10 minutos"
				]
			},
			footerTxt: "Ver Todas as Funcionalidades",

			modalInfo: {
				status: false
			}
		}, {
			class: "",
			icon_path: Basic_plan,
			name: "Básico",
			description: "Edição de vídeo online simples para indivíduos",
			plan: 1,
			monthlyprice: 20,
			Annuallyprice: 12,
			saveUp: 28,
			price_description: "por editor/mês",
			btnTxt: "Tornar Básico",
			featureList: {
				icon: <BasicChecked />,
				items: [
					"Qualidade de exportação - 1080p",
					"Sem marca d'água",
					"720 minutos de legendas",
					"Tamanho de arquivo de upload - ilimitado",
					"Duração de exportação - 25 minutos"
				]
			},
			footerTxt: "Ver Todas as Funcionalidades",

			modalInfo: {
				status: true,
				description: "Para usuários que precisam de edição de vídeo online simples",
				checkIcon: <BasicChecke2 />,
				planmin: 60,
				planIndex: 2,
				lists: [
					"Tamanho de arquivo de upload ilimitado",
					"Sem marcas d'água",
					"Legendas automáticas - Até",
					"Exportar vídeos de até 25 minutos de duração",
					"20GB de armazenamento",
					"Áudio Limpo",
					"Exportar vídeos em 1080p"
				]
			}
		}
	], [
		{
			class: "most-popular",
			icon_path: Expert_plan,
			name: "Especialista",
			description: "Traduções, Texto para Fala e mais para ferramentas avançadas",
			plan: 2,
			monthlyprice: 30,
			Annuallyprice: 18,
			saveUp: 21,
			price_description: "por editor/mês",
			btnTxt: "Tornar Pro",
			featureList: {
				icon: <ProChecked />,
				items: [
					"1.440 minutos de legendas",
					"Traduções em mais de 50 línguas",
					"Duração de exportação - 120 minutos",
					"Voz sintética AI 100K+ caracteres",
				]
			},
			footerTxt: "Ver Todas as Funcionalidades",
			modalInfo: {
				status: true,
				description:
					"Para profissionais que precisam de traduções, exportações de legendas, vídeos mais longos e ferramentas de marca",
				checkIcon: <ProChecke2 />,
				planmin: 120,
				planIndex: 1,
				lists: [
					"Tudo no Básico, mais ...",
					"Legendas: Até",
					"Traduções de legendas",
					"Editar vídeos de até 2 horas de duração",
					"50GB de armazenamento",
					"Áudio Limpo",
					"Texto para Fala: Até 72.000 caracteres",
					"Opções avançadas de exportação"
				]
			}
		}, {
			class: "",
			icon_path: Business_plan,
			name: "Negócios",
			description: "Colaboração avançada, acesso e privacidade",
			plan: 3,
			monthlyprice: 60,
			Annuallyprice: 46,
			saveUp: 16,
			price_description: "por editor/mês",
			btnTxt: "Tornar Negócios",
			featureList: {
				icon: <BusinessChecked />,
				items: [
					"7200 minutos de legendas",


					"100GB de armazenamento",
					"Modelos personalizados",
					"Edição colaborativa"
				]
			},
			footerTxt: "Ver Todas as Funcionalidades",
			modalInfo: {
				status: true,
				description:
					"Para equipes com necessidades avançadas de colaboração, acesso, transcrição e privacidade",
				checkIcon: <ProChecke2 />,
				planIndex: 1,
				planmin: 600,
				lists: [
					"Tudo no Especialista, mais ...",
					"Legendas: Até",
					"Modelos personalizados",
					"Armazenamento do projeto de 100GB",
					"Add-on: Espaços de trabalho privados",
				]
			}
		}
	]
];




export const usedLogs = [
	<BBCLogo />,
	<GoogleLogo />,
	<DisnetLogo />,
	<NetflixLogos />,
	<MetaLogo />,
	<AmazonLogo />
];

const GridListClass = "compares-gridlist-icon";
export const comparePlan_lists = [
	// Basics sections
	[{ type: "headerTitle", title: "The Basics" }],
	[
		{
			type: "header",
			title: "No Watermark",
			tip: "Whether your video is watermark free",
			infoIcon: <Information />
		},
		{ type: "icon", item: <Empty className={GridListClass} /> },
		{ type: "icon", item: <BasicChecked className={GridListClass} /> },
		{ type: "icon", item: <ProChecked className={GridListClass} /> },
		{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	], [
		{
			type: "header",
			title: "Video Export Length",
			tip: "The maximum video length you can export",
			infoIcon: <Information />
		},
		{ type: "title", title: "10 mins" },
		{ type: "title", title: "25 mins" },
		{ type: "title", title: "2 hours" },
		{ type: "title", title: "2 hours" }
	], [
		{
			type: "header",
			title: "Upload File Size",
			tip: "The maximum file size that you can upload",
			infoIcon: <Information />
		},
		{ type: "title", title: "250MB" },
		{ type: "title", title: "Unlimited" },
		{ type: "title", title: "Unlimited" },
		{ type: "title", title: "Unlimited" }
	], [
		{
			type: "header",
			title: "Projects",
			tip: "Number of projects you can make",
			infoIcon: <Information />
		},
		{ type: "title", title: "Unlimited" },
		{ type: "title", title: "Unlimited" },
		{ type: "title", title: "Unlimited" },
		{ type: "title", title: "Unlimited" }
	], [
		{
			type: "header",
			title: "Storage",
			tip: "Storage limits for your projects and their assets",
			infoIcon: <Information />
		},
		{ type: "title", title: "2GB" },
		{ type: "title", title: "10GB" },
		{ type: "title", title: "50GB" },
		{ type: "title", title: "100GB" }
	], [
		{
			type: "header",
			title: "Export Quality",
			tip: "Highest exportable project resolution",
			infoIcon: <Information />
		},
		{ type: "title", title: "720p" },
		{ type: "title", title: "1080p" },
		{ type: "title", title: "1080p" },
		{ type: "title", title: "4k" }
	// ], [
	// 	{
	// 		type: "header",
	// 		title: "Text Animations",
	// 		tip: "Add animated text to your videos",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "title", title: "Limited" },
	// 	{ type: "title", title: "Unlimited" },
	// 	{ type: "title", title: "Unlimited" },
	// 	{ type: "title", title: "Unlimited" }
	// ], [
	// 	{
	// 		type: "header",
	// 		title: "Stock Audio",
	// 		tip: "Access to our vast library of stock audio",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "title", title: "Limited" },
	// 	{ type: "title", title: "Unlimited" },
	// 	{ type: "title", title: "Unlimited" },
	// 	{ type: "title", title: "Unlimited" }
	// ], [
	// 	{
	// 		type: "header",
	// 		title: "Stock Video",
	// 		tip: "Access to our vast library of stock video",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "title", title: "Limited" },
	// 	{ type: "title", title: "Unlimited" },
	// 	{ type: "title", title: "Unlimited" },
	// 	{ type: "title", title: "Unlimited" }
	], [
		{
			type: "header",
			title: "Templates",
			tip: "Professional & editable video templates",
			infoIcon: <Information />
		},
		{ type: "title", title: "Limited" },
		{ type: "title", title: "Unlimited" },
		{ type: "title", title: "Unlimited" },
		{ type: "title", title: "Unlimited" }
	], [
		{
			type: "header",
			title: "Custom Templates",
			tip:
				"Create your own templates to make video creation faster and more consistent",
			infoIcon: <Information />
		},
		{ type: "icon", item: <Empty className={GridListClass} /> },
		{ type: "icon", item: <Empty className={GridListClass} /> },
		{ type: "icon", item: <Empty className={GridListClass} /> },
		{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	], [
		{
			type: "header",
			title: "Audio Noise Removal",
			tip: "Remove background noise and enhance your audio quality",
			infoIcon: <Information />
		},
		{ type: "title", title: "Up to 5 min/mo" },
		{ type: "title", title: "Up to 200 min/yr" },
		{ type: "title", title: "Up to 600 min/yr" },
		{ type: "title", title: "Up to 1500 min/yr" }
	], [
		{
			type: "header",
			title: "Support",
			tip: "Help from our friendly support team",
			infoIcon: <Information />
		},
		{ type: "title", title: "Chat" },
		{ type: "title", title: "Chat" },
		{ type: "title", title: "Chat" },
		{ type: "title", title: "Custom support on request" }
	],
	// Subtitles section
	[{ type: "border" }],
	[{ type: "headerTitle", title: "Subtitles" }],
	[
		{
			type: "header",
			title: "Auto Subtitles",
			tip: "Maximum allowed transcription time in one billing period",
			infoIcon: <Information />
		},
		{ type: "title", title: "Up to 10 min/mo" },
		{ type: "title", title: "Up to 720 min/yr" },
		{ type: "title", title: "Up to 1,440 min/yr" },
		{ type: "title", title: "Up to 7,200 min/yr" }
	], [
	// 	{
	// 		type: "header",
	// 		title: "SRT Subtitles Downloads",
	// 		tip:
	// 			"Ability to export .srt, .vtt and .txt files with generated subtitles",
	// 		infoIcon: <Information />
	// 	},
	// 	{
	// 		type: "icon",
	// 		item: <Empty className={GridListClass} />
	// 	},
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "icon", item: <ProChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	// ], [
		{
			type: "header",
			title: "Subtitle Translation",
			tip:
				"Translate subtitles into more than 50 languages - counts towards your subtitle transcription time",
			infoIcon: <Information />
		},
		{ type: "icon", item: <FreeChecked className={GridListClass} /> },
		{ type: "icon", item: <BasicChecked className={GridListClass} /> },
		{ type: "icon", item: <ProChecked className={GridListClass} /> },
		{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	// ], [
	// 	{
	// 		type: "header",
	// 		title: "Video Localization Tools",
	// 		tip:
	// 			"Edit and manage multiple subtitle languages tracks at the same time",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "icon", item: <ProChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	],
	// AI Generated Visuals section
	// [{ type: "border" }],
	// [{ type: "headerTitle", title: "AI Generated Visuals" }],
	// [
	// 	{
	// 		type: "header",
	// 		title: "AI Generated Images",
	// 		tip: "Visuals that are generated by AI",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "title", title: "Up to 5 images/mo" },
	// 	{ type: "title", title: "Up to 200 images/yr" },
	// 	{ type: "title", title: "Up to 500 images/yr" },
	// 	{ type: "title", title: "Up to 800 images/yr" }
	// ],
	// Workflow Tools
	[{ type: "border" }],
	[{ type: "headerTitle", title: "Workflow Tools" }],
	[
		{
			type: "header",
			title: "Screen & Cam Recorder",
			tip: "Record your webcam and screen and add it to the editor",
			infoIcon: <Information />
		},
		{
			type: "icon",
			item: <FreeChecked className={GridListClass} />
		},
		{ type: "icon", item: <BasicChecked className={GridListClass} /> },
		{ type: "icon", item: <ProChecked className={GridListClass} /> },
		{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	], [
		{
			type	: "header",
			title: "Video Compressor",
			tip: "Reduce file size for easy distribution",
			infoIcon: <Information />
		},
		{ type: "title", title: "Up to 1GB" },
		{ type: "title", title: "Up to 5GB" },
		{ type: "title", title: "Unlimited" },
		{ type: "title", title: "Unlimited" }
	], [
		{
			type: "header",
			title: "Video Converter",
			tip: "Convert videos into MP4, WMV, MOV, and AVI file types",
			infoIcon: <Information />
		},
		{ type: "title", title: "Up to 1GB" },
		{ type: "title", title: "Up to 5GB" },
		{ type: "title", title: "Unlimited" },
		{ type: "title", title: "Unlimited" }
	], [
		{
			type: "header",
			title: "Video Player",
			tip: "Let others view your video online instantly",
			infoIcon: <Information />
		},
		{ type: "icon", item: <FreeChecked className={GridListClass} /> },
		{ type: "icon", item: <BasicChecked className={GridListClass} /> },
		{ type: "icon", item: <ProChecked className={GridListClass} /> },
		{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	// ], [
	// 	{
	// 		type: "header",
	// 		title: "Commenting & Review",
	// 		tip: "Collect feedback from you team with timestamped comments",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "icon", item: <FreeChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BasicChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <ProChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	], [
		{
			type: "header",
			title: "Share & Embed",
			tip: "Share via URL or embed ad-free video into your website",
			infoIcon: <Information />
		},
		{ type: "icon", item: <FreeChecked className={GridListClass} /> },
		{ type: "icon", item: <BasicChecked className={GridListClass} /> },
		{ type: "icon", item: <ProChecked className={GridListClass} /> },
		{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	],
	// Live Streaming section
	// [{ type: "border" }],
	// [{ type: "headerTitle", title: "Live Streaming" }],
	// [
	// 	{
	// 		type: "header",
	// 		title: "Stream Anywhere (1080p)",
	// 		tip: "Youtube, Facebook, LinkedIn, Twitter & Twitch + RTMP",
	// 		infoIcon: <Information />
	// 	},
	// 	{
	// 		type: "icon",
	// 		item: <FreeChecked className={GridListClass} />
	// 	},
	// 	{ type: "icon", item: <BasicChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <ProChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	// ], [
	// 	{
	// 		type: "header",
	// 		title: "Stream Recording",
	// 		tip: "Your stream gets automatically recorded. Up to 1080p",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "title", title: "Unlimited" },
	// 	{ type: "title", title: "Unlimited" },
	// 	{ type: "title", title: "Unlimited" },
	// 	{ type: "title", title: "Unlimited" }
	// ], [
	// 	{
	// 		type: "header",
	// 		title: "Invite Guests",
	// 		tip: "Have up to 8 guests on the stream, including yourself",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "title", title: "Up to 8" },
	// 	{ type: "title", title: "Up to 8" },
	// 	{ type: "title", title: "Up to 8" },
	// 	{ type: "title", title: "Up to 8" }
	// ], [
	// 	{
	// 		type: "header",
	// 		title: "Customizable Stream",
	// 		tip: "Switch between customizable layouts and share your screen",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "icon", item: <FreeChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BasicChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <ProChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	// ], [
	// 	{
	// 		type: "header",
	// 		title: "Transcriptions",
	// 		tip:
	// 			"Automatic transcript of all your broadcasts - support for .txt & .srt",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "icon", item: <FreeChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BasicChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <ProChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	// ], [
	// 	{
	// 		type: "header",
	// 		title: "Content Repurposing",
	// 		tip: "Edit your broadcasts in text-based editor and create clips",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "icon", item: <FreeChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BasicChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <ProChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	// ], [
	// 	{
	// 		type: "header",
	// 		title: "Custom Watermark",
	// 		tip: "Remove the VIDEOO.IO Live logo or replace it with your own",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "icon", item: <FreeChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BasicChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <ProChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	// ],
	// [{ type: "border" }],
	// // Brand Tools
	// [{ type: "headerTitle", title: "Brand Tools" }],
	// [
	// 	{
	// 		type: "header",
	// 		title: "Brand Kit",
	// 		tip: "Easily accessible brand assets to be used across projects",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "icon", item: <ProChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	// ], [
	// 	{
	// 		type: "header",
	// 		title: "Custom Fonts",
	// 		tip: "Upload unlimited custom fonts to use with video captions",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "icon", item: <ProChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	// ], [
	// 	{
	// 		type: "header",
	// 		title: "Brand Colors",
	// 		tip: "Reusable pallet of your brand colors to use across your projects",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "icon", item: <ProChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	// ], [
	// 	{
	// 		type: "header",
	// 		title: "Custom Watermarks",
	// 		tip: "Add custom watermarks on your projects",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "icon", item: <ProChecked className={GridListClass} /> },
	// 	{ type: "icon", item: <BusinessChecked className={GridListClass} /> }
	// ],
	[{ type: "border" }],
	// Corporate Add-Ons
	[{ type: "headerTitle", title: "Corporate Add-Ons" }],
	[
		{
			type: "header",
			title: "Private Workspaces",
			tip: "Segregated private workspaces for multiple teams",
			infoIcon: <Information />
		},
		{ type: "icon", item: <Empty className={GridListClass} /> },
		{ type: "icon", item: <Empty className={GridListClass} /> },
		{ type: "icon", item: <Empty className={GridListClass} /> },
		{ type: "link", title: "Contact Sales", link: "mailto:info@videoo.io" }
	], [
		{
			type: "header",
			title: "Integrations",
			tip: "Link up your video production workflow",
			infoIcon: <Information />
		},
		{ type: "icon", item: <Empty className={GridListClass} /> },
		{ type: "icon", item: <Empty className={GridListClass} /> },
		{ type: "icon", item: <Empty className={GridListClass} /> },
		{ type: "link", title: "Contact Sales", link: "mailto:info@videoo.io" }
	],
	// ], [
	// 	{
	// 		type: "header",
	// 		title: "Batch automation",
	// 		tip: "Auto clean, transcribe, translate and brand videos",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "link", title: "Contact Sales", link: "mailto:info@videoo.io" }
	// ], [
	// 	{
	// 		type: "header",
	// 		title: "Meeting recording",
	// 		tip: "Record, transcribe, share and archive video meetings",
	// 		infoIcon: <Information />
	// 	},
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "icon", item: <Empty className={GridListClass} /> },
	// 	{ type: "link", title: "Contact Sales", link: "mailto:info@videoo.io" }
	[
		{
			type: "header",
			title: "Custom privacy & security",
			tip: "Security audits, Data Processing Agreements",
			infoIcon: <Information />
		},
		{ type: "icon", item: <Empty className={GridListClass} /> },
		{ type: "icon", item: <Empty className={GridListClass} /> },
		{ type: "icon", item: <Empty className={GridListClass} /> },
		{ type: "link", title: "Contact Sales", link: "mailto:info@videoo.io" }
	]
];

export const FreequentlyQuestionInfo = [
	{
		hader: "Do you provide subscription options for educational institutions?",
		content:
			"We certainly do! Our team offers custom education plans for faculty-wide use. If you're interested in learning more, please reach out to our sales team at ",
		link: { status: true, url: "mailto:info@videoo.io", title: " info@videoo.io" }
	}, {
		hader: "How would the watermark appear?",
		content:
			"The watermark appears in the corner of videos that are produced with the free plan. Our self-funded startup relies on this watermark to expand, but it doesn't take away from the quality of your content. You may opt for one of our EXPERT plans to eliminate the watermark.",
		link: { status: false, url: "", title: "" }
	}, {
		hader: "Is there a free trial available for the paid plans?",
		content:
			"If you're interested in trying out our paid plans, we offer a free trial specifically for Corporate customers. To qualify, you'll need a minimum of 6 users. Simply reach out to our sales team to set up your trial via ",
		link: { status: true, url: "mailto:info@videoo.io", title: "info@videoo.io" }
	}, {
		hader: "Is it possible to cancel my subscription at any time?",
		content:
			"Certainly, you can cancel your plan anytime by accessing your account page from your profile. If you decide to cancel, you will still have access to all the premium features until the end of your billing cycle.",
		link: { status: false, url: "", title: "" }
	}, {
		hader: "What payment methods and currencies do you accept?",
		content:
			"We accept all major credit and debit cards and prices are displayed in your local currency where possible. If your currency is not available, prices will be displayed in USD. Our payment system is powered by Stripe, a trusted and secure payment processor. If you encounter any issues paying with your card, please don't hesitate to contact us and we'll work with you to find a solution.",
		link: { status: false, url: "", title: "" }
	}, {
		hader: "Can I access receipts for my transactions?",
		content: "You can easily access receipts for all your transactions in your account dashboard.",
		link: { status: false, url: "", title: "" }
	}, {
		hader: "Is there any change to refund the plan ?",
		content: "If you are wondering about our refund policy, we have detailed it on our Terms of Sale page which can be accessed on our website dashboard.",
		link: { status: true, url: "", title: "" }
	}, {
		hader: "What are the transcription limitations for subtitles and translations?",
		content:
			"Each plan comes with a set limit for transcription minutes during a specific period of time. Both subtitles and translations contribute to your transcription limit. For instance, if you add subtitles to a 30-minute video, it will be counted as 30 minutes towards your limit. Similarly, if you translate it from English to Spanish, it will add another 30 minutes, bringing the total transcription time used to 60 minutes. The limit is set either on a monthly or annual basis, depending on your chosen billing cycle. Any unused minutes during a billing cycle will not carry over to the next cycle. Therefore, you may want to opt for an annual plan for greater flexibility in using your transcription minutes.",
		link: { status: false, url: "", title: "" }
	}, {
		hader:
			"I need a high volume of subtitles and translations over a short time frame, can you help me ?",
		content:
			"If you require a significant amount of subtitles and translations within a short period, and the monthly limit does not meet your needs, you may want to consider upgrading to a higher plan or opting for an annual plan. With an annual plan, you have the flexibility to use your entire yearly transcription limit within a single month if necessary.",
		link: { status: false, url: "", title: "" }
	}, {
		hader: "What is the quickest way to get in touch with you if I need assistance?",
		content:
			"We understand that video editing can be challenging, and we value your business. If you need assistance, you can reach us through our email at info@videoo.io or our live chat feature during our business hours, and we will respond promptly.",
		link: { status: false, url: "", title: "" }
	}, {
		hader: "What is your Companies House number?",
		content: "Our Companies House number is ",
		link: { status: true, url: "https://beta.companieshouse.gov.uk/company/15462119", title: "15462119" }
	}
];
