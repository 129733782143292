export const editorLangEsStrs = {
    welcomNewProject : 'Bienvenido a tu nuevo proyecto',
    pls_add_media:'Por favor, añade medios a tu proyecto',
    add_video:'Añadir Video',
    add_audio:'Añadir Audio',
    add_image:'Añadir Imagen',
    add_webcam:'Captura WebCam',
    add_screen:'Captura de Pantalla',
    add_webcam_screen:'Captura WebCam & Pantalla',
    capture_screen_camera:'¡Captura tu pantalla y cámara!',
    video_captureDevice:'Dispositivo de Captura de Video',
    resolution: 'Resolución',
    audio_captureDevice: 'Dispositivo de Captura de Audio',
    resize_video: 'Redimensionar Video',
    rotate_video: 'Rotar Video',
    loop_video: 'Video en Bucle',
    joinmarge_video: 'Añadir Unir/Combinar Video',
    cut_video: 'Cortar Video',
    crop_video: 'Recortar Video',
    translate_video: 'Traducir Video',
    transcripbe_video: 'Transcribir Video',
    auto_subtitles: 'Subtítulos Automáticos',
    add_subtitle_video: 'Añadir Subtítulo al Video',
    compress_video: 'Comprimir Video',
    convert_video: 'Convertir Video',
    project_not_accessible: 'Proyecto no accesible',
    leave: 'Salir',
    cancel: 'Cancelar',
    okay: 'Ok',
    stop: 'Detener',
    record: 'Grabar',
    ongoing_upload_project_str: 'Hay una subida en curso a este proyecto.',
    leaving_page_sure: 'Estás saliendo de esta página, ¿estás seguro?',
    uploading_files: 'Subiendo Archivos',
    record_screen_and_upload: 'Graba tu pantalla y sube.',
    show_defaul_sharingMenu: 'Mostrar elementos de menú de compartir predeterminados',
    prefer_share_browser: 'Prefiere compartir una pestaña del navegador',
    prefer_share_window: 'Prefiere compartir una ventana',
    prefer_share_entireScreen: 'Prefiere compartir la pantalla entera',
    record_webcam_and_upload: 'Graba tu cámara web y sube.',
    sign_signup_to_uploadVideos: 'Por favor, inicia sesión o regístrate para subir videos',
    sizelimir_or_timelimit: 'más grande que 250 Mbytes o más largo que 5 minutos',
    screen_capture_preferences: 'Preferencias de Captura de Pantalla',
    is_uploading: 'Está Subiendo',
    monthly: 'Mensual',
    annually: 'Anualmente',
    most_popular: 'Más Popular',
    export: 'Exportar',
    bitrate: 'Tasa de Bits',
    frame_rate_fps: 'Tasa de Cuadros (FPS)',
    produce: 'Producir',
    setup: 'Configuración',
    template: 'Plantilla',
    templates: 'Plantillas',
    media: 'Medios',
    stock: 'Stock',
    translations: 'Traducciones',
    subtitles: 'Subtítulos',
    text_to_speech: 'Texto a Voz',
    transitions: 'Transiciones',
    text: 'Texto',
    emojis: 'Emojis',
    filters: 'Filtros',
    draw: 'Dibujar',
    edit_subtitle: 'Editar Subtítulo',
    add_new_subtitle: 'añadir nuevo subtítulo',
    select_brush_stock_start_draw: 'Selecciona un trazo de pincel para empezar a dibujar',
    brush_stroke: 'Trazo de Pincel',
    round: 'Redondo',
    sliced: 'Cortado',
    color: 'Color',
    opacity: 'Opacidad',
    size: 'Tamaño',
    duration: 'Duración',
    add_new_line: 'Añadir Nueva Línea',
    all_types: 'Todos los Tipos',
    load_more: 'Cargar Más',
    upload_video: 'Subir Video',
    upload_audio: 'Subir Audio',
    upload_image: 'Subir Imagen',
    create_webcam_recording: 'Crear Grabación de Webcam',
    create_screen_capture_recording: 'Crear Grabación de Captura de Pantalla',
    create_webcam_screen_recording: 'Crear Grabación de Webcam & Pantalla',
    project_asset: 'Activo del Proyecto',
    you_want_add_translations: '¿Cómo quieres añadir traducciones?',
    uploader: 'Subidor',
    auto: 'AUTO',
    auto_translations: 'Traducciones Automáticas',
    automatically_add_transllations_video: 'Añadir traducciones automáticamente al video',
    wait_moments_subtitles_being: 'Espera unos momentos mientras el subtítulo se está preparando',
    select_language_detect: 'Selecciona el idioma a detectar',
    disable_translate: 'Desactivar Traducción',
    select_language_select: 'Selecciona el Idioma para traducir',
    transition_duration: 'Duración de la Transición',
    coming_soon: 'Próximamente',
    language: 'Idioma',
    speaking_rate_defaul: 'Tasa de Habla (por defecto 1)',
    pitch_defaul: 'Tono (por defecto 0)',
    volume_gain_default: 'Ganancia de Volumen (por defecto 0)',
    create_text_speech: 'Crear Texto a Voz',
    add_text: 'Añadir Texto',
    text: 'Texto',
    start: 'Inicio',
    end: 'Fin',
    text_style: 'Estilo de Texto',
    background_color: 'Color de Fondo',
    add_new_text_field: 'Añadir Nuevo Campo de Texto',
    how_add_subtitles: '¿Cómo quieres añadir subtítulos?',
    automatically_subtitles_video: 'Añadir subtítulos automáticamente al video',
    text_color: 'Color del Texto',
    preview_text: 'Previsualizar Texto',
    search: 'Buscar',
    orientation: 'Orientación',
    next: 'Siguiente',
    previous: 'Anterior',
    locale: 'Localidad',
    landscape: 'Paisaje',
    portrait: 'Retrato',
    square: 'Cuadrado',
    time: 'Tiempo',
    background: 'Fondo',
    project_setting: 'Configuración del Proyecto',
    use_template_project: 'Usar como proyecto de plantilla',
    visibility: 'Visibilidad',
    category: 'Categoría',
    capture_thumbnail: 'Capturar Miniatura',
    brand_kit: 'Kit de Marca',
    video_placeholder: 'VideoPlaceholder2.mp4',
    replace_video: 'Reemplazar Video',
    transformations: 'Transformaciones',
    fill_canvas: 'Llenar el lienzo',
    fit_canvas: 'Ajustar al lienzo',
    round_corners: 'Esquinas Redondeadas',
    audio: 'Audio',
    detach_audio: 'Separar Audio',
    cleanAudio: 'AudioLimpio',
    upgrade_basic_unlock: 'Actualiza a BÁSICO para desbloquear',
    remove_background_noise_enhance_audio_qty: 'Eliminar ruido de fondo y mejorar calidad de audio',
    color_correction: 'Corrección de Color',
    reset: 'Restablecer',
    exposure: 'Exposición',
    brightness: 'Brillo',
    contract: 'Contraste',
    saturation: 'Saturación',
    sharpen: 'Afilado',
    blur: 'Desenfoque',
    noise: 'Ruido',
    trending: 'TENDENCIAS',
    custom: 'PERSONALIZADO',
    portrait: 'RETRATO',
    landscape: 'PAISAJE',
    settings: 'Configuraciones',
    magic_draw: 'Dibujo Mágico',
    tools: 'Herramientas',
    brush_size: 'Tamaño del Pincel',
    save: 'Guardar',
    clear: 'Limpiar',
    view_all: 'Ver Todo',
    save_your_project_later: 'Guarda tu proyecto para más tarde',
    sign_in: 'iniciar sesión',
    sign_up: 'registrarse',
    sign_up_home: 'Registrarse',
    redo: 'REHACER',
    undo: 'DESHACER',
    collaborate: 'COLABORAR',
    enter_email_share_project: 'Introduce el correo para compartir el proyecto',
    share_project: 'Compartir Proyecto',
    create_new_workspace: 'Crear nuevo Espacio de Trabajo',
    audio_noise_remove: 'Eliminación de Ruido de Audio',
    media_upload: 'Subida de Medios',
    draw_color: 'Color de Dibujo',
    tsl_none: 'ninguno',
    tsl_fade: 'Desvanecer',
    tsl_book_flip: 'Volteo de Libro',
    tsl_disolve: 'Disolver',
    tsl_doorway: 'Puerta',
    tsl_blur: 'Desenfocar',
    tsl_push: 'Empujar',
    tsl_slide: 'Deslizar',
    tsl_wipe: 'Limpiar',
    tsl_zoom_in: 'Acercar',
    tsl_zoom_out: 'Alejar',
    flt_None: 'Ninguno',
    flt_Darker: 'Más Oscuro',
    flt_BlacknWhite: 'Blanco y Negro',
    flt_Daphne: 'Dafne',
    flt_Penelope: 'Penélope',
    flt_Phoebe: 'Febe',
    flt_Thalia: 'Talía',
    flt_Chloe: 'Cloé',
    flt_Hermione: 'Hermione',
    flt_Hera: 'Hera',
    flt_Rhea: 'Rea',
    flt_Donna: 'Donna',
    flt_Martina: 'Martina',
    flt_Gabriella: 'Gabriella',
    flt_Elisabetta: 'Elisabetta',
    flt_Isabella: 'Isabella',
    flt_Luna: 'Luna',
    flt_Viola: 'Viola',
    flt_Zita: 'Zita',
    flt_Donata: 'Donata',
    flt_Maddalena: 'Maddalena',
    flt_Tecla: 'Tecla',
    flt_Narcisa: 'Narcisa',
    emj_Smileys: 'Sonrisas',
    emj_Animals: 'Animales',
    emj_People: 'Gente',
    emj_Jobs: 'Trabajos',
    emj_Sports: 'Deportes',
    emj_Flags: 'Banderas',
    emj_Signs: 'Señales',
    emj_Nature: 'Naturaleza',
    emj_Travel: 'Viajes',
    emj_Letters: 'Letras',
    emj_Misc: 'Varios',
    create_empty_project_templates: 'Crear un Proyecto Vacío Desde Plantillas',
    days_ago: 'días atrás',
    create_workspace: 'Crear Espacio de Trabajo',
    workspace: 'Espacios de Trabajo',
    delete_workspace: 'Eliminar Espacio de Trabajo',
    transfer_owner: 'Transferir Propietario',
    can_create_new_workspace: '¿Podrías crear un nuevo espacio de trabajo?',
    could_delete_workspace: '¿Podrías eliminar el espacio de trabajo?',
    available_subtitles_time: 'Tiempo de Subtítulos Disponibles',
    available_storage_space: 'Espacio de Almacenamiento Disponible',
    available_subtitle_lines: 'Líneas de Subtítulos Disponibles',
    other_workspaces: 'Otros espacios de trabajo',
    members: 'Miembros',
    add_member: 'Añadir Miembro',
    remove_member: 'Eliminar Miembro',
    change_member_level: 'Cambiar Nivel de Miembro',
    owner: 'Propietario',
    admin: 'Admin',
    member: 'Miembro',
    read_only: 'Solo Lectura',
    duplicate: 'Duplicar',
    splite: 'Dividir',
    remove: 'eliminar',
    layer: 'Capa',
    volume: 'Volumen',
    disable: 'Desactivar',
    deleterow: 'EliminarFila',
    fullscreen: 'Pantalla Completa',
    editsubtitle: 'EditarSubtítulo',
    fit: 'Ajustar',
    green_screen: 'Pantalla Verde',
    profile: 'Perfil',
    pricing: 'Precios',
    login: 'Iniciar Sesión',
    logout: 'Cerrar Sesión',
    get_started_free: "Comenzar - es gratis",
    dashboard: 'Tablero',
    projects: 'Proyectos',
    menu_Features: 'Características',
    menu_Tools: 'Herramientas',
    menu_VideoEditor: 'Editor de Video',
    menu_AIVideoEditor: 'Editor de Video Basado en IA',
    menu_AddImgToVideo: 'Añadir Imagen a Video',
    menu_AddMusicToVideo: 'Añadir Música a Video',
    menu_AddTextToVideo: 'Añadir Texto a Video',
    menu_FilterVideo: 'Filtrar Video',
    menu_AddTransitionstoVideo: 'Añadir Transiciones a Video',
    menu_AddFreeStockVideos:'Añadir Videos de Stock Gratis',
    menu_SubtitlesTranscription: 'Subtítulos y Transcripción',
    menu_AddSubtitlesToVideo: 'Añadir Subtítulos a Video',
    menu_AudioToText: 'Audio a Texto',
    menu_AutoSubtitles: 'Subtítulos Automáticos',
    menu_TranscribeVideo: 'Transcribir Video',
    menu_TranslateVideo: 'Traducir Video',
    menu_Toolkit: 'Kit de Herramientas',
    menu_Crop: 'Recortar',
    menu_Cut: 'Cortar',
    menu_JoinMerge: 'Unir/Combinar',
    menu_LoopVideo: 'Video en Bucle',
    menu_Resize: 'Redimensionar',
    menu_Rotate: 'Rotar',
    menu_More: 'Más',
    menu_VideoCompressor: 'Compresor de Video',
    menu_VideoConverter: 'Conversor de Video',
    menu_AIGenerated: 'Generado por IA',
    menu_AIGeneratedImages: 'Imágenes generadas por IA',
    menu_AIGeneratedVideos: 'Vídeos generados por IA',
    menu_AIGeneratedVoices: 'Voces generadas por IA',
    menu_Blog: 'Blog',
    menu_VIDEOOBlog: 'Blog de VIDEOO',
    menu_VIDEOOHowTo: 'Cómo hacerlo con VIDEOO',
    menu_VIDEOOCareers: 'Carreras en VIDEOO',
    online_ai_video_editor: 'Editor de Video Basado en IA en Línea',
    online_could_video_editor: 'Editor de Video Basado en la Nube en Línea',
    home_free_trial_detail: 'Prueba gratuita de 7 días, cancela en cualquier momento',
    home_create_button_detail: 'Creación de video con un clic con subtítulos automáticos, traducción y más',
    create_now: 'Crear Ahora',
    home_templates_start: 'Explora una gama de plantillas para comenzar',
    try_now_required: 'Pruébalo ahora, no se requiere cuenta',
    online_collaborative_video_editing: 'Edición de Video Colaborativa en Línea',
    online_collaborative_video_editing_detail: 'Sumérgete en el mundo de la Edición de Video en la Nube en Línea con videoo.io. Nuestra plataforma revoluciona la forma en que editas, ofreciendo capacidades de Edición de Video Colaborativa en Línea y un Espacio de Trabajo de Edición de Video Basado en la Nube.',
    auto_subtitles_translations: 'Subtítulos Automáticos y Traducciones',
    auto_subtitles_translations_detail: "Mejora tus videos con nuestra función de Subtítulos Automáticos y Traducciones, parte de nuestro Software de Edición de Video Profesional en Línea. Diseñado para ser una Herramienta de Software de Edición de Video para Todos, videoo.io hace que sea simple crear contenido accesible y atractivo.",
    ai_base_text_speech: 'Texto a Voz Basado en IA',
    ai_base_text_speech_detail: 'Nuestras Voces de Texto a Voz Basadas en IA son perfectas para creadores de contenido, encarnando el concepto de Edición de Video Reinventada para Youtubers e Influencers. Con videoo.io, transforma tus guiones en locuciones atractivas, añadiendo un toque profesional a tus videos y agilizando tu proceso de producción.',
    free_user_stock_video: 'Videos de Stock Gratis para Usar',
    free_user_stock_video_detail: 'Accede a una vasta colección de Videos de Stock Gratis para Usar con videoo.io, complementando nuestras capacidades de Edición de Video 4K en Línea. Nuestra plataforma, siendo un Software de Edición de Video 4K para Todos, asegura que tus proyectos se destaquen con metraje de alta calidad, haciendo que la edición de grado profesional sea accesible para todos los usuarios.',
    share: 'Compartir',
    unlisted_video: 'Video No Listado',
    your_video_about_ready: 'Tu video está a punto de estar listo',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
    want_free_stuff: '¿Quieres cosas gratis?',
    record_screen_webcam_both: 'Graba tu pantalla, webcam, o ambos',
    add_subtitle_your_video: 'Añade subtítulos a tus videos',
    create_your_team_workspace: 'Crea tu Espacio de Trabajo en Equipo',
    collaborate_videos: 'Colabora en videos',
    generate_transcript: 'Genera una transcripción',
    singup_get_all_this_more: 'Regístrate (gratis) para obtener todo esto y más!',
    downloading: 'Descargando',
    download: 'Descargar',
    reedit_video: 'Reeditar video',
    remove_watermark: 'Eliminar marca de agua',
    already_have_account: '¿Ya tienes una cuenta?',
    link_copied: 'Enlace Copiado',
    invitation_list: 'Lista de Invitaciones',
    remove_invitation: 'Eliminar Invitación',
    change_workspace: ' Cambiar espacio de trabajo',
    you_login_manage_your_profile: 'Necesitas iniciar sesión para gestionar tu perfil',
    you_dont_have_content_here: "No tienes ningún contenido aquí.",
    name: 'Nombre',
    status: 'Estado',
    data_modified: 'Fecha Modificada',
    all_workspaces: 'Todos los Espacios de Trabajo',
    delete_project: 'Eliminar Proyecto',
    duplicate_project: 'Duplicar Proyecto',
    draft: 'Borrador',
    edit: 'Editar',
    surname: 'Apellido',
    email: 'Correo Electrónico',
    plan: 'Plan',
    market_emails: 'Correos Electrónicos de Marketing',
    editor_notification: 'Notificaciones del Editor',
    storage: 'Almacenamiento',
    subtitle: 'Subtítulo',
    delete_account: 'Eliminar Cuenta',
    this_action_irreversibe: 'Esta acción es irreversible',
    current_plan: 'PLAN ACTUAL',
    cancel_subscription: 'Cancelar Suscripción',
    payment_method: 'MÉTODOS DE PAGO',
    expires: 'Expira',
    make_default: 'Hacer Predeterminado',
    delete: 'Eliminar',
    add_payment_method: 'Añadir método de pago',
    billing_information: 'INFORMACIÓN DE FACTURACIÓN',
    invoice_history: 'HISTORIAL DE FACTURAS',
    payment: 'Pago',
    preferences: 'Preferencias',
    save_up_to: 'Ahorra Hasta ',
    your_plan: 'Tu Plan',
    inactive: 'Inactivo',
    contact_sales: 'Contactar Ventas',
    need_additional_features: '¿Necesitas características adicionales? Pregúntanos sobre nuestro plan Empresarial'
};

