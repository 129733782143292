import { Box } from '@mui/system';

import './aigenerated-voice.scss';
import UploadImg from './../../../assets/image/tools/upload-img.png';
import HeroImg from './../../../assets/image/tools/hero-img.png';
import facebookLogo from "./../../../assets/image/Logos/facebook_logo.png";
import instagramLogo from "./../../../assets/image/Logos/instagram_logo.png";
import tiktokLogo from "./../../../assets/image/Logos/tiktok_logo.png";
import twitterLogo from "./../../../assets/image/Logos/twitter_logo.png";
import youtubeLogo from "./../../../assets/image/Logos/youtube_logo.png";
import whatsappLogo from "./../../../assets/image/Logos/whatsapp_logo.png";
import { createNewProject } from '../uploadHelper';
import { Footer } from '../../../component/home-footer/footer';
import { Header } from '../../../component/home-header/header';

export const BASE_STATIC_STORAGE_URL = process.env.REACT_APP_STATIC_STORAGE_URL;
export const how_to_1   = BASE_STATIC_STORAGE_URL + '/images/features/Add Audio to Text.png';
export const how_to_2   = BASE_STATIC_STORAGE_URL + '/images/features/add_more_files.jpg';
export const how_to_3   = BASE_STATIC_STORAGE_URL + '/images/features/Export Audio to Text.png';

export const AIGeneratedVoice = () => {
    return (
        <Box className="T_containner">
      <Box className="Header"><Header /></Box>

      <Box className="content-containner">
      <>
            <Box className='section-15'>
                <Box className='div-1232 _1300'>
                    <Box className='breadcrumbs'>
                        <a href={'/'}>
                            <Box className='breadcrumb-text-block'>Videoo</Box>
                        </a>
                        <Box className='breadcrumb-arrow'></Box>
                        <a href={'/'}>
                            <Box className='breadcrumb-text-block'>AI Tools</Box>
                        </a>
                        <Box className='breadcrumb-arrow'></Box>
                        <Box className='breadcrumb-text-block'>AI Voice Generator</Box>
                    </Box>
                </Box>
            </Box>

            <Box className='section-9 directory std-hero-section'>
                <Box className='div-1232'>
                    <Box className='hero-grid'>
                        <Box className='hero-grid-text'>
                            <Box className='hero-header-title'>Online AI-Generated Text-to-Speech Voices</Box>
                            <Box className='hero-content-title'>Free online Text-To-Speech tool - AI Voices online powered by Voiser.net and Google Cloud</Box>

                            <Box onClick={() => {
                                createNewProject("feature-transcribe-video")
                            }} className='choose-btn'>
                                <Box className='choose-btn-text'>Go to Video Editor</Box>
                                <Box className='choose-btn-img'><img src={UploadImg} alt='uploadImg' /></Box>
                            </Box>
                        </Box>

                        <Box className='hero-img'>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/yUxd119hwFk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </Box>
                    </Box>

                    {/* <Box className="logos-grid">
                        <Box className="usedByLogos-item">
                            <img src={instagramLogo} alt="instagram logo" width={100} />
                        </Box>
                        <Box className="usedByLogos-item">
                            <img src={youtubeLogo} alt="youtube logo" width={100} />
                        </Box>
                        <Box className="usedByLogos-item">
                            <img src={facebookLogo} alt="facebook logo" width={100} />
                        </Box>
                        <Box className="usedByLogos-item">
                            <img src={twitterLogo} alt="twitter logo" width={100} />
                        </Box>
                        <Box className="usedByLogos-item">
                            <img src={tiktokLogo} alt="tiktok logo" width={100} />
                        </Box>
                        <Box className="usedByLogos-item">
                            <img src={whatsappLogo} alt="whatsapp logo" width={100} />
                        </Box>
                        {{usedLogs.map((logo, index) => (
                            <Box className="usedByLogos-item" key={index}>{logo}</Box>
                        ))} }
                    </Box> */}
                </Box>
            </Box>

            <Box className='wf-section'>
                <Box className='div-1232'>
                    <Box className='div-block-67'>
                        <h2 className="heading-19">Create Text to Speech with 250+ AI Voices from 50+ languages, Free!</h2>


                        <Box className='text-section-block'>
                            <ul className='text-section-ul'>
                                <li className='py-10'>
                                    Text-to-Speech technologies has evolved immensely in the recent years. With the rise of new AI models, it is now possible to generate human-alike voices which are generally quite difficult to distinguish from actual human voices.
                                </li>

                                <li className='py-10'>
                                    The range of AI voices can mimic human attributes, and even reflect emotions such as happiness, sadness, excitement, and even anger in a realistic manner. This helps all kind of content creators that are intending to produce studio recording quality video production while having limited or no access to professional studio recording equipment.
                                    The fees for professional studio recording gear and studio renting fees are incomparable to the cost of a subscription based AI Voice service. Regarding this fact, AI Voices are becoming more and more popular amongst social media video producers.
                                </li>

                                <li className='py-10'>
                                    Videoo.io has integrated Voiser.net and Google Cloud Text-to-Speech services to provide an easy to use interface that will allow the video editors to use enhanced AI voices for their videos. Voiser.net is an emerging AI Voice service provider company which has more than 50+ languages with about 250+ AI voices. Google Cloud Text-to-Speech(TTS) service is a well established and legacy API, however the number of voices are not in the range that Voiser.net supports. 
                                </li>

                                <li className='py-10'>
                                    So, if you want to have high quality, professional studio grade voice overs for your video, our AI voices are the best choice for you. You can use these AI voices for your video projects of any sort: educational, events, promotions, social media and many more.
                                </li>

                                <li className='py-10'>
                                    In order to use AI voices via Videoo.io, first go to Text-to-Speech menu which is located in left side the Video Editor UI. Then, enter the context that you would like to be spoken by the AI voice. Then, you can select the AI voice using the search bar. The selection can be based on the country, gender or the actual Voice name. As there are 250+ voices supported, using the search bar is wise choice in order to find the right choice for your context. 
                                    The supported languages are English, French, German, Mandarin, Spanish, Russian, Turkish and the rest of the major languages on earth. If you want to test out the voices, just press the play button next to the voice sign, and a preview of the AI voice will be hearable for you.
                                </li>

                                <li className='py-10'>
                                    When you are done with selection and you are sure about your context, you can create the TTS audio file by pressing "Create Text to Speech" button. After the audio file is created, it will be positioned in project timeline. You can now listen to the audio in your video editing project among other assets and media content that resides in your project.
                                    If you are satisfied with all the content and their placement in video editor timeline, then press Export. And Voila! You have a professional grade studio quality voice over for your project that is generated by AI-Voices from Voiser.net (or Google Cloud TTS).
                                </li>
                            </ul>
                        </Box>

                    </Box>
                </Box>
            </Box>

            <Box className='wf-section'>
                <Box className='div-1232 wide _3'>
                    <Box className='div-block-70'>
                        <Box className='div-block-69'>
                            <h2 className="heading-20">How to Add Text-To-Speech with AI voices using Videoo.io</h2>
                        </Box>

                        <Box className='div-block-68'>
                            <Box className='how-to-div-item'>
                                <img src={how_to_1} alt='howTo1' className="how-to-img" />
                                <h3 className="heading-21 m-y-0">Choose your media assets</h3>
                                <p className="how-to-content">Select your assets and upload it, either from computer or mobile device</p>
                            </Box>

                            <Box className='how-to-div-item'>
                                <img src={how_to_2} alt='howTo2' className="how-to-img" />
                                <h3 className="heading-21 m-y-0">Enter Script for AI voice </h3>
                                <p className="how-to-content">Go to Text-To-Speech menu, enter your context, select AI models from search bar including country, gender and voice name.</p>
                            </Box>

                            <Box className='how-to-div-item'>
                                <img src={how_to_3} alt='howTo3' className="how-to-img" />
                                <h3 className="heading-21 m-y-0">Export and Download</h3>
                                <p className="how-to-content">Just click export, and now you have human like AI voice over for your video or other assets !</p>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
      </Box>

      <Box className="footer-containner"><Footer /></Box>
    </Box >
       
    )
}
