import './videoocareer.scss'
import { Layouts } from '../../component/layouts/layouts'

export const Career5 = () => {
  return (
    <Layouts>
      <div className='flex flex-col items-center justify-center px-10 sm:px-20 md:px-30 lg:px-40'>
        <div className='w-full max-w-1000 flex flex-col gap-10 md:gap-20 lg:gap-30 py-30'>
          <div className='text-center text-20 md:text-30 lg:text-40 font-semibold'>
            Cloud Solution Architect
          </div>
          <div className='flex flex-col gap-10 mm:gap-20'>
            <div className='text-15 md:text-18'>
              We are seeking a skilled and experienced Cloud Solution Architect to join our team. As a Cloud Solution Architect, you will be responsible for designing and implementing scalable, reliable, and secure cloud solutions for our clients. You will work closely with cross-functional teams to understand business requirements and translate them into technical architectures that leverage cloud technologies.

              This is an exciting opportunity to work on cutting-edge cloud projects and collaborate with a talented team to deliver innovative solutions. You will play a key role in driving the adoption of cloud technologies and ensuring the successful implementation of cloud-based solutions.
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Responsibilities:</span>
              <div className='text-15 md:text-18'>
                - Collaborate with stakeholders to understand business requirements and define cloud solution architectures
              </div>
              <div className='text-15 md:text-18'>
                - Design and implement scalable, reliable, and secure cloud solutions using platforms such as AWS, Azure, or Google Cloud
              </div>
              <div className='text-15 md:text-18'>
                - Develop architecture blueprints, technical documentation, and implementation guidelines
              </div>
              <div className='text-15 md:text-18'>
                - Conduct feasibility studies and provide recommendations on cloud technologies, services, and tools
              </div>
              <div className='text-15 md:text-18'>
                - Collaborate with development and operations teams to ensure successful implementation and integration of cloud solutions
              </div>
              <div className='text-15 md:text-18'>
                - Perform cloud infrastructure assessments and provide optimization recommendations
              </div>
              <div className='text-15 md:text-18'>
                - Stay up-to-date with the latest cloud technologies, trends, and best practices
              </div>
              <div className='text-15 md:text-18'>
                - Assist with other architectural and technical tasks as needed
              </div>
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Qualifications:</span>
              <div className='text-15 md:text-18'>
                - Bachelor's degree in Computer Science, Engineering, or a related field
              </div>
              <div className='text-15 md:text-18'>
                - Strong experience in cloud solution architecture and design
              </div>
              <div className='text-15 md:text-18'>
                - In-depth knowledge of cloud platforms such as AWS, Azure, or Google Cloud
              </div>
              <div className='text-15 md:text-18'>
                - Proficiency in infrastructure-as-code tools such as Terraform or CloudFormation
              </div>
              <div className='text-15 md:text-18'>
                - Solid understanding of cloud security best practices and compliance requirements
              </div>
              <div className='text-15 md:text-18'>
                - Experience with containerization technologies such as Docker and Kubernetes
              </div>
              <div className='text-15 md:text-18'>
                - Familiarity with DevOps practices and tools for continuous integration and deployment
              </div>
              <div className='text-15 md:text-18'>
                - Excellent problem-solving and analytical skills
              </div>
              <div className='text-15 md:text-18'>
                - Strong communication and stakeholder management abilities
              </div>
            </div>

            <div className='text-15 md:text-18'>
              If you are a talented and experienced Cloud Solution Architect looking for a challenging role in designing and implementing cloud solutions, we would love to hear from you. Join our team and contribute to the success of our clients by delivering scalable and secure cloud architectures.

              To apply, please submit your resume to info@videoo.io. Please include "Cloud Solution Architect Application" in the subject line. Only shortlisted candidates will be contacted.

              We look forward to reviewing your application!
            </div>
          </div>
        </div>
      </div>
    </Layouts>
  )
}