import { Modal } from '@mui/material'

export const ModalCom = (props) => {
  const { openState, onClose, children } = props

  return (
    <Modal
      open={openState}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="default-modal-wrapper"
    >
      <div className="default-modal">
        {children}
      </div>
    </Modal>
  )
}