import './videoocareer.scss'
import { Layouts } from '../../component/layouts/layouts'

export const Career6 = () => {
  return (
    <Layouts>
      <div className='flex flex-col items-center justify-center px-10 sm:px-20 md:px-30 lg:px-40'>
        <div className='w-full max-w-1000 flex flex-col gap-10 md:gap-20 lg:gap-30 py-30'>
          <div className='text-center text-20 md:text-30 lg:text-40 font-semibold'>
            Software Engineer in Test
          </div>
          <div className='flex flex-col gap-10 mm:gap-20'>
            <div className='text-15 md:text-18'>
              We are seeking a highly skilled and motivated Software Engineer in Test to join our team. As a Software Engineer in Test, you will be responsible for ensuring the quality and reliability of our software products through the design and implementation of automated testing frameworks and test cases. You will work closely with the development team to identify and fix bugs, as well as contribute to the continuous improvement of our testing processes.

              This is an exciting opportunity to work on cutting-edge software projects and play a key role in delivering high-quality software solutions. You will have the chance to work with a talented team and utilize your technical skills to ensure the stability and performance of our software products.
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Responsibilities:</span>
              <div className='text-15 md:text-18'>
                - Design and develop automated testing frameworks and test cases
              </div>
              <div className='text-15 md:text-18'>
                - Collaborate with the development team to identify and fix software defects
              </div>
              <div className='text-15 md:text-18'>
                - Create and execute test plans, test cases, and test scripts
              </div>
              <div className='text-15 md:text-18'>
                - Perform functional, regression, and performance testing
              </div>
              <div className='text-15 md:text-18'>
                - Participate in code reviews to ensure testability and maintainability of the software
              </div>
              <div className='text-15 md:text-18'>
                - Contribute to the continuous improvement of the testing processes and tools
              </div>
              <div className='text-15 md:text-18'>
                - Collaborate with cross-functional teams to ensure the delivery of high-quality software products
              </div>
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Qualifications:</span>
              <div className='text-15 md:text-18'>
                - Bachelor's degree in Computer Science, Engineering, or a related field
              </div>
              <div className='text-15 md:text-18'>
                - Strong experience in software testing and test automation
              </div>
              <div className='text-15 md:text-18'>
                - Proficiency in programming languages such as Java, Python, or C++
              </div>
              <div className='text-15 md:text-18'>
                - Knowledge of testing frameworks and tools (e.g., Selenium, JUnit, TestNG)
              </div>
              <div className='text-15 md:text-18'>
                - Familiarity with Agile methodologies and continuous integration practices
              </div>
              <div className='text-15 md:text-18'>
                - Experience with version control systems (e.g., Git, SVN)
              </div>
              <div className='text-15 md:text-18'>
                - Strong problem-solving and analytical skills
              </div>
              <div className='text-15 md:text-18'>
                - Excellent communication and collaboration abilities
              </div>
            </div>

            <div className='text-15 md:text-18'>
              If you are a talented and detail-oriented Software Engineer in Test looking to work on challenging projects and contribute to the development of high-quality software, we would love to hear from you. Join our team and be part of an innovative and dynamic environment.

              To apply, please submit your resume to info@videoo.io. Please include "Software Engineer in Test Application" in the subject line. Only shortlisted candidates will be contacted.

              We look forward to reviewing your application!
            </div>
          </div>
        </div>
      </div>
    </Layouts>
  )
}