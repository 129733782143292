// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-section-block .text-section-ul {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.5px;
  font-family: "Euclidcirculara webfont", Arial, sans-serif;
}
.text-section-block .text-section-ul li {
  list-style-position: inside;
  list-style-type: circle;
}
.text-section-block .text-section-ul li span {
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/pages/AIGenerated/aigenerated-video/aigenerated-video.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;EACA,yDAAA;AAAJ;AAEI;EACE,2BAAA;EACA,uBAAA;AAAN;AAEM;EACE,gBAAA;AAAR","sourcesContent":[".text-section-block {\n  .text-section-ul {\n    font-size: 18px;\n    font-weight: 400;\n    line-height: 30px;\n    letter-spacing: 0.5px;\n    font-family: \"Euclidcirculara webfont\", Arial, sans-serif;\n\n    li {\n      list-style-position: inside;\n      list-style-type: circle;\n\n      span {\n        font-weight: 700;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
