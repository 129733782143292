import { createStore, applyMiddleware, compose } from "redux";
import createDebounce from "redux-debounced";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

const middlewares = [thunk, createDebounce()];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = (preloadedState) => createStore(
	rootReducer,
	preloadedState,
	composeEnhancers(applyMiddleware(...middlewares))
);
export default store;
