import React, { useCallback, useState } from "react";
import { NavLink, Link, useSearchParams } from 'react-router-dom';
import { Box } from "@mui/material";

import './signUp.scss';
import Logo from '../../assets/image/Logo.png';
import { GoogleIcon } from "../../Icons";
import { useLocation } from 'react-router-dom';

import { BASE_BACKEND_URL, EmailSendEmailMagicLinkForSignUp } from "../../axios/ApiProvider"

function gtag_report_conversion(url) {
  var callback = function () {
    if (typeof(url) != 'undefined') {
      window.location = url;
    }
  };
  window.gtag('event', 'conversion', {
      'send_to': 'AW-11458714849/ngVxCJDNmIMZEOG5-Ncq',
      'value': 1.0,
      'currency': 'USD',
      'transaction_id': '',
      'event_callback': callback
  });
  return false;
}

export const SignUp = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectUUID = queryParams.get('project_uuid');

  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");

  const [accountInfo, setAccountInfo] = useState({
    email: email || "",
    name: "",
    surname: ""
  })

  const GoogleSignUpBtn = useCallback(() => {
    const REACT_APP_BASE_BACKEND_URL = BASE_BACKEND_URL;
    const REACT_APP_GOOGLE_CLIENT_ID = "1034580383158-i286oqc9psc2c11utuhr86c4b5mdcof0.apps.googleusercontent.com";
    const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
    const redirectUri = 'api/v1/oauth/google';

    const scope = [
      'https://www.googleapis.com/auth/userinfo.email',
      'https://www.googleapis.com/auth/userinfo.profile'
    ].join(' ');
    const params = {
      response_type: 'code',
      client_id: REACT_APP_GOOGLE_CLIENT_ID,
      redirect_uri: `${REACT_APP_BASE_BACKEND_URL}/${redirectUri}`,
      prompt: 'select_account',
      access_type: 'offline',
      scope
    };

    gtag_report_conversion();

    const urlParams = new URLSearchParams(params).toString();
    window.location = `${googleAuthUrl}?${urlParams}`;
  }, []);

  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const EmailSignUpBtn = async (form_data, file) => {
    let username = accountInfo.email || "";
    let name = accountInfo.name || ""; 
    let surname = accountInfo.surname || "";
    const alphanumeric = /^[\p{L}\p{N}]*$/u;
    let issuccess = false;

    if (!validateEmail(username)) {
      window.toastr.error("Email is not acceptable");
      return issuccess;
    }

    if (!name || !name.match(alphanumeric)) {
      window.toastr.error("Check your name, it should be alphanumeric");
      return issuccess;
    }

    if (!surname || !surname.match(alphanumeric)) {
      window.toastr.error("Check your surname, it should be alphanumeric");
      issuccess = false;
      return issuccess;
    }

    let result = await EmailSendEmailMagicLinkForSignUp(projectUUID);

    if (!result || result.state === 'error') {
      window.toastr.error(result.message);
      return issuccess;
    }

    window.toastr.success(result.data.message);
    issuccess = true;

    gtag_report_conversion();
    return issuccess
  }

  const handleInputChange = (e) => {
    setAccountInfo({
      ...accountInfo,
      [e.target.name]: e.target.value
    })
  }

  return (
    <Box className="signUp-containner">
      <Box className="signup-left">
        <Box className='signup-left-containner'>
          <Box className='signup-left-content'>
            <Box className='signup-content-header'>
              <Link to={'..'}>
                <img src={Logo} alt='logo' />
              </Link>

              <Box className='signup-header-content'>
                Create your account
              </Box>

              <Box className='signup-header-footer'>
                Create videos with a single click
              </Box>
            </Box>

            <Box className='signup-content-body'>

            {projectUUID ? null : (
              <Box className='googleBtn' onClick={GoogleSignUpBtn}>
                <GoogleIcon /> Continue with Google
              </Box>
            )}
            {projectUUID ? (
              <Box className='signup-content-or'></Box>
            ) : (
              <Box className='signup-content-or'>OR</Box>
            )}

              <Box className='signup-content-input'>
                <input placeholder="Enter your email address here" data-testid="@email-input" type="email" name="email" className='email-input' id='email_input' onChange={handleInputChange} value={accountInfo.email} />
                <label htmlFor='email' className='email-input-label'>
                  Email Address
                </label>
              </Box>

              <Box className='signup-content-input'>
                <input placeholder="Enter your name here" data-testid="@name-input" type="name" name="name" className='name-input' id='name_input' onChange={handleInputChange} value={accountInfo.name}/>
                <label htmlFor='name' className='name-input-label'>
                  Name
                </label>
              </Box>

              <Box className='signup-content-input'>
                <input placeholder="Enter your surname here" data-testid="@surname-input" type="surname" name="surname" className='surname-input' id='surname_input' onChange={handleInputChange} value={accountInfo.surname} />
                <label htmlFor='surname' className='surname-input-label'>
                  Surname
                </label>
              </Box>

              <Box className='signup-Btn' onClick={EmailSignUpBtn}>
                Get Magic Link
              </Box>
              <p className='signup-btn-footer-text'>We'll email you a magic link to sign in.</p>
            </Box>
          </Box>

          <Box className='signup-left-footer'>
            <Box className='signup-content-footer'>
              <Box className='signup-content-footer-text'>
                By proceeding, you agree to our
                <Box>
                  <Link to=''>Terms</Link> and <Link to=''>Privacy Policy</Link>
                </Box>
              </Box>

              <Box className='signup-content-footer-footer'>
                Already have an account? <NavLink to={`/signin/?project_uuid=${projectUUID || ''}`}>Sign in</NavLink>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className="signup-right"></Box>
    </Box>
  )
}