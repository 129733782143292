export const Menu_options = [
	{
		header: 'Video Editor',
		langKey: 'menu_VideoEditor',
		link: '../../video-editor',
		items: [
			{
				title: 'Add Image To Video',
				langKey: 'menu_AddImgToVideo',
				link: '../../add-image-to-video',
				child: []
			}, {
				title: 'Add Music To Video',
				langKey: 'menu_AddMusicToVideo',
				link: '../../add-music-to-video',
				child: []
			}, {
				title: 'Add Text To Video',
				langKey: 'menu_AddTextToVideo',
				link: '../../add-text-to-video',
				child: []
			}, {
				title: 'Filter Video',
				langKey: 'menu_FilterVideo',
				link: '../../video-filters',
				child: []
			}, {
				title: 'Add Transitions to Video',
				langKey: 'menu_AddTransitionstoVideo',
				link: '../../add-transition-to-video',
				child: []
			}, {
				title: 'Add Free Stock Videos',
				langKey: 'menu_AddFreeStockVideos',
				link: '../../add-free-stock-to-video',
				child: []
			},{
				title: 'Add Subtitles to Video',
				langKey: 'menu_AddSubtitlesToVideo',
				link: '../../add-subtitles-to-video',
				child: []
			}]
	}, 
	{
		header: 'Tools',
		langKey: 'menu_Tools',
		link: '../../tools',
		items: [
		{
			title: 'Video Converter',
			langKey: 'menu_Convert',
			link: '../../video-converter',
		}, {
			title: 'Video Compressor',
			langKey: 'menu_Compressor',
			link: '../../video-compressor'
		}, {
			title: 'Video Resizer',
			langKey: 'menu_Resize',
			link: '../../video-resizer'
		}, {
			title: 'Crop Video',
			langKey: 'menu_Crop',
			link: '../../crop-video'
		}, {
			title: 'Cut Video',
			langKey: 'menu_Cut',
			link: '../../video-trimmer'
		}, {
			title: 'Loop Video',
			langKey: 'menu_LoopVideo',
			link: '../../loop-video'
		}, {
			title: 'Rotate Video',
			langKey: 'menu_Rotate',
			link: '../../rotate-video'
		}, {
			title: 'Transcribe Video',
			langKey: 'menu_TranscribeVideo',
			link: '../../transcribe-video-to-text'
		}, {
			title: 'Translate Video',
			langKey: 'menu_TranslateVideo',
			link: '../../video-translator'
		}, {
			title: 'Merge Videos',
			langKey: 'menu_JoinMerge',
			link: '../../merge-videos'
		}, {
			title: 'Audio To Text',
			langKey: 'menu_AudioToText',
			link: '../../audio-to-text'
		}, {
			title: 'Subtitle Generator',
			langKey: 'menu_AutoSubtitles',
			link: '../../subtitle-generator'
		}]
	},
	{
		header: 'AI Generated Tools',
		langKey: 'menu_AIGenerated',
		link: '../../ai-tools',
		items: [
			{
				title: 'AI Video Summarizer',
				langKey: 'menu_AIVideoSummary',
				link: '../../ai-summary-generator'
			},
			{
				title: 'AI Generated Images',
				langKey: 'menu_AIGeneratedImages',
				link: '../../ai-image-generator'
			}, {
				title: 'AI Generated Videos',
				langKey: 'menu_AIGeneratedVideos',
				link: '../../ai-video-generator'
			},  {
				title: 'AI Generated Voices',
				langKey: 'menu_AIGeneratedVoices',
				link: '../../ai-voice-generator'
			},
		]
	},  
	
	// {
	// 	title: 'Features',
	// 	langKey: 'menu_Features',
	// 	items: [
			
			
			
	// 		{
	// 			header: 'Resources',
	// 			langKey: 'menu_Resources',
	// 			items: [
	// 				{
	// 					header: 'Video Editing Tips',
	// 					langKey: 'menu_VideoEditTip',
	// 					child: [
	// 						{
	// 							title: 'How to merge videos',
	// 							langKey: 'menu_HowToMerge',
	// 							link: '/'
	// 						},
	// 						{
	// 							title: 'How to add subtitles',
	// 							langKey: 'menu_HowToMerge',
	// 							link: '/'
	// 						},
	// 						{
	// 							title: 'How to add image to video',
	// 							langKey: 'menu_HowToMerge',
	// 							link: '/'
	// 						},
	// 						{
	// 							title: 'How to edit loop videos',
	// 							langKey: 'menu_HowToMerge',
	// 							link: '/'
	// 						},
	// 						{
	// 							title: 'How to create short video',
	// 							langKey: 'menu_HowToMerge',
	// 							link: '/'
	// 						},
	// 						{
	// 							title: 'How to find AI generated images',
	// 							langKey: 'menu_HowToMerge',
	// 							link: '/'
	// 						},
	// 						{
	// 							title: 'How to find copyright-free music',
	// 							langKey: 'menu_HowToMerge',
	// 							link: '/'
	// 						}
	// 					]
	// 				}, {
	// 					header: 'Social Media Tips',
	// 					langKey: 'menu_SocialMedia',
	// 					child: [
	// 						{
	// 							title: 'How to make a YouTube video',
	// 							langKey: 'menu_howto'
	// 						}
	// 					]
	// 				}
		
	// 			]
	// 		},
			
	// 		{
	// 			header: 'Subtitles & Transcription',
	// 			langKey: 'menu_SubtitlesTranscription',
	// 			child: [
	// 				{
	// 					title: 'Add Subtitles To Video',
	// 					langKey: 'menu_AddSubtitlesToVideo',
	// 					link: '../../subtitlestranscription/add-subtitles'
	// 				}, {
	// 					title: 'Audio To Text',
	// 					langKey: 'menu_AudioToText',
	// 					link: '../../subtitlestranscription/audio-to-text'
	// 				}, {
	// 					title: 'Auto Subtitles',
	// 					langKey: 'menu_AutoSubtitles',
	// 					link: '../../subtitlestranscription/auto-subtitles'
	// 				}, {
	// 					title: 'Transcribe Video',
	// 					langKey: 'menu_TranscribeVideo',
	// 					link: '../../subtitlestranscription/transcribe-video'
	// 				}, {
	// 					title: 'Translate Video',
	// 					langKey: 'menu_TranslateVideo',
	// 					link: '../../subtitlestranscription/translate-video'
	// 				},]
	// 		}, 
	// 		{
	// 			header: 'Toolkit',
	// 			langKey: 'menu_Toolkit',
	// 			child: [
	// 				{
	// 					title: 'Crop',
	// 					langKey: 'menu_Crop',
	// 					link: '../../tools/crop-video'
	// 				}, {
	// 					title: 'Cut',
	// 					langKey: 'menu_Cut',
	// 					link: '../../tools/cut-video'
	// 				}, {
	// 					title: 'Join/Merge',
	// 					langKey: 'menu_JoinMerge',
	// 					link: '../../tools/joinmerge-video'
	// 				}, {
	// 					title: 'Loop Video',
	// 					langKey: 'menu_LoopVideo',
	// 					link: '../../tools/loop-video'
	// 				}, {
	// 					title: 'Resize',
	// 					langKey: 'menu_Resize',
	// 					link: '../../tools/resize-video'
	// 				}, {
	// 					title: 'Rotate',
	// 					langKey: 'menu_Rotate',
	// 					link: '../../tools/rotate-video'
	// 				},
	// 				// {
	// 				// 	title: 'Convert',
	// 				// 	link: '../../tools/convert-video'
	// 				// },
	// 				// {
	// 				// 	title: 'Compress',
	// 				// 	link: '../../tools/compress-video'
	// 				// }
	// 			]
	// 		},
	// 		// {
	// 		// 	header: 'Record',
	// 		// 	child: [
	// 		// 		{
	// 		// 			title: 'Screen Recorder',
	// 		// 			link: ''
	// 		// 		}, {
	// 		// 			title: 'Telprompter',
	// 		// 			link: ''
	// 		// 		}, {
	// 		// 			title: 'Voice Recorder',
	// 		// 			link: ''
	// 		// 		}, {
	// 		// 			title: 'Webcam Recorder',
	// 		// 			link: ''
	// 		// 		},]
	// 		// }, 
	// 		{
	// 			header: 'More',
	// 			langKey: 'menu_More',
	// 			child: [
	// 		// 		{
	// 		// 			title: 'GIF Editor',
	// 		// 			link: ''
	// 		// 		}, 
	// 				{
	// 					title: 'Video Compressor',
	// 					langKey: 'menu_VideoCompressor',
	// 					link: '../../more/compress-video'
	// 				}, {
	// 					title: 'Video Converter',
	// 					langKey: 'menu_VideoConverter',
	// 					link: '../../more/convert-video'
	// 				},  {
	// 					title: 'Video Transcribe',
	// 					langKey: 'menu_TranscribeVideo',
	// 					link: '../../more/transcribe-video'
	// 				},
	// 		// {
	// 		// 			title: 'YouTube Cutter',
	// 		// 			link: ''
	// 		// 		}, {
	// 		// 			title: 'TyouTube To GIF',
	// 		// 			link: ''
	// 		// 		}, {
	// 		// 			title: 'send Video',
	// 		// 			link: ''
	// 		// 		}, {
	// 		// 			title: 'Webcam Test',
	// 		// 			link: ''
	// 		// 		},
	// 			]
	// 		},
			
	// 	]
	// },
	// {
	// 	title: 'Produce',
	// 	items: [
	// 		{
	// 			header: 'Marketing',
	// 			child: [
	// 				{
	// 					title: 'Intro Maker',
	// 					link: ''
	// 				}, {
	// 					title: 'Outro Maker',
	// 					link: ''
	// 				}, {
	// 					title: 'Video Maker',
	// 					link: ''
	// 				}, {
	// 					title: 'Meme Maker',
	// 					link: ''
	// 				}, {
	// 					title: 'Promo Videos',
	// 					link: ''
	// 				}, {
	// 					title: 'Video Ad Maker',
	// 					link: ''
	// 				},]
	// 		}
	// 	]
	// }, 
	// {
	// 	header: 'Blog',
	// 	langKey: 'menu_Blog',
	// 	link: '/',
	// 	items: [
	// 		{
	// 			title: 'VIDEOO Blog',
	// 			langKey: 'menu_VIDEOOBlog',
	// 			link: '/videooblog'
	// 		}, {
	// 			title: 'VIDEOO How to',
	// 			langKey: 'menu_VIDEOOHowTo',
	// 			link: '/videoohowto'
	// 		}, {
	// 			title: 'VIDEOO Careers',
	// 			langKey: 'menu_VIDEOOCareers',
	// 			link: '/videoocareers'
	// 		}
	// 	]
	// }
];

export const Pricing_options = [
	{ title: "Pricing", link: "pricing" },
	{ title: "Login", link: "signin" },
	{ title: "Signup", link: "signup" }
];