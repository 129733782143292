// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p {
  margin-top: 16px;
  margin-bottom: 32px;
  font-family: "Euclidcirculara webfont", Arial, sans-serif;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: 0.5px;
}
p ul {
  list-style-type: decimal;
  padding-left: 35px;
}`, "",{"version":3,"sources":["webpack://./src/pages/SubtitlesTranscription/auto-subtitles/auto-subtitles.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,mBAAA;EACA,yDAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,qBAAA;AACJ;AACI;EACI,wBAAA;EACA,kBAAA;AACR","sourcesContent":["p{\n    margin-top: 16px;\n    margin-bottom: 32px;\n    font-family: \"Euclidcirculara webfont\", Arial, sans-serif;\n    font-size: 20px;\n    line-height: 30px;\n    font-weight: 400;\n    letter-spacing: 0.5px;\n\n    ul{\n        list-style-type: decimal;\n        padding-left: 35px;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
