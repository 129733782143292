import { Box, Button, Card, CardMedia, Grid, Typography } from "@mui/material";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Link } from "react-router-dom";

import './add-music-to-video.scss';
import UploadImg from './../../../assets/image/tools/upload-img.png';
import facebookLogo from "./../../../assets/image/Logos/facebook_logo.png";
import instagramLogo from "./../../../assets/image/Logos/instagram_logo.png";
import tiktokLogo from "./../../../assets/image/Logos/tiktok_logo.png";
import twitterLogo from "./../../../assets/image/Logos/twitter_logo.png";
import youtubeLogo from "./../../../assets/image/Logos/youtube_logo.png";
import whatsappLogo from "./../../../assets/image/Logos/whatsapp_logo.png";
import { createNewProject } from './../VideoEditorHelper';
import { Header } from '../../../component/home-header/header';
import { Footer } from '../../../component/home-footer/footer';

import { GetProjectLink } from "../../../axios/ApiProvider";

export const EDIT_FRONTEND_URL = process.env.REACT_APP_BASE_FRONTEND_EDITOR_URL
export const BASE_STATIC_STORAGE_URL = process.env.REACT_APP_STATIC_STORAGE_URL;

export const how_to_1 = BASE_STATIC_STORAGE_URL + '/images/features/Add Your File.png';
export const how_to_2 = BASE_STATIC_STORAGE_URL + '/images/features/Join-Merge Video.png';
export const how_to_3 = BASE_STATIC_STORAGE_URL + '/images/features/Export Loop Video.png';

const VideooEditor_AddMusic_Welcome = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddMusic-Welcome.png'
const VideooEditor_AddMusic_Step1 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddMusic-1-MainPage.png'
const VideooEditor_AddMusic_Step2 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddMusic-2-AddStockMusic.png'
const VideooEditor_AddMusic_Step3 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddMusic-3-StartwithTemplateProject.png'
const VideooEditor_AddMusic_Step4 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddMusic-4-StartwithBlankProject.png'
const VideooEditor_AddMusic_Step5 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddMusic-5-AddCustomMusic.png'
const VideooEditor_AddMusic_Step6 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddMusic-6-ModifyCustomMusicFile.png'
const VideooEditor_AddMusic_Step7 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddMusic-7-TrimCustomMusicFile.png'
const VideooEditor_AddMusic_Step8 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddMusic-8-SearchStockAudio.png'
const VideooEditor_AddMusic_Step9 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddMusic-9-ExportOptions.png'
const VideooEditor_AddMusic_Step10 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddMusic-10-Exporting.png'
const VideooEditor_AddMusic_Step11 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddMusic-11-Exported.png'
const VideooEditor_AddMusic_Step12 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddMusic-12-Extra-TTS.png'

const VideooEditor_AddMusic_Action1 = BASE_STATIC_STORAGE_URL + '/images/features/Add%20Music%20to%20Video%202.png'
const VideooEditor_AddMusic_Action2 = BASE_STATIC_STORAGE_URL + '/images/features/Add%20Your%20File%20Music%202.png'
const VideooEditor_AddMusic_Action3 = BASE_STATIC_STORAGE_URL + '/images/features/Export%20Music.png'

const VideooEditor_AddImage_Image = BASE_STATIC_STORAGE_URL + '/images/features/Add%20Your%20File%20Filter.png'
const VideooEditor_AddTransitions_Image = BASE_STATIC_STORAGE_URL + '/images/features/Add%20Your%20File%20Transition.png'
const VideooEditor_AddText_Image = BASE_STATIC_STORAGE_URL + '/images/features/Add%20Text%20to%20Video.png'
const VideooEditor_AddStock_Image = BASE_STATIC_STORAGE_URL + '/images/features/Export%20Stock.png'


export const AddMusicToVideo = () => {
    const handleCreateNewProject = async () => {
        let result = await GetProjectLink();
        if (!result || result.state == 'error') window.toastr.error(result.data.message);
        else if (!!Object.keys(result.data)[0]) window.location.href = `${EDIT_FRONTEND_URL}/${Object.keys(result.data)[0]}?from=new`
    }

    return (
        <Box className="T_containner">
          <Box className="Header"><Header /></Box>

          <Box className="content-containner">
            <Box className="section-15" sx={{marginTop: '4rem'}}> 
                <Box className="div-1232">
                    <Grid container spacing={1} justifyContent={'center'} alignItems={'center'} height={400}>
                        <Grid item xs={12} md={6}>
                            <Card className="p-6">
                                <Box className="div-1232">
                                    <Typography variant="h3" gutterBottom fontWeight={'bold'}>
                                    Add Music to Your <br />Videos Online
                                    </Typography>
                                </Box>
                                <Box className="div-1232" sx={{marginBottom: '1rem'}}>
                                    <Typography variant="body1" gutterBottom >
                                    Using Videoo.io, you can easily add music to your videos online. Simply upload your video, add custom music or stock audio, export & download your video with images added.
                                    Enhance your videos with your favorite tracks using our easy-to-use online music addition tool. Create engaging and dynamic content by seamlessly integrating music into your video projects.
                                    </Typography>
                                </Box>
                                <Box className="div-1232" onClick={handleCreateNewProject}>
                                    <Button variant="contained" className="create-now" color="success">Create Now</Button>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6} height={'100%'}>
                            <Card className="p-6">
                                {/*<img src={Illustration} className="mx-auto h-full " />*/}
                                <div style={{ width: '100%', paddingTop: '56.25%', position: 'relative' }}>
                                <iframe
                                    src="https://www.youtube.com/embed/qFPoCL3OLCo"
                                    title="Videoo.io, Online Cloud Based Video Editor"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                                ></iframe>
                                </div>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box className='section-15 pt-12'>
              <Box className='div-1232'>
                <Box className='breadcrumbs'>
                  <a href={'/'}>
                    <Box className='breadcrumb-text-block'>Videoo</Box>
                  </a>
                  <Box className='breadcrumb-arrow'></Box>
                  <a href={'/'}>
                    <Box className='breadcrumb-text-block'>Video Editor</Box>
                  </a>
                  <Box className='breadcrumb-arrow'></Box>
                  <a href={'/'}>
                    <Box className='breadcrumb-text-block'>Add Music to Video</Box>
                  </a>
                </Box>
              </Box>
            </Box>
            <Box className="section-9 directory std-hero-section" sx={{paddingY: '3rem'}}>
              <Box className="div-1232">
                <Box className="mx-auto text-center" sx={{width: '80%'}}>
                  <Typography variant="h3" gutterBottom fontWeight={600} color={'#4D4D4D'}>Add music, sound effect or stock audio to video online for free without even downloading any program to your device</Typography>
                </Box>
                <Box sx={{textAlign: 'center', marginBottom: '3rem'}}>
                  <Typography variant="body1" gutterBottom >Free audio in your videos is possible with a single click</Typography>
                </Box>
                <Box className="container">
                  <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                    <Grid item md={6} xs={12} >
                      <img width={'100%'} src={VideooEditor_AddMusic_Welcome} />
                    </Grid>
                    <Grid item md={6} xs={12} >
                      <Box sx={{paddingBottom: '0.5rem'}}>
                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>With VIDEOO's intuitive drag-and-drop editing feature, adding muic to your videos has never been easier.</Typography>
                      </Box>
                      <Box sx={{paddingBottom: '1rem'}}>
                        <Typography variant="body1" gutterBottom>
                          - Simply upload your video to the platform, select the audio track you want to add, and drag it onto the timeline. You can adjust the volume, duration, and position of the audio to create the perfect soundtrack for your video.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          - You can also use stock audio from <a href="https://freesound.org" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}><strong>Freesound</strong></a> to enhance your videos. Choose from a wide range of sound effects, music tracks to add a professional touch to your content.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          - Adding AI-Genarated Real-life like human voices to your videos through Text-To-Speech (TTS) is possible with <a href="https://www.voiser.net" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}><strong>Voiser.net</strong></a> voices using <a href="https://www.videoo.io" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}><strong>www.Videoo.io</strong></a>. You can select from 250+ different human voices from 50+ languages to bring a human touch to your videos.
                        </Typography>
                      </Box>
                      <Box sx={{paddingTop: '1rem'}}>
                        <Button variant="contained" className="py-10 px-33" color="success">Learn More</Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
            <Box className="section-9 directory std-hero-section" >
              <Box className="div-1232">
                <Box className="text-center">
                <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>How to Add a Audio or Music to a Video, a step-by-step Guide</Typography>
                </Box>
                <Box className="text-center" sx={{marginBottom: '3rem'}}>
                  <Typography variant="body1" gutterBottom >You can use Videoo.io to enhance your creativity by adding custom or stock audio to your video projects.</Typography>
                </Box>

                <Box className="section-9 directory std-hero-section" >
                    <Box className="div-1232">
                        <Box className="container">
                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddMusic_Step1} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 1 - Using Videoo.io</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body1" gutterBottom>
                                        - Login to Videoo.io and start creating your video project. You can add music to your video by uploading your own audio files or selecting stock audio from the platform.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                        - Go to Media Section on left side menu. For stock audio, go to Stock Menu, and select <a href="https://freesound.org" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}><strong>Freesound</strong></a> Audio section.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddMusic_Step2} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 2 - Create via a Stock Content (optional)</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                      <Typography variant="body1" gutterBottom>
                                        - <a href="https://www.videoo.io" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}><strong>Videoo.io</strong></a>, once you have created the account, you can use the email in your mailbox to login.
                                      </Typography>
                                      <Typography variant="body1" gutterBottom>
                                        - Once logged in, you can begin by creating a new project. You will be able to create project using one of the Stock Content.
                                      </Typography>
                                    </Box>
                                    <Box sx={{paddingTop: '1rem'}}>
                                        <Button to="../dashboard/stocks" component={Link} variant="contained" className="py-10 px-33" color="success">Create a Stock Project</Button>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddMusic_Step3} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 3 - Create from Templates (optional)</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body1" gutterBottom>
                                        Select "Templates" from the menu. Browse through the available templates and choose one that fits your theme of adding music, sound effects, or stock audio to your video. Selecting a template will open a project workspace pre-filled with elements you can customize. This setup allows you to easily add audio, adjust the timeline, and make any other necessary modifications to enhance your video. Using a template streamlines the process, giving you a head start while still providing the flexibility to personalize your project.
                                        </Typography>
                                    </Box>
                                    <Box sx={{paddingTop: '0.1rem'}}>
                                        <Button to="../dashboard/templates" component={Link} variant="contained" className="py-10 px-33" color="success">Create a Template Project</Button>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddMusic_Step4} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                      <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 4 - Create a Blank Project</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body1" gutterBottom>
                                          To create an blank project, to the dashboard and click on "Create New Project.". You can also create or select a new workspace for this project. Here, you can upload your video files and images. Starting with a blank project allows you to customize your video from scratch, giving you the flexibility to add images precisely where you want them in the timeline.
                                        </Typography>
                                    </Box>
                                    <Box className="div-1232" onClick={handleCreateNewProject}>
                                        <Button variant="contained" className="create-now" color="success">Create a Blank Project</Button>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddMusic_Step5} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 5 - Upload Custom Audio File</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                          - Click on the "Upload" menu and select "Upload Audio." Browse your device to find the desired audio file and upload it to your project. Once uploaded, you can drag and drop the audio file onto your video timeline, positioning it exactly where you want it to play.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            -  This feature allows you to seamlessly integrate music, sound effects, or stock audio into your video, enhancing the overall production quality.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>


                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddMusic_Step6} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 6 - Positioning Custom Music File on Timeline</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                         - Once your audio file is uploaded, drag and drop it onto the timeline. To trim the audio file, click on it in the timeline to activate the editing options.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddMusic_Step7} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 7 - Trimming Audio File</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                      <Typography variant="body2" gutterBottom>
                                        - You can then drag the edges of the audio track to shorten or lengthen it as needed. Use the preview function to ensure the audio aligns perfectly with your video. This trimming feature allows you to precisely control the duration and placement of your audio, enhancing the overall quality and synchronization of your video project.
                                      </Typography>
                                      <Typography variant="body2" gutterBottom>
                                        - Also make sure to control the audio levels of the track to ensure it is in the expected state. You can adjust the volume of the audio track in the helper menu. Use the preview function to ensure the audio aligns perfectly with your video, so you will precisely control the duration and placement of your audio, enhancing the overall quality and synchronization of your video project.
                                      </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddMusic_Step8} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 8 - Adding Stock Sound to Your Video (optional)</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                         - Browse through the library of available stock audio tracks from <a href="https://freesound.org" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}><strong>Freesound</strong></a>, which may include music, sound effects, and ambient sounds. Once you find a suitable track, click on it to preview and then select "Add to Project" to insert it into your timeline. 
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                         - You can drag and drop the stock audio onto your timeline, positioning it exactly where you want it to play. Adjust the length and volume of the track to fit your video's needs. Adding stock audio is a quick and efficient way to enhance your video's production value without the need for creating custom audio content.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                         - Also make sure the audio levels are balanced and that the sound complements the visuals. You can adjust the volume of the audio track in the timeline to ensure it doesn't overpower the video content.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddMusic_Step9} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 9 - Exporting Audio Project</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                          - To export your audio project in VIDEOO, follow these steps after you have completed editing your video and adding audio. First, make sure all your audio tracks are correctly positioned and edited in the timeline.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                          - Then, navigate to the "Export" button, typically located in the top right corner of the workspace. Click on it to open the export settings menu. Here, you can choose the desired video format, resolution, and quality settings that best suit your needs.
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                          - Review the settings to ensure everything is correct, and then click on "Export" to begin the rendering process.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>


                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddMusic_Step10} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 10 - Exporting your Video with Audio</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                          - Just wait for couple of moments before the producing output for your project is complete in Videoo.io GPU based rendening engine.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddMusic_Step11} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 11 - Download or Share your Video</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                      <Typography variant="body2" gutterBottom>
                                        - Once the export is complete, you will have the option to download the finished video to your device or share it directly to various platforms. This step finalizes your project, allowing you to distribute your video with professionally integrated audio.
                                      </Typography>
                                      <Typography variant="body2" gutterBottom>
                                        - You can easily share the video on all social media platforms : <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>WhatsApp</a>, <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>Instagram</a>, <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>Facebook</a>, <a href="https://www.tiktok.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>TikTok</a>, <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>Twitter</a>, and <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>LinkedIn</a>
                                      </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
              </Box>
            </Box>

            <Box className="section-9  std-hero-section">
              <Box className="div-1232" sx={{marginTop: '2rem'}}>
                <Typography className="text-center" variant="h4" gutterBottom fontWeight={600} >Easy-to Use Add Music to Video Features</Typography>
                <Box className="container py-15" sx={{marginTop: '2rem'}}>
                  <Grid container spacing={4}>
                    <Grid item md={6} xs={12}>
                      <Box className="mb-5">
                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>
                        Audio Trimmer
                        </Typography>
                      </Box>
                      <Box className="py-10">
                        <Typography variant="body2" gutterBottom>
                         - The audio trimmer in our online video editor is a powerful and intuitive tool that allows you to fine-tune the audio elements in your video projects. With the audio trimmer, you can easily trim, cut, and adjust your audio tracks directly on the timeline, ensuring that every sound aligns perfectly with your visual content.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                         - Using the trimmer is straightforward. Simply upload your audio files, and they will appear on the timeline. From there, you can click on any audio track to activate the trimming tool. Drag the edges of the audio clip to shorten or lengthen it as needed.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                         - In addition to trimming, the cutting tool allows you to split audio clips at any point on the timeline. This feature is particularly useful for removing unwanted sections of audio or for synchronizing different audio clips with specific moments in your video. By clicking on the desired point and using the cutting tool, you can create seamless audio edits that enhance the overall impact of your video.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                         - Our online video editor's audio trimmer and cutting tool provide you with the flexibility and precision needed to create high-quality video content without the need for any additional software downloads. Whether you're adding background music, sound effects, or voiceovers, these tools make it easy to achieve the perfect audio-visual sync.
                        </Typography>
                      </Box>
                      <Box className="mx-auto text-center mt-10" onClick={handleCreateNewProject}>
                        <Button variant="contained" className="py-10 px-33" color="success">Create with Audio Trimmer</Button>
                      </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Box className="mb-5">
                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>
                        Audio Level Control
                        </Typography>
                      </Box>
                      <Box className="py-10">
                        <Typography variant="body2" gutterBottom>
                          The Audio Level Modifier in our online video editor is an essential tool for balancing and enhancing the audio in your video projects. This feature allows you to adjust the volume levels of your audio tracks directly on the timeline, ensuring that every element of your sound design is perfectly balanced.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                        With the Audio Level Modifier, you can easily increase or decrease the volume of any audio clip by selecting it on the timeline and using the volume slider. This precise control ensures that background music, voiceovers, and sound effects are all at the optimal levels, preventing any audio from overpowering the others.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                        The user-friendly interface makes it simple for both beginners and experienced editors to make accurate audio adjustments. Real-time previewing of volume changes ensures that you can hear exactly how your modifications will sound in the final video, allowing for quick and efficient editing.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                        By utilizing the Audio Level Modifier in our online video editor, you can achieve professional-quality audio balance and dynamics in your video projects without the need for any additional software downloads. This tool is indispensable for creating videos with clear, well-balanced sound that enhances the overall viewing experience.
                        </Typography>
                      </Box>
                      <Box className="mx-auto text-center mt-10" onClick={handleCreateNewProject}>
                        <Button variant="contained" className="py-10 px-33" color="success">Create with Audio Level Control</Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>



            <Box className="section-9  std-hero-section">
              <Box className="div-1232" sx={{marginTop: '2rem'}}>
                <Typography className="text-center" variant="h4" gutterBottom fontWeight={600} >Best Content Production Strategies for Audio in Video Editing</Typography>
                <Box className="container py-15" sx={{marginTop: '2rem'}}>
                  <Grid container spacing={4}>
                    <Grid item md={6} xs={12}>
                      <Box className="mb-5">
                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>
                        When to Add Music to a Video
                        </Typography>
                      </Box>
                      <Box className="py-10">
                        <Typography variant="body2" gutterBottom>
                          - <strong>Opening and Closing Sequences:</strong> Music is perfect for bookending your video. Use an engaging track at the beginning to grab the audience's attention and another at the end to leave a lasting impression. This helps in setting the mood and providing a sense of closure.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          - <strong>Scene Transitions:</strong> Adding music during transitions between scenes can smooth the shift and keep the viewer engaged. It helps in maintaining a flow and continuity throughout the video, especially in longer projects.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          - <strong>Highlighting Key Moments:</strong> Use music to emphasize significant moments or turning points in your video. For instance, a triumphant score can highlight a victory or success, while a somber tune can underscore a more serious or emotional moment.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          - <strong>Background Ambiance:</strong> For videos with voiceovers or dialogue, background music can provide ambiance without overpowering the spoken content. It fills the silence and can subtly influence the viewer's emotions and perceptions.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          - <strong>Montages and Time-lapses:</strong> Music is essential for montages and time-lapse sequences. It can drive the pacing and rhythm of these segments, making them more dynamic and enjoyable to watch.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          - <strong>Brand Identity:</strong> For promotional and corporate videos, background music can reinforce brand identity. Consistent use of a particular style or theme can make your brand more recognizable and memorable.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          - <strong>Enhancing Narratives:</strong> In storytelling, music can enhance the narrative by adding depth and dimension to the story. It can complement the visuals and help convey the underlying themes and emotions.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          - <strong>Engaging Tutorials and Educational Content:</strong> Adding music to instructional videos can make them more engaging and less monotonous. It helps in maintaining the viewer’s interest, especially in longer tutorials.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          By carefully selecting and integrating music into your video at these key moments, you can create a more compelling and immersive viewing experience. Music not only enhances the visual elements but also adds an emotional layer, making your video more impactful and memorable.
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Box className="mb-5">
                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>
                        How to Choose the Right Music for a Video Editing Project
                        </Typography>
                      </Box>
                      <Box className="py-15">
                        <Typography variant="body1" gutterBottom>
                          Choosing the right music for your video is crucial to enhancing the viewer's experience and conveying the intended message. Here are some tips to help you select the perfect track:
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          - <strong>Understand the Mood:</strong> Consider the overall mood and tone of your video. Whether it's uplifting, dramatic, or calm, the music should match and enhance the emotional impact you want to create.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          - <strong>Know Your Audience:</strong> Think about the preferences and expectations of your target audience. Choose music that resonates with them and fits the context of the video.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          - <strong>Consider the Genre:</strong> The genre of the music should align with the content of the video. For example, use classical music for elegant, sophisticated themes, or upbeat pop music for fun, energetic content.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          - <strong>Match the Pace:</strong> Ensure the tempo of the music matches the pace of your video. Fast-paced music works well for action scenes, while slower music can complement more reflective or emotional moments.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          - <strong>Use Instrumental Tracks:</strong> If your video contains dialogue or voiceovers, consider using instrumental tracks to avoid competing with the spoken words. This helps maintain clarity and focus on the message.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          - <strong>Check Licensing:</strong> Ensure that the music you choose is properly licensed for use in your video. Using royalty-free or properly licensed music helps avoid legal issues.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          - <strong>Test and Adjust:</strong> Experiment with different tracks and see how they affect the overall feel of your video. Don't be afraid to make adjustments until you find the perfect fit.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          By carefully selecting the right music, you can significantly enhance the emotional impact and engagement of your video, creating a more immersive and memorable experience for your audience.
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>

            <Box className="section-9 directory" sx={{paddingY: '3rem'}}>
              <Box className="div-1232 px-18">
                <Box className="text-center">
                <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>
                  Enhance Your Video Projects with AI-Voices
                </Typography>
                </Box>
                <Box className="text-center mx-auto" sx={{marginBottom: '3rem', width: '60%'}}>
                  <Typography variant="body1" gutterBottom>
                    Easy to use Text-to-Speech Audio generation is available in Videoo.io
                  </Typography>
                </Box>

                <Box className="text-center mx-auto" sx={{marginBottom: '3rem', width: '60%'}}>
                  <Typography variant="body1" gutterBottom>
                    Voiser.net offers advanced AI-generated voices that can add a human-like quality to your video projects. With over 250 different voices available in more than 50 languages, Voiser.net provides a versatile and powerful tool for creating engaging and professional audio content.
                  </Typography>
                </Box>

                <Box className="py-15">
                  <Typography variant="body2" gutterBottom>
                    - <strong>Wide Range of Voices:</strong> Choose from 250+ distinct voices to find the perfect match for your video. Whether you need a deep, authoritative tone or a light, friendly voice, Voiser.net has a variety of options to suit your needs.
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    - <strong>Multilingual Support:</strong> Voiser.net supports over 50 languages, allowing you to create content for a global audience. This makes it easy to add voiceovers in different languages, ensuring your message reaches a wider demographic.
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    - <strong>Human-Like Quality:</strong> The AI-generated voices from Voiser.net are designed to sound natural and human-like. This enhances the overall quality of your video, making it more relatable and engaging for viewers.
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    - <strong>Easy Integration:</strong> Integrating Voiser.net's voices into your video projects is straightforward. Simply select the voice you want, generate the audio, and add it to your video timeline.
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    - <strong>Versatile Applications:</strong> Whether you're creating promotional videos, tutorials, or narrations, Voiser.net provides a versatile solution for adding professional-quality voiceovers to your projects.
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    By utilizing Voiser.net's AI-generated voices, you can enhance the auditory experience of your videos, making them more impactful and accessible to a diverse audience. This tool is an excellent addition to any video editor's toolkit, offering a simple yet powerful way to improve your content.
                  </Typography>
                </Box>
                <Grid item md={6} xs={12} >
                  <Box className="py-10">
                      <img width={'100%'} src={VideooEditor_AddMusic_Step12} />
                  </Box>
                </Grid>
              </Box>
            </Box>

            <Box className="container">
                <Grid container spacing={5}>
                    <Grid item md={4} xs={12} className="text-center">
                    <Card className="p-6">
                        <Box className="text-center py-4">
                        <img className="mx-auto" src={VideooEditor_AddMusic_Action1}/>
                        </Box>
                        <Box className="text-center py-4">
                        <Typography variant="h5" gutterBottom fontWeight={600} color={'#4D4D4D'}>Add Your Content</Typography>
                        </Box>
                        <Box className="text-center py-4">
                        <Typography variant="body2" gutterBottom>
                            - Select your video and upload it. You can drag & drop, or choose the file from your computer or mobile device
                        </Typography>
                        </Box>
                    </Card>
                    </Grid>
                    <Grid item md={4} xs={12} className="text-center">
                    <Card className="p-6">
                        <Box className="text-center py-4">
                        <img className="mx-auto" src={VideooEditor_AddMusic_Action2}/>
                        </Box>
                        <Box className="text-center py-4">
                        <Typography variant="h5" gutterBottom fontWeight={600} color={'#4D4D4D'}>Add Your Audio</Typography>
                        </Box>
                        <Box className="text-center py-4">
                        <Typography variant="body2" gutterBottom>
                            - Either you can add custom audio file, or you can use stock audio from Freesound using Videoo.io
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            - <a href="https://freesound.org" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}><strong>Freesound:</strong></a> This resource offers a variety of sound effects and audio clips that you can incorporate into your video. Adding unique sounds can significantly improve the engagement and professionalism of your content.
                        </Typography>
                        </Box>
                    </Card>
                    </Grid>
                    <Grid item md={4} xs={12} className="text-center">
                    <Card className="p-6">
                        <Box className="text-center py-4">
                        <img className="mx-auto" src={VideooEditor_AddMusic_Action3}/>
                        </Box>
                        <Box className="text-center py-4">
                        <Typography variant="h5" gutterBottom fontWeight={600} color={'#4D4D4D'}>Export, Download & Share</Typography>
                        </Box>
                        <Box className="text-center py-4">
                        <Typography variant="body2" gutterBottom>
                            - When your project timeline is in sync, you can export your project, ready for downloading or sharing in social media.
                        </Typography>
                        </Box>
                    </Card>
                    </Grid>
                </Grid>
            </Box>



            <Box className="section-9 directory" sx={{paddingY: '3rem'}} >
                <Box className="div-1232 px-18">
                    <Box>
                    <Typography className="text-center" variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Other Video Tools</Typography>
                    </Box>
                    <Box className="text-center">
                    <Typography className="text-center" variant="body1" gutterBottom color={'#4D4D4D'}>There are plenty of other features that are avaliable with Videoo.io</Typography>
                    </Box>
                </Box>
                <Box >
                    <Grid container spacing={3}>
                    <Grid item md={3} xs={12}>
                        <Card className="p-6" sx={{background: '#abbed133'}}>
                        <Grid >
                            <img width={'100%'} src={VideooEditor_AddImage_Image} />
                        </Grid>
                        <Typography sx={{paddingY: '1rem'}} className="text-center" variant="h5" gutterBottom fontWeight={500} color={'#717171'}>
                            Add Image to Video
                        </Typography>
                        <Box className="mx-auto text-center">
                            <Button to="../add-image-to-video" component={Link}>Create <ArrowRightAltIcon /></Button>
                        </Box>
                        </Card>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Card className="p-6" sx={{background: '#abbed133'}}>
                        <Grid >
                            <img width={'100%'} src={VideooEditor_AddText_Image} />
                        </Grid>
                        <Typography sx={{paddingY: '1rem'}} className="text-center" variant="h5" gutterBottom fontWeight={500} color={'#717171'}>
                        Add Text to Video
                        </Typography>
                        <Box className="mx-auto text-center">
                            <Button to="../add-text-to-video" component={Link}>Create <ArrowRightAltIcon /></Button>
                        </Box>
                        </Card>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Card className="p-6" sx={{background: '#abbed133'}}>
                        <Grid >
                            <img width={'100%'} src={VideooEditor_AddStock_Image} />
                        </Grid>
                        <Typography sx={{paddingY: '1rem'}} className="text-center" variant="h5" gutterBottom fontWeight={500} color={'#717171'}>
                        Add Free Stock Content to Video
                        </Typography>
                        <Box className="mx-auto text-center">
                            <Button to="../add-free-stock-to-video" component={Link}>Create <ArrowRightAltIcon /></Button>
                        </Box>
                        </Card>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Card className="p-6" sx={{background: '#abbed133'}}>
                        <Grid>
                            <img width={'100%'} src={VideooEditor_AddTransitions_Image} />
                        </Grid>
                        <Typography sx={{paddingY: '1rem'}} className="text-center" variant="h5" gutterBottom fontWeight={500} color={'#717171'}>
                            Add Transitions to Video
                        </Typography>
                        <Box className="mx-auto text-center">
                            <Button to="../add-transition-to-video" component={Link}>Create <ArrowRightAltIcon /></Button>
                        </Box>
                        </Card>
                    </Grid>
                    </Grid>
                </Box>
            </Box>

            <Box className="section-9 directory" sx={{paddingY: '3rem'}}>
              <Box className="div-1232 px-18">
                <Box className="text-center">
                  <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>
                    Frequently Asked Questions
                  </Typography>
                </Box>
                <Box className="text-center mx-auto" sx={{marginBottom: '3rem', width: '60%'}}>
                  <Typography variant="body1" gutterBottom>
                    Welcome to our FAQ section about adding Audio to a Video! Here, we answer the most common questions about using VIDEOO. Whether you're new to video editing or a seasoned pro, you'll find helpful tips and insights to make the most of our platform.
                  </Typography>
                </Box>

                <Box className="py-15">
                  <Typography variant="h5" gutterBottom>
                    Question #1: How do I add audio to a video using VIDEOO?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    To add audio to a video using VIDEOO, click on the "Upload" menu and select "Upload Audio." Choose your desired audio file from your device, upload it, and drag it onto the timeline. This allows you to seamlessly integrate music, sound effects, or voiceovers into your video.
                  </Typography>
                </Box>

                <Box className="py-15">
                  <Typography variant="h5" gutterBottom>
                    Question #2: Can I customize the audio I add to my videos in VIDEOO?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Yes, VIDEOO allows you to customize the audio you add to your videos. You can adjust the volume, trim the audio, and use keyframes to create dynamic volume changes throughout the video.
                  </Typography>
                </Box>

                <Box className="py-15">
                  <Typography variant="h5" gutterBottom>
                    Question #3: What audio formats are supported for uploads in VIDEOO?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    VIDEOO supports various audio formats, including MP3, WAV, and AAC. This ensures compatibility and flexibility when adding different types of audio to your videos.
                  </Typography>
                </Box>

                <Box className="py-15">
                  <Typography variant="h5" gutterBottom>
                    Question #4: Can I add multiple audio tracks to a single video using VIDEOO?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Absolutely! VIDEOO allows you to add multiple audio tracks to a single video. You can easily drag and drop several audio files into the timeline and arrange them as needed to create a rich and layered soundscape.
                  </Typography>
                </Box>

                <Box className="py-15">
                  <Typography variant="h5" gutterBottom>
                    Question #5: How does VIDEOO ensure the quality of the audio added to videos?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    VIDEOO maintains the quality of the audio added to videos by supporting high-fidelity audio files and providing tools to optimize the audio for the best auditory experience. This includes features like noise reduction and equalization.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className="section-9 directory std-hero-section" >
              <Box className="section-15 py-12 mx-auto">
                <Box className="div-1232">
                  <Box className="mx-auto">
                    <Typography sx={{textAlign: 'center'}} variant="h3" gutterBottom fontWeight={'bolder'}>Make Your Videos Easily</Typography>
                  </Box>
                  <Box className="mx-auto text-center mt-10" onClick={handleCreateNewProject}>
                    <Button variant="contained" className="py-10 px-33" color="success">Create Now <ArrowRightAltIcon /></Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="footer-containner"><Footer /></Box>
        </Box>
        
    )
}