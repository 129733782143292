import { TOOLKEY, SUBTITLESTATEKEY, ELEMENTSTATEKEY, DRAWSTATEKEY, MENUBARREF, CAPTUREDIALOG, LANGUAGESTRS } from "./../constants/editConst";

export const menubarRef_Store = (params) => {
  return (dispatch) => { dispatch({ type: MENUBARREF, payload: params, }); }
}

export const SelectedEditTool_Store = (params) => {
  return (dispatch) => { dispatch({ type: TOOLKEY, payload: params, }); }
}

export const SubtitleState_Store = (params) => {
  return (dispatch) => { dispatch({ type: SUBTITLESTATEKEY, payload: params, }); }
};

export const ElementState_Store = (params) => {
  return (dispatch) => { dispatch({ type: ELEMENTSTATEKEY, payload: params }); }
};

export const DrawState_Store = (params) => {
  return (dispatch) => { dispatch({ type: DRAWSTATEKEY, payload: params, }); }
};

export const CaptureDialog_Store = (params) => {
  return (dispatch) => { dispatch({ type: CAPTUREDIALOG, payload: params, }); }
};

export const languageStrs_Store = (params) => {
  return (dispatch) => { dispatch({ type: LANGUAGESTRS, payload: params, }); }
}