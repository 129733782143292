// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-preference-wrapper {
  display: flex;
  flex-direction: column;
  width: calc(100% - 1px);
  height: calc(100% - 8px);
  padding-top: 60px;
}
.custom-preference-wrapper .custom-prefernece-toolbar-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  z-index: 2;
  height: 64px;
  padding: 0px 32px 8px;
  flex-direction: column;
}
.custom-preference-wrapper .custom-prefernece-toolbar-section .toolbar-Breadcrumbs-contain {
  flex-shrink: 1;
  min-width: 0px;
  display: inline-flex;
  flex-flow: row nowrap;
  width: 100%;
  align-items: center;
}
.custom-preference-wrapper .custom-prefernece-toolbar-section .toolbar-Breadcrumbs-contain a {
  text-decoration: none;
  flex: 0 0 auto;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 160px;
  color: inherit;
  padding: 0.25rem 8px;
  border-radius: 0.3125rem;
  font-family: Inter, sans-serif;
  font-size: 1.125rem;
  line-height: 1.5rem;
  letter-spacing: 0px;
  font-weight: 500;
  margin-left: -0.25rem;
  margin-right: 0.25rem;
  overflow: hidden;
}
.custom-preference-wrapper .custom-prefernece-toolbar-section .toolbar-Breadcrumbs-contain a:hover {
  background-color: rgb(238, 238, 240);
}`, "",{"version":3,"sources":["webpack://./src/component/workspaces/pages/preferences/preferences.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,wBAAA;EACA,iBAAA;AACJ;AAAE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,gBAAA;EACA,QAAA;EACA,UAAA;EACA,YAAA;EACA,qBAAA;EACA,sBAAA;AAEJ;AADI;EACI,cAAA;EACA,cAAA;EACA,oBAAA;EACA,qBAAA;EACA,WAAA;EACA,mBAAA;AAGR;AADQ;EACE,qBAAA;EACA,cAAA;EACA,qBAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,cAAA;EACA,oBAAA;EACA,wBAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,qBAAA;EACA,qBAAA;EACA,gBAAA;AAGV;AADU;EACE,oCAAA;AAGZ","sourcesContent":[".custom-preference-wrapper{\n    display: flex;\n    flex-direction: column;\n    width: calc(100% - 1px);\n    height: calc(100% - 8px);\n    padding-top: 60px;\n  .custom-prefernece-toolbar-section{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    position: sticky;\n    top: 0px;\n    z-index: 2;\n    height: 64px;\n    padding: 0px 32px 8px;\n    flex-direction: column;\n    .toolbar-Breadcrumbs-contain {\n        flex-shrink: 1;\n        min-width: 0px;\n        display: inline-flex;\n        flex-flow: row nowrap;\n        width: 100%;\n        align-items: center;\n  \n        a {\n          text-decoration: none;\n          flex: 0 0 auto;\n          display: inline-block;\n          white-space: nowrap;\n          text-overflow: ellipsis;\n          max-width: 160px;\n          color: inherit;\n          padding: 0.25rem 8px;\n          border-radius: 0.3125rem;\n          font-family: Inter, sans-serif;\n          font-size: 1.125rem;\n          line-height: 1.5rem;\n          letter-spacing: 0px;\n          font-weight: 500;\n          margin-left: -0.25rem;\n          margin-right: 0.25rem;\n          overflow: hidden;\n  \n          &:hover {\n            background-color: rgb(238, 238, 240);\n          }\n        }\n      }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
