import './blogs/videooblog/videooblog.scss'
import { Layouts } from '../component/layouts/layouts'

export const Cookies = () => {
  return (
    <Layouts>
      <div className='flex flex-col items-center justify-center px-10 sm:px-20 md:px-30 lg:px-40'>
        <div className='w-full max-w-1000 flex flex-col gap-10 md:gap-20 lg:gap-30 py-30'>
          <div className='text-center text-20 md:text-30 lg:text-40 font-semibold'>
            Cookies Policy
          </div>

          <div className='flex flex-col gap-10 mm:gap-20'>
            <div className='text-15 md:text-18'>
              This Cookies Policy explains how Videoo.io uses cookies and similar technologies to provide you with a personalized and enhanced experience when using our online cloud-based video editor. By using Videoo.io, you consent to the use of cookies as described in this policy.

              <br /><br />

              <span className='font-semibold'>What are Cookies:</span> Cookies are small text files that are placed on your device when you visit a website. They help us recognize your device and gather information about your preferences and activities on Videoo.io.

              <br /><br />

              <span className='font-semibold'>Types of Cookies:</span> We use both session cookies and persistent cookies on Videoo.io. Session cookies are temporary and are deleted when you close your browser, while persistent cookies remain on your device for a longer period.

              <br /><br />

              <span className='font-semibold'>How We Use Cookies:</span> We use cookies for various purposes, including but not limited to:

              <ul className='list-disc ml-10'>
                <li>Remembering your preferences and settings</li>
                <li>Providing personalized content and recommendations</li>
                <li>Improving our website's performance and functionality</li>
                <li>Analyzing usage patterns and trends</li>
                <li>Tracking and measuring the effectiveness of our marketing campaigns</li>
              </ul>

              <br />

              <span className='font-semibold'>Third-Party Cookies:</span> We may allow third-party service providers to place cookies on your device. These providers help us analyze website traffic, deliver targeted advertisements, and provide additional functionality. Please note that we have no control over these third-party cookies and their use is subject to their own privacy policies.

              <br /><br />

              <span className='font-semibold'>Managing Cookies:</span> You have the option to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser settings to decline cookies if you prefer. However, please note that disabling cookies may affect your ability to use certain features of Videoo.io.

              <br /><br />

              <span className='font-semibold'>Changes to this Policy:</span> We may update this Cookies Policy from time to time. Any changes will be posted on this page, and the revised policy will become effective immediately. We encourage you to review this policy periodically.

              <br /><br />

              <span className='font-semibold'>Contact Us:</span> If you have any questions or concerns about our use of cookies or this Cookies Policy, please contact us at cookies@videoo.io.

            </div>

          </div>
        </div>
      </div>
    </Layouts>
    )
}