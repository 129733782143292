import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import { NotificationContainer } from 'react-notifications';

import './assets/style/index.scss';
import 'react-notifications/lib/notifications.css';
import reportWebVitals from './reportWebVitals';

import { Router } from './router';
import configureStore from './store';
import { GlobalProvider } from './provider';
import { mainConst } from './store/constants/mainConst';
import { videoEditData } from './store/constants/editConst';
import { videodata } from './store/constants/videoPlayConst';
import { webgldata } from './store/constants/webglinfo';
import { projectdata } from './store/constants/projectinfo';
import { MuiThemeProvider } from './theme/theme';
import TagManager from 'react-gtm-module';
import {editorLangEnStrs} from './lang/editor_string_en';
const InitialData = {
  maindata: mainConst,
  editdata: videoEditData,
  videodata: videodata,
  webgldata: webgldata,
  projectdata: projectdata,
}
InitialData.editdata.languageStrs = editorLangEnStrs;
const tagManagerArgs = {
  gtmId: 'GTM-WZBNQ9RR'
}

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('videoo-app'));

root.render(
  <MuiThemeProvider>
    <GlobalProvider>
      <Provider store={configureStore(InitialData)}>
        <Router />

        <NotificationContainer />
      </Provider>
    </GlobalProvider>
  </MuiThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals)
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
