import { Box } from '@mui/system';

import './translate-video';
import UploadImg from './../../../assets/image/tools/upload-img.png';
import facebookLogo from "./../../../assets/image/Logos/facebook_logo.png";
import instagramLogo from "./../../../assets/image/Logos/instagram_logo.png";
import tiktokLogo from "./../../../assets/image/Logos/tiktok_logo.png";
import twitterLogo from "./../../../assets/image/Logos/twitter_logo.png";
import youtubeLogo from "./../../../assets/image/Logos/youtube_logo.png";
import whatsappLogo from "./../../../assets/image/Logos/whatsapp_logo.png";

import ImageOne from "./../../../assets/image/pages/translateVideo/image1.jpg";
import ImageTwo from "./../../../assets/image/pages/translateVideo/image2.jpg";
import ImageThree from "./../../../assets/image/pages/translateVideo/image3.jpg";
import ImageFour from "./../../../assets/image/pages/translateVideo/image4.jpg";

import { createNewProject } from './../SubtitlesTranscriptionHelper';
import { Header } from '../../../component/home-header/header';
import { Footer } from '../../../component/home-footer/footer';

export const BASE_STATIC_STORAGE_URL = process.env.REACT_APP_STATIC_STORAGE_URL;
export const how_to_1   = BASE_STATIC_STORAGE_URL + '/images/features/Add Your File Translate Video.png';
export const how_to_2   = BASE_STATIC_STORAGE_URL + '/images/features/Translate Video.png';
export const how_to_3   = BASE_STATIC_STORAGE_URL + '/images/features/Export Translate Video.png';

export const TranslateVideo = () => {
  return (
    <Box className="T_containner">
      <Box className="Header"><Header /></Box>

      <Box className="content-containner">
      <>
      <Box className='section-15'>
        <Box className='div-1232 _1300'>
          <Box className='breadcrumbs'>
            <a href={'/'}>
              <Box className='breadcrumb-text-block'>Videoo</Box>
            </a>
            <Box className='breadcrumb-arrow'></Box>
            <a href={'/'}>
              <Box className='breadcrumb-text-block'>Tools</Box>
            </a>
            <Box className='breadcrumb-arrow'></Box>
            <Box className='breadcrumb-text-block'>Video Translator</Box>
          </Box>
        </Box>
      </Box>

      <Box className='section-9 directory std-hero-section'>
        <Box className='div-1232'>
          <Box className='hero-grid'>
            <Box className='hero-grid-text'>
              <h1 className='hero-header-title'>Video Translator</h1>
              <Box className='hero-content-title'>Free online video editing software - create videos online</Box>

              <Box onClick={createNewProject} className='choose-btn'>
                <Box className='choose-btn-text'>Create Project</Box>
                <Box className='choose-btn-img'>
                  <img src={UploadImg} alt='uploadImg' />
                </Box>
              </Box>
            </Box>
            <Box className='hero-img'>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/AzJwYv8YRDs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </Box>
          </Box>

          {/* <Box className="logos-grid">
              <Box className="usedByLogos-item">
                  <img src={instagramLogo} alt="instagram logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={youtubeLogo} alt="youtube logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={facebookLogo} alt="facebook logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={twitterLogo} alt="twitter logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={tiktokLogo} alt="tiktok logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={whatsappLogo} alt="whatsapp logo" width={100} />
              </Box>
              {{usedLogs.map((logo, index) => (
                  <Box className="usedByLogos-item" key={index}>{logo}</Box>
              ))}}
          </Box> */}

        </Box>
      </Box>

      <Box className='wf-section'>
        <Box className='div-1232'>
          <Box className='div-block-67'>
            <h2 className='heading-20'>Translate Your Videos In Any Language Using Videoo.io!</h2>
            <p>
            You can reach wider audiences by creating content in different languages without knowing those languages. You just need to create auto subtitles in those languages. 
            </p>
            <p>
            Videoo.io’s AI-powered auto-translation feature can translate your content into more than 30 languages such as English, German, Spanish, Turkish, etc. This feature uses the Auto Subtitles technique to generate translations.
            </p>
            <img className='mx-auto' src={ImageTwo}/>
            <h2 className='heading-20'>How to Translate a Video?</h2>
            <p>
              Translating a video to text is a very easy task in Videoo.io. You can easily achieve that goal by following a few simple steps.
              <ul>
                <li>Upload a media: Click on the “Media” tab on the left menu. Select your media.</li>
                <li>Navigate to the Translations Tab: Click on the “Translations” tab on the left menu.</li>
                <li>Select a language to detect: Select the language spoken on the video or audio</li>
                <li>Select a language to translate into: Select the language you want to translate.</li>
                <li>Customize your subtitles: Adjust the font, size, and colors.</li>
                <li>Translate: Click on the “Auto Translations” button.</li>
              </ul>
            </p>
            <img className='mx-auto' src={ImageOne}/>
            <h2 className='heading-20'>How to Edit a Translation?</h2>
            <p>You can use the Subtitle Editor to edit the translations. Select the text you want to edit and click on the Edit Subtitle button on the mini helper menu. Even if you have thousands of words in your translations, you can edit all the styles, sizes, and content of the text with a single click in seconds.</p>
            <h2 className='heading-20'>Increase Your Engagement with Translated Videos!</h2>
            <p>To reach wider audiences on popular platforms like Instagram Reels, TikTok, YouTube, and Shorts you can use translated videos. Videoo.io's AI-generated auto translations ensure your content remains engaging even when viewers don’t know the language spoken in your content. Additionally, you can even generate content in different aspect ratios using the same AI-generated subtitles. For example, you can create TikTok content and after that, you can convert it into a YouTube video too without the need of generating another translation. Your translated captions stay the same.</p>
            <img className='mx-auto' src={ImageFour}/>
            <h2 className='heading-20'>How to Add Translated Subtitles in Two Languages?</h2>
            <p>You may want to reach an even wider audience by adding two subtitles in two different languages, for example, one in German and the other in Turkish. To achieve that goal, you can use the translation again with a different language to translate option. This will automatically generate a new translation. You can choose a different style for this translation to separate between them.</p>
            <img className='mx-auto' src={ImageThree}/>
            <h2 className='heading-20'>Conclusion</h2>
            <p>Videoo.io's Translations feature simplifies creating and customizing translated subtitles, saving you time. You don’t have to know the language you want to create content or translate and edit the subtitles one by one. Subtitle Editor can customize your auto translations with a single click. Unleash the power of AI-generated translations with Videoo.io and increase your engagement.</p>
            <h2 className='heading-20'>FAQ</h2>
            <p>
              <ul>
                <li>
                  <span>Q: How can I translate a video?</span><br />
                  <span>A: You can easily translate videos to text by clicking the Auto Translations button in the Translations tab.</span>
                </li>
                <li>
                  <span>Q: Can I edit the subtitles?</span><br />
                  <span>A: Absolutely! Videoo.io offers a Subtitle Editor to edit the translated subtitles with a single click and allows you to edit them in seconds. This subtitle editor allows you to adjust each caption's size, style, and content.</span>
                </li>
                <li>
                  <span>Q: Which languages can I translate?</span><br />
                  <span>A: You can now create translations in more than 30 languages including English, Spanish, French, German, Italian, Japanese, and more.</span>
                </li>
                <li>
                  <span>Q: How do I add subtitles in multiple languages?</span><br />
                  <span>A: You can use the translation again with a different language to translate option. This will automatically generate a new translation. You can choose a different style for this translation to separate the translated subtitles between them.</span>
                </li>
                <li>
                  <span>Q: Can I generate voiceovers with this subtitle translator?</span><br />
                  <span>A: Yes! You can use our Text-to-speech tool to vocalize your translations.</span>
                </li>
              </ul>
            </p>
          </Box>
        </Box>
      </Box>

      <Box className='wf-section'>
        <Box className='div-1232 wide _3'>
          <Box className='div-block-70'>
            <Box className='div-block-69'>
              <h2 className="heading-20">How to Translate a Video</h2>
            </Box>

            <Box className='div-block-68'>
              <Box className='how-to-div-item'>
                <img src={how_to_1} alt='howTo1' className="how-to-img" />
                <h3 className="heading-21 m-y-0">Add Your File</h3>
                <p className="how-to-content">Select your video and upload it. You can drag &amp; drop, or choose the file from your computer or mobile device</p>
              </Box>

              <Box className='how-to-div-item'>
                <img src={how_to_2} alt='howTo2' className="how-to-img" />
                <h3 className="heading-21 m-y-0">Translate Video</h3>
                <p className="how-to-content">Log into Videoo.io, select your project. Navigate to 'Translations' menu; choose source and target languages.</p>
              </Box>

              <Box className='how-to-div-item'>
                <img src={how_to_3} alt='howTo3' className="how-to-img" />
                <h3 className="heading-21 m-y-0">Share!</h3>
                <p className="how-to-content">Select appropriate font and size for subtitles and click Translate. Wait for a few moment. And voila it is ready.</p>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
      </Box>

      <Box className="footer-containner"><Footer /></Box>
    </Box >
   
  )
}