import copy from "copy-to-clipboard";
import axios from 'axios';
import { useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { NavLink, Link, useNavigate, useParams } from "react-router-dom";
import { Box, Typography, LinearProgress, Alert, Snackbar } from "@mui/material";
import moment from "moment";
import 'moment-timezone';

import { EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";

import HomeIcon from '@mui/icons-material/Home';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { ShareIcon, LinkIcon, FacebookIcon, TwitterIcon, EmailIcon, LinkedinIcon, CutIcon, LightiningIcon } from "../../Icons";
import Logo from '../../assets/image/Logo.png';
import ExportLogo from "../../assets/image/ExportLogo.png"

import './assetView.scss';
import { updateProjectJSON } from "../../axios/ApiProvider";
import { copyObject } from '../../component/workspaces/dashboard.config';
import { getWebHookData, getProjectJSON, getAssetData } from "../../axios/ApiProvider";
import PricingModal from "../Pricing/pricingModal";

const EDIT_FRONTEND_URL = process.env.REACT_APP_BASE_FRONTEND_EDITOR_URL

export const AssetView = () => {
  const languageStrs = useSelector((store) => store.editdata.languageStrs)
  let history = useNavigate();
  let { uuid, projectUUID } = useParams();
  const [openHelper, setOpenHelper] = useState(false);

  const [userData, setUserData] = useState({})
  const loginState = useSelector((store) => store.maindata.loginState)
  const [showPricingPageModal, setShowPricingPageModal] = useState(false)
  const [assetsData, setAssetsData] = useState();
  const videoRef = useRef(null)

  const [videoBlob, setVideBlob] = useState();

  useEffect(() => {
    let tempData = {}
    let item = localStorage.getItem('user_data')
    if (!!item && JSON.parse(item)?.state === 'loggedin') tempData = JSON.parse(item)

    setUserData(tempData)
  }, [loginState])

  useEffect(() => {
    (async function() {
      const resp = await getAssetData(uuid, projectUUID);
      setAssetsData(resp.data)
    })()
  }, [uuid, projectUUID]);

  useEffect(() => {
    if(videoRef && assetsData) {
      videoRef.current.src = assetsData.signedurl
    }
  }, [assetsData])
  const [progressVal, setProgressVal] = useState(0);
  const [videoDownloading, setVideoDownloading] = useState(false);
  const downloadFunc = () => {
    if(assetsData && assetsData.signedurl) {
      let videoUrl = assetsData?.signedurl;
      const filename = assetsData?.filename
      if (!videoUrl) return;
      if(videoBlob){
        const aElement = document.createElement('a');
        aElement.setAttribute('download', filename);
        const href = URL.createObjectURL(videoBlob);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
        setVideoDownloading(false);
      }else{
        setProgressVal(0);
        axios.get(videoUrl, 
          {responseType: 'blob',
            onDownloadProgress: progressEvent => {
              setVideoDownloading(true);
              setProgressVal(Math.floor(progressEvent.progress * 100));
            }
          }).then(res=>{
            setVideBlob(res.data);
            const aElement = document.createElement('a');
            aElement.setAttribute('download', filename);
            const href = URL.createObjectURL(res.data);
            aElement.href = href;
            aElement.setAttribute('target', '_blank');
            aElement.click();
            URL.revokeObjectURL(href);
            setVideoDownloading(false);
        })
      }

      /*
      fetch(videoUrl).then(res => res.blob()).then(file => {
        let tempUrl = URL.createObjectURL(file);
        const link = document.createElement("a");
  
        link.href = tempUrl;
        link.download = filename || 'video.mp4';
  
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(tempUrl);
        link.remove();
      }).catch(() => { alert("Failed to download file!"); });
      */
    } else {
      window.toastr.error("Can not download") 
    }
  }

  let project_url = window.location.href;
  const [opanAlert, setOpenAlert] = useState(false);
  const copyLinkFunc = () => {
    let copy_res = copy(project_url);
    if (!copy_res || opanAlert) return;

    setOpenAlert(true);
    setTimeout(() => { setOpenAlert(false); }, 1500);
  }

  const [leftTime, setLeftTime] = useState(0);

  // useEffect(() => {
  //   firstLoadFunc()
  // }, [])

  // const firstLoadFunc = async () => {
  //   try {
  //     const JSONdata = await getProjectJSON(projectUUID);
  //     if (JSONdata.state !== "success" || !JSONdata.data[projectUUID]) return;
  //     if (!JSONdata.data[projectUUID]?.created_at) return;

  //     let curret_time = new Date();
  //     setProjectJSON(JSONdata.data[projectUUID]);
  //     let projectData = JSONdata.data[projectUUID]
  //     let created_at = new Date(projectData.created_at + ' GMT');
  //     setLeftTime(created_at - curret_time + 3600 * 48 * 1000);

  //     let image_url = '';
  //     let thumbnail = '';
  //     let asset_uuid = '';
  //     let thumbnail_Keys = 0;
  //     let tempJSON = JSONdata.data[projectUUID];
  //     let videoKeys = Object.keys(tempJSON?.videos ? tempJSON?.videos : {})
  //     if (videoKeys.length > 0) asset_uuid = tempJSON.videos[videoKeys[0]].asset_uuid;
  //     if (!!asset_uuid) thumbnail = tempJSON?.asset[asset_uuid].thumbnail;
  //     if (!!thumbnail) thumbnail_Keys = Object.keys(thumbnail);
  //     if (thumbnail_Keys.length > 0) image_url = thumbnail[thumbnail_Keys[0]].signed_url;

  //     if (!!image_url) {
  //       let blob = await fetch(image_url).then(r => r.blob());
  //       let image_file = new File([blob], "image.png", { type: 'image/png', lastModified: new Date().getTime() });
  //       let reader = new FileReader();

  //       reader.onloadend = function () {
  //         setImage(reader.result);
  //       }

  //       reader.readAsDataURL(image_file);
  //     } else {
  //       let defaultImg = 'https://www.messagetech.com/wp-content/themes/ml_mti/images/no-image.jpg'
  //       setImage(defaultImg)
  //     }
  //   } catch (err) {
  //     console.log(err.message)
  //   }
  // }

  let [showTime, setShowTime] = useState([1970, 0, 1, 0, 0, 0])
  const [time_, setTime_] = useState(+new Date());
  useEffect(() => {
    const currentT = new Date();
    let handler = setTimeout(async () => {
      setTime_(currentT.getTime());
      let passTime = + new Date() - currentT.getTime();

      let newLeftTime = leftTime - passTime;
      if (newLeftTime > 0) setShowTime(moment(new Date(newLeftTime).toGMTString())._a)
      else setShowTime([1970, 0, 1, 0, 0, 0]);
      if (leftTime > 0) setLeftTime(newLeftTime);
    }, 1000)
    return () => handler && clearTimeout(handler);
  }, [time_]);

  let floorHour = showTime[4] > 0 ? 1 : showTime[5] > 0 ? 1 : 0;
  let leftHours = (showTime[2] - 1) * 24 + showTime[3] + floorHour;

  const getDate = () => {
    let date = new Date();
    const day = date.getDate();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const str = `${day}/${month}/${year}`;

    return str;
  }

  const handleClickRemoveWatermark = () => {
    setShowPricingPageModal(true)
  }

  const handleClosePricingModal = () => {
    setShowPricingPageModal(false)
  }

  return (
    <>
      <Box className="project-view-wrapper">
        <Box component='section' className="view-body-section">
          <Box className="header-section">
            <Box className="header-left">
              <Link to={'..'} className="home-icons">
                <HomeIcon />
                <Box component='span' />
                <img src={Logo} alt='logo' />
              </Link>

            </Box>

            <Box className="header-right">
              {!loginState && (
                <NavLink to={`/signin/?project_uuid=${projectUUID}`} className="login-btn">{languageStrs.login}</NavLink>
              )}

              <Box className="share-btn" onClick={copyLinkFunc}>{languageStrs.share} <ShareIcon /></Box>
            </Box>
          </Box>

          <Box className="video-section">
            <Box className="video-container">
              <video controls ref={videoRef}/>
              
            </Box>
          </Box>

          <Box className="footer-section">
            <Box className="footer-contain">
              {!!loginState && (
                <Typography component='h2' sx={{ textTransform: 'uppercase' }}>
                  {(userData?.username || '').slice(0, 1)}
                </Typography>
              )}

              <Box component='span'>
                <Typography component='h3'>{!!userData?.username ? userData?.username : 'Anonymous'}</Typography>
                <Box className="content">
                  <Typography component='h5'><LanguageOutlinedIcon /> {languageStrs.unlisted_video}</Typography>
                  <Typography component='h6'>{getDate()}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box component='section' className="view-sidebar-section">
          <Box className="sidebar-header-contain">
            <Box className="header-background" />
            <img src={ExportLogo} className="m-auto" alt="export logo" />
            <Typography component='h5'>{languageStrs.your_video_about_ready}</Typography>
          </Box>

          {/* <Box component='ul' className="sidebar-link-contain">
            <Box component='li' className="link-item" onClick={copyLinkFunc}>
              <Box component='span' style={{ backgroundColor: '#fff5eb', color: '#ff9e0d' }}><LinkIcon /></Box>
              <Typography component='h2'>Copy Link</Typography>
            </Box>

            <FacebookShareButton disabled={shareDisable}
              disabledStyle={{ opacity: 0.6 }}
              quote={projectJSON?.title}
              url={image}
              hashtag=''
            >
              <Box component='li' className="link-item">
                <Box component='span' style={{ backgroundColor: '#eaf2fe', color: '#237eef' }}><FacebookIcon /></Box>
                <Typography component='h2'>Facebook</Typography>
              </Box>
            </FacebookShareButton>

            <TwitterShareButton disabled={shareDisable}
              disabledStyle={{ opacity: 0.6 }}
              title={projectJSON?.title}
              url={image}
              hashtags={[]}
              related={[]}
              via=''
            >
              <Box component='li' className="link-item">
                <Box component='span' style={{ backgroundColor: '#ecf5fe', color: '#1fa0f2' }}><TwitterIcon /></Box>
                <Typography component='h2'>Twitter</Typography>
              </Box>
            </TwitterShareButton>

            <EmailShareButton disabled={shareDisable}
              disabledStyle={{ opacity: 0.6 }}
              subject={projectJSON?.title}
              url={image}
              separator=''
              body=''
            >
              <Box component='li' className="link-item">
                <Box component='span' style={{ backgroundColor: '#fff1ec', color: '#ff7434' }}><EmailIcon /></Box>
                <Typography component='h2'>Email</Typography>
              </Box>
            </EmailShareButton>

            <LinkedinShareButton disabled={shareDisable}
              disabledStyle={{ opacity: 0.6 }}
              title={projectJSON?.title}
              url={image}
              summary=''
              source=''
            >
              <Box component='li' className="link-item">
                <Box component='span' style={{ backgroundColor: '#eaf2fe', color: '#237eef' }}><LinkedinIcon /></Box>
                <Typography component='h2'>Linkedin</Typography>
              </Box>
            </LinkedinShareButton>
          </Box> */}

          {!loginState && (
            <Box className="sidebar-content-section">
              <Box className="content-body-contain">
                <Box className="time-count-section">
                  <Box component='div'>
                    <Typography component='h3'>{(showTime[2] - 1) * 24 + showTime[3]}</Typography>
                    <Typography component='h4'>{languageStrs.hours}</Typography>
                  </Box>

                  <Box component='span' />
                  <Box component='div'>
                    <Typography component='h3'>{showTime[4]}</Typography>
                    <Typography component='h4'>{languageStrs.minutes}</Typography>
                  </Box>

                  <Box component='span' />
                  <Box component='div'>
                    <Typography component='h3'>{showTime[5]}</Typography>
                    <Typography component='h4'>{languageStrs.minutes}</Typography>
                  </Box>
                </Box>

                {/* <Box className="content-section">
                  {leftHours > 0 ? (<>
                    <Typography component='h2'>Your video will be deleted in {leftHours} hours. </Typography>
                    <Typography component='h3'>Sign up now to save your video.</Typography>
                  </>) : (
                    <Typography component='h3'>your project deleted</Typography>
                  )}
                </Box> */}

                {!loginState && (
                  <NavLink className="signUp-btn" to={`/signup/?project_uuid=${projectUUID}`}>{languageStrs.sign_up}</NavLink>
                )}
              </Box>

              <Box className="content-footer-contain" onClick={() => { setOpenHelper(!openHelper); }}>
                <Box component='button'>
                  <Typography component='h2'>{languageStrs.want_free_stuff}</Typography>
                  {openHelper ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                </Box>

                {openHelper && <Box component='ul' className="helper-contain">
                  <Box component='li'>{languageStrs.record_screen_webcam_both}</Box>
                  <Box component='li'>{languageStrs.add_subtitle_your_video}</Box>
                  <Box component='li'>{languageStrs.create_your_team_workspace}</Box>
                  <Box component='li'>{languageStrs.collaborate_videos}</Box>
                  <Box component='li'>{languageStrs.generate_transcript}</Box>
                  <Box component='span'>{languageStrs.singup_get_all_this_more}</Box>
                </Box>}
              </Box>
            </Box>
          )}

          <Box className="sidebar-button-group">
            {videoDownloading && (
              <Box component='button' style={{display: 'block'}}>
                <Box className="flex" style={{flexDirection: 'row-reverse', justifyContent: "space-between"}}>
                <Typography component='h2'>{progressVal}%</Typography>
                <Typography component='h2'>{languageStrs.downloading}... </Typography>
                </Box>
                
                <Box className="w-full">
                  <LinearProgress variant="determinate" value={progressVal} />
                </Box>
              </Box>
            )}
            {!videoDownloading && (<Box component='button' onClick={downloadFunc}>
              <FileDownloadOutlinedIcon />
              <Typography component='h2'>{languageStrs.download}</Typography>
            </Box>)}

            <a href={`${EDIT_FRONTEND_URL}/${projectUUID}`} style={{ textDecoration: 'none' }}>
              <Box component='button'><CutIcon /><Typography component='h2'>{languageStrs.reedit_video}</Typography></Box>
            </a>
          </Box>


          {!loginState && (
            <NavLink to={`/signin/?project_uuid=${projectUUID}`} className="watermark-btn">
              <LightiningIcon />
              <Typography component='h2'>{languageStrs.remove_watermark}</Typography>
            </NavLink>
          )}

          {!!loginState && (
            <Box className="watermark-btn" onClick={handleClickRemoveWatermark}>
              <LightiningIcon />
              <Typography component='h2'>{languageStrs.remove_watermark}</Typography>
            </Box>
          )}

          {!loginState && (
            <Box component='footer' className="sidebar-footer">
              <Typography component='h5'>
                {languageStrs.already_have_account}
                <NavLink to={`/signin/?project_uuid=${projectUUID}`} className='cursor-pointer ml-10'>{languageStrs.login}</NavLink>
              </Typography>
            </Box>
          )}
        </Box>

        <Snackbar
          open={opanAlert}
          onClose={() => { setOpenAlert(false); }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
        >
          <Alert variant="filled" severity="success" sx={{ width: '250px' }}>{languageStrs.link_copied}</Alert>
        </Snackbar>
      </Box >
      <PricingModal open={showPricingPageModal} onCloseModal={handleClosePricingModal} />
    </>
  )
}