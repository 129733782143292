import ReactLoading from 'react-loading';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, Stack, ImageList, ImageListItem } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';

import './templates.scss';
import { LightiningIcon } from "../../../../Icons";
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import emtpyThumb from '../../../../assets/image/emptyThumb.jpg'
import { TemplatesHeader } from './templateHeader';
import { getTemplates } from './../../../../axios/ApiProvider';
import { GetProjectLink } from './../../../../axios/ApiProvider';
import { duplicateTemplateProject } from "./../../../../axios/ApiProvider";

const EDIT_FRONTEND_URL = process.env.REACT_APP_BASE_FRONTEND_EDITOR_URL
const BASE_BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL

export const Templates = ({ page }) => {
  const languageStrs = useSelector((store) => store.editdata.languageStrs);
  const resSm = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const resMd = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const resLg = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  let imageCount = 1
  if (resLg) imageCount = 4
  else if (resMd) imageCount = 3
  else if (resSm) imageCount = 2

  const [isLoading, SetIsLoading] = useState(true);
  const [nextPageUrl, SetNextPageUrl] = useState(null);
  const [pretPageUrl, SetPretPageUrl] = useState(null);
  const [TempArr, setTempArr] = useState([]);


  useEffect(() => {
    if(page == 'custometemplates'){
      getTemplatesItem(BASE_BACKEND_URL + '/templateproject/?visibility='+1);
    }else{
      getTemplatesItem(BASE_BACKEND_URL + '/templateproject/?visibility='+0);
    }
    
  }, [page])

  const getTemplatesItem = async (url) => {
    SetIsLoading(false);
    let result = await getTemplates(url);

    if (result.state === 'success') {
      setTempArr(result.data.results);
      SetNextPageUrl(result.data.next);
      SetPretPageUrl(result.data.previous);
    } else {
      setTempArr([]);
      SetNextPageUrl(null);
      SetPretPageUrl(null);
    }

    SetIsLoading(true);
  }

  const nextPage = () => { getTemplatesItem(nextPageUrl); }
  const prePage = () => { getTemplatesItem(pretPageUrl); }

  const createNewProject = async () => {
    let result = await GetProjectLink();
    if (!result || result.state == 'error') window.toastr.error(result.data.message);
    else if (!!Object.keys(result.data)[0]) {
      window.location.href = `${EDIT_FRONTEND_URL}/${Object.keys(result.data)[0]}?from=new`
    }
  }

  const [state, setState] = useState(false)
  const templateOnClick = async (info) => {
    let uuid = info?.uuid
    if (!uuid || state) return
    setState(true)

    let result = await duplicateTemplateProject(uuid)
    if (result.state !== 'success') {
      setState(true)
      return
    }

    let data = result.data
    let duplicateUUID = data['Duplicated Project uuid']
    window.location.href = `${EDIT_FRONTEND_URL}/${duplicateUUID}/`
  }

  const CalcuDifferentDay = (day) => {
    let day1 = new Date();
    let day2 = new Date(day);
    let dif_day = day1.getTime() - day2.getTime();
    let TotalDays = Math.ceil(dif_day / (1000 * 3600 * 24));
    return TotalDays ? TotalDays : '0';
  }

  return (
    
    <Box className="custom-template-wrapper pt-[45px]">
        <Box className='templates-next-preview-btn'>
        <Stack direction="row" spacing={2}>
          <Button size='small' onClick={prePage} variant="outlined" startIcon={<SkipPreviousIcon />} disabled={!!pretPageUrl ? false : true}>
            {languageStrs.previous}
          </Button>

          <Button size='small' onClick={nextPage} variant="outlined" endIcon={<SkipNextIcon />} disabled={!!nextPageUrl ? false : true}>
            {languageStrs.next}
          </Button>
        </Stack>
      </Box>
      <div className="relative flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
        {!!isLoading && (
          <>
            <Box className="custom-templates-body-wrapper">
              <Box className="custom-templates-body">
                <ImageList gap={10} variant="masonry" cols={imageCount}>
                  {TempArr.map((item, index) => (
                    <ImageListItem key={index}
                      className="template-item w-full"
                      onClick={() => { templateOnClick(item) }}
                    >
                      <Box className="template-item-image">
                        {!item?.project_thumbnail && (
                          <img alt='' src={emtpyThumb} />
                        )}

                        {!!item?.project_thumbnail && (
                          <img alt='' src={item.project_thumbnail} />
                        )}

                        {!!item?.project_thumbnail && (
                          <img alt='' src={emtpyThumb}
                            className='invisible'
                          />
                        )}

                        <Box className="templateTitle-ThumbnailImageBackgroundOverlay" />
                      </Box>
                      <Box className="">
                        <h1 className="TileInfoWrapper-header">{item.title}</h1>
                      </Box>
                    </ImageListItem >
                  ))}
                </ImageList>

              </Box>
            </Box>
          </>
        )}

        {!isLoading && (
          <Box className='absolute w-full h-full flex flex-col items-center justify-center'>
            <ReactLoading type={'spinningBubbles'} color='rgb(53 126 221)' height={100} width={100} />
          </Box>
        )}
      </div>

      {/* {!isLoading && (
        <Box className='templates-loading'>
          <ReactLoading type={'spinningBubbles'} color='rgb(53 126 221)' height={100} width={100} />
        </Box>
      )} */}
       
    </Box >
  )
}