import './videoocareer.scss'
import { Layouts } from '../../component/layouts/layouts'

export const Career4 = () => {
  return (
    <Layouts>
      <div className='flex flex-col items-center justify-center px-10 sm:px-20 md:px-30 lg:px-40'>
        <div className='w-full max-w-1000 flex flex-col gap-10 md:gap-20 lg:gap-30 py-30'>
          <div className='text-center text-20 md:text-30 lg:text-40 font-semibold'>
            Cloud DevOps Engineer
          </div>
          <div className='flex flex-col gap-10 mm:gap-20'>
            <div className='text-15 md:text-18'>
              We are seeking a highly skilled and motivated Cloud DevOps Engineer to join our team. As a Cloud DevOps Engineer, you will be responsible for designing, implementing, and maintaining our cloud infrastructure and CI/CD pipelines. You will work closely with our development and operations teams to ensure efficient and reliable deployment and operation of our applications.

              This is an exciting opportunity to work with cutting-edge cloud technologies and contribute to the scalability and reliability of our software solutions. You will have the chance to collaborate with a talented team and leverage your expertise in cloud infrastructure and DevOps practices to drive continuous improvement and innovation.
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Responsibilities:</span>
              <div className='text-15 md:text-18'>
                - Design, deploy, and manage cloud infrastructure using platforms such as AWS, Azure, or Google Cloud
              </div>
              <div className='text-15 md:text-18'>
                - Implement and automate CI/CD pipelines for efficient software delivery and deployment
              </div>
              <div className='text-15 md:text-18'>
                - Monitor and optimize cloud infrastructure performance, security, and cost
              </div>
              <div className='text-15 md:text-18'>
                - Collaborate with development and operations teams to define infrastructure requirements and best practices
              </div>
              <div className='text-15 md:text-18'>
                - Troubleshoot and resolve issues related to cloud infrastructure and deployment
              </div>
              <div className='text-15 md:text-18'>
                - Stay up-to-date with the latest cloud technologies, trends, and best practices
              </div>
              <div className='text-15 md:text-18'>
                - Assist with other DevOps-related tasks as needed
              </div>
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Qualifications:</span>
              <div className='text-15 md:text-18'>
                - Bachelor's degree in Computer Science, Engineering, or a related field
              </div>
              <div className='text-15 md:text-18'>
                - Strong experience with cloud platforms such as AWS, Azure, or Google Cloud
              </div>
              <div className='text-15 md:text-18'>
                - Proficiency in infrastructure-as-code tools such as Terraform or CloudFormation
              </div>
              <div className='text-15 md:text-18'>
                - Solid understanding of CI/CD concepts and experience with tools like Jenkins or GitLab CI/CD
              </div>
              <div className='text-15 md:text-18'>
                - Knowledge of containerization technologies like Docker and orchestration tools like Kubernetes
              </div>
              <div className='text-15 md:text-18'>
                - Strong scripting and automation skills using tools like Bash, Python, or PowerShell
              </div>
              <div className='text-15 md:text-18'>
                - Familiarity with monitoring and logging tools such as Prometheus, Grafana, or ELK Stack
              </div>
              <div className='text-15 md:text-18'>
                - Excellent problem-solving and troubleshooting abilities
              </div>
              <div className='text-15 md:text-18'>
                - Strong communication and collaboration skills
              </div>
            </div>

            <div className='text-15 md:text-18'>
              If you are a passionate and experienced Cloud DevOps Engineer looking for a challenging role in a fast-paced and innovative environment, we would love to hear from you. Join our team and play a key role in driving the scalability, reliability, and efficiency of our cloud infrastructure.

              To apply, please submit your resume to info@videoo.io. Please include "Cloud DevOps Engineer Application" in the subject line. Only shortlisted candidates will be contacted.

              We look forward to reviewing your application!
            </div>
          </div>
        </div>
      </div>
    </Layouts>

  )
}