import './videoocareer.scss'
import { Layouts } from '../../component/layouts/layouts'

export const Career10 = () => {
  return (
    <Layouts>
      <div className='flex flex-col items-center justify-center px-10 sm:px-20 md:px-30 lg:px-40'>
        <div className='w-full max-w-1000 flex flex-col gap-10 md:gap-20 lg:gap-30 py-30'>
          <div className='text-center text-20 md:text-30 lg:text-40 font-semibold'>
            Video Producer for Online Video Editor
          </div>
          <div className='flex flex-col gap-10 mm:gap-20'>
            <div className='text-15 md:text-18'>
              We are seeking a skilled and creative Video Producer to join our team as a key contributor to our Online Video Editor. As a Video Producer, you will be responsible for overseeing the production of video content and ensuring the highest quality of videos created using our online video editing platform. You will collaborate with our design and development teams to enhance the user experience and drive engagement through captivating and impactful video content.

              This is an exciting opportunity to work in a fast-paced and dynamic environment, shaping the future of online video editing and enabling users to create professional-level videos easily. If you have a passion for video production, a keen eye for detail, and a desire to innovate in the digital media space, we would love to have you on board.
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Responsibilities:</span>
              <div className='text-15 md:text-18'>
                - Collaborate with the design and development teams to define and execute the video production strategy for our Online Video Editor
              </div>
              <div className='text-15 md:text-18'>
                - Conceptualize and develop creative video ideas that align with our brand and target audience
              </div>
              <div className='text-15 md:text-18'>
                - Plan, coordinate, and oversee video shoots, including managing talent, props, locations, and equipment
              </div>
              <div className='text-15 md:text-18'>
                - Direct and guide video production teams, including videographers, editors, and animators, to ensure the successful execution of projects
              </div>
              <div className='text-15 md:text-18'>
                - Edit and enhance raw footage, applying creative storytelling techniques and incorporating graphics, effects, and sound elements
              </div>
              <div className='text-15 md:text-18'>
                - Ensure videos meet quality standards, brand guidelines, and project requirements
              </div>
              <div className='text-15 md:text-18'>
                - Stay up-to-date with industry trends, video production techniques, and emerging technologies to continuously improve our video editing capabilities
              </div>
              <div className='text-15 md:text-18'>
                - Collaborate with marketing teams to create compelling video content for promotional campaigns and social media platforms
              </div>
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Qualifications:</span>
              <div className='text-15 md:text-18'>
                - Bachelor's degree in Film Production, Media Studies, or a related field
              </div>
              <div className='text-15 md:text-18'>
                - Proven experience as a Video Producer, ideally in a digital media or video production environment
              </div>
              <div className='text-15 md:text-18'>
                - Proficiency in video editing software, such as Adobe Premiere Pro or Final Cut Pro
              </div>
              <div className='text-15 md:text-18'>
                - Strong understanding of video production techniques, including camera operation, lighting, sound, and post-production processes
              </div>
              <div className='text-15 md:text-18'>
                - Knowledge of motion graphics and animation principles
              </div>
              <div className='text-15 md:text-18'>
                - Excellent storytelling and creative skills, with a keen eye for detail
              </div>
              <div className='text-15 md:text-18'>
                - Ability to manage multiple projects simultaneously and meet tight deadlines
              </div>
              <div className='text-15 md:text-18'>
                - Strong communication and collaboration skills, with the ability to work effectively in a team environment
              </div>
              <div className='text-15 md:text-18'>
                - Familiarity with online video platforms and social media trends
              </div>
              <div className='text-15 md:text-18'>
                - Portfolio showcasing your video production work and creativity
              </div>
            </div>

            <div className='text-15 md:text-18'>
              If you are a passionate and talented Video Producer who thrives in a dynamic and creative environment, we would love to hear from you. Join our team and contribute to revolutionizing the way people create and share videos online.

              To apply, please submit your resume, portfolio, and a brief cover letter to info@videoo.io. Please include "Video Producer Application" in the subject line. Only shortlisted candidates will be contacted.

              We look forward to reviewing your application!
            </div>
          </div>
        </div>
      </div>
    </Layouts>
  )
}