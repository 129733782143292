import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom';
import {Box, Menu, MenuItem} from "@mui/material"
import MoreVertIcon from "@mui/icons-material/MoreVert"

import { deleteAsset } from '../../../../axios/ApiProvider'
import emtpyThumb from '../../../../assets/image/emptyThumb.jpg'


const GridItem = ({ itemData, onDelete }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useNavigate();
  const open = Boolean(anchorEl);
  const handleClickMore = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickDeleteAsset = async () => {
    const result = await deleteAsset(itemData.uuid)
    handleClose()
    onDelete()
    if (result.state !== 'success') {
      return window.toastr.error(result.data.message)
    }
  }

  const CalcuDifferentDay = (day) => {
    let day1 = new Date();
    let day2 = new Date(day);
    let dif_day = day1.getTime() - day2.getTime();
    let TotalDays = Math.ceil(dif_day / (1000 * 3600 * 24));
    let Totalhours = Math.ceil(dif_day / (1000 * 3600));
    let Totalmins = Math.ceil(dif_day / (1000 * 60));
    let Totalsecs = Math.ceil(dif_day / (1000));
    console.log(TotalDays, Totalhours, Totalmins, Totalsecs);
    return TotalDays > 0 ? TotalDays+' days ago' : Totalhours > 0? Totalhours+' hour ago' : Totalmins > 0? Totalmins + ' mins ago' : Totalsecs + ' sec ago' ;
  }

  const handleClickItem = (e) => {
    e.preventDefault();
    if(itemData.project_uuid) {
      history(`/asset/view/${itemData.uuid}/${itemData.project_uuid}`);
    } else {
      history(`/asset/view/${itemData.uuid}`);
    }
  }

  return (
    <Box className="project-list-item">
      <a href="#!" onClick={handleClickItem} className="project-listItem-cell1">
        {itemData?.export_thumbnail && (
          <img src={itemData.export_thumbnail} alt='projct-thumbnail' />
        )}

        {!itemData?.export_thumbnail && (
          <img src={emtpyThumb}
            alt='project-thumbnail'
          />
        )}
        <Box className="listItem-cell1-text">
          <Box className="listItem-cell1-header">{itemData.title}</Box>

          <Box className="listItem-cell1-body-contain">
            <Box className="listItem-cell1-body">
              Draft • {CalcuDifferentDay(itemData.updated_at)}
            </Box>
          </Box>
        </Box>
      </a>

      <Box className="project-listItem-cell2">
        <span className="listItem-cell2-text">Draft</span>
      </Box>

      <Box className="project-listItem-cell3">
        <span className="listItem-cell3-text">{CalcuDifferentDay(itemData.updated_at)}</span>
      </Box>

      <Box className="project-listItem-cell4" onClick={handleClickMore}>
        <MoreVertIcon />
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClickDeleteAsset}>Delete Export</MenuItem>
      </Menu>
    </Box>
  )
}

export default GridItem
