import { Box } from '@mui/system';

import './ai-video-editor.scss';
import UploadImg from './../../../assets/image/tools/upload-img.png';
import facebookLogo from "./../../../assets/image/Logos/facebook_logo.png";
import instagramLogo from "./../../../assets/image/Logos/instagram_logo.png";
import tiktokLogo from "./../../../assets/image/Logos/tiktok_logo.png";
import twitterLogo from "./../../../assets/image/Logos/twitter_logo.png";
import youtubeLogo from "./../../../assets/image/Logos/youtube_logo.png";
import whatsappLogo from "./../../../assets/image/Logos/whatsapp_logo.png";

import { createNewProject } from '../VideoEditorHelper';
import { Footer } from '../../../component/home-footer/footer';
import { Header } from '../../../component/home-header/header';

export const BASE_STATIC_STORAGE_URL = process.env.REACT_APP_STATIC_STORAGE_URL;
export const how_to_1   = BASE_STATIC_STORAGE_URL + '/images/features/Add Free Stock Videos.png';
export const how_to_2   = BASE_STATIC_STORAGE_URL + '/images/features/add_more_files.jpg';
export const how_to_3   = BASE_STATIC_STORAGE_URL + '/images/features/Export Stock.png';

export const AIVideoEditor = () => {
  return (
    <Box className="T_containner">
      <Box className="Header"><Header /></Box>

      <Box className="content-containner">
      <>
      <Box className='section-15'>
        <Box className='div-1232 _1300'>
          <Box className='breadcrumbs'>
            <a href={'/'}>
              <Box className='breadcrumb-text-block'>Videoo</Box>
            </a>
            <Box className='breadcrumb-arrow'></Box>
            <a href={'/'}>
              <Box className='breadcrumb-text-block'>Online Video Editor</Box>
            </a>
            <Box className='breadcrumb-arrow'></Box>
            <Box className='breadcrumb-text-block'>AI Video Editor</Box>
          </Box>
        </Box>
      </Box>

      <Box className='section-9 directory std-hero-section'>
        <Box className='div-1232'>
          <Box className='hero-grid'>
            <Box className='hero-grid-text'>
              <Box className='hero-header-title'>AI Video Editor</Box>
              <Box className='hero-content-title'>Free online AI video editing software - create videos online</Box>

              <Box onClick={() => {
                createNewProject("feature-add-free-stock-video")
              }} className='choose-btn'>
                <Box className='choose-btn-text'>Create Project</Box>
                <Box className='choose-btn-img'>
                  <img src={UploadImg} alt='uploadImg' />
                </Box>
              </Box>
            </Box>
            <Box className='hero-img'>
              <iframe
                  src={"https://www.youtube.com/embed/StOapicMq_s"}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="How to Add Stock Videos | using Videoo.io" frameborder="0" 
                  style={{margin: 'auto', width: '100%', aspectRatio: 1.7}}
              />

            </Box>
          </Box>

          {/* <Box className="logos-grid">
              <Box className="usedByLogos-item">
                  <img src={instagramLogo} alt="instagram logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={youtubeLogo} alt="youtube logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={facebookLogo} alt="facebook logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={twitterLogo} alt="twitter logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={tiktokLogo} alt="tiktok logo" width={100} />
              </Box>
              <Box className="usedByLogos-item">
                  <img src={whatsappLogo} alt="whatsapp logo" width={100} />
              </Box>
              {{usedLogs.map((logo, index) => (
                  <Box className="usedByLogos-item" key={index}>{logo}</Box>
              ))}}
          </Box> */}
        </Box>
      </Box>

      <Box className='wf-section'>
        <Box className='div-1232'>
          <Box className='div-block-67'>
            <h2 className="heading-19">Add Free AI Video Editor Features</h2>
            <Box className='text-section-block'>
              <ul className='text-section-ul'>
                <li className='py-10'>
                  Videoo.io emerges as a dynamic and user-friendly online video editing service, revolutionizing the way we create videos by integrating advanced AI capabilities. This innovative platform caters to both professionals and hobbyists, making it easier than ever to access high-quality editing tools for any project. With its seamless integration of AI technology, Videoo.io offers a comprehensive suite of features, including text to video, auto-subtitles, voice translations, and AI-generated images and voices, enabling creators to enhance their videos effortlessly. This synergy between Videoo.io and cutting-edge AI opens up endless creative possibilities, making it an invaluable tool for anyone looking to bring their video projects to life with ease and flair.
                </li>
                <li className='py-10'>
                  Start a new video project or open an existing one where you want to use AI features.
                </li>
                <li className='py-10'>
                  Look for the option to add new assets or apply AI tools to your project. This will typically be a menu or button that allows you to add various types of media, including text, audio, images, and AI-generated content.
                </li>
                <li className='py-10'>
                  Browse through the available AI tools. Videoo.io offers a variety of AI-powered features that you can use for free. Select the ones that best suit your project.
                </li>
                <li className='py-10'>  
                  After choosing the AI tools, you can edit your project according to your needs. This can include generating text-based videos, adding auto-subtitles, translating voiceovers, and incorporating AI-generated images and voices.
                </li>
                <li className='py-10'>  
                  Once you're satisfied with how the AI features integrate into your project, you can finalize your edits and save or export your video.
                </li>
              </ul>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className='wf-section'>
        <Box className='div-1232 wide _3'>
          <Box className='div-block-70'>
            <Box className='div-block-69'>
              <h2 className="heading-20">How to Add a Free Stock Videos</h2>
            </Box>

            <Box className='div-block-68'>
              <Box className='how-to-div-item'>
                <img src={how_to_1} alt='howTo1' className="how-to-img" />
                <h3 className="heading-21 m-y-0">Create or Select a Project</h3>
                <p className="how-to-content">Visit Videoo.io and start a new video project or open an existing one to enhance with stock videos.</p>
              </Box>

              <Box className='how-to-div-item'>
                <img src={how_to_2} alt='howTo2' className="how-to-img" />
                <h3 className="heading-21 m-y-0">Add Free Stock Videos</h3>
                <p className="how-to-content">Use the feature to add new assets and explore Pexels’ free stock video catalogue integrated within Videoo.io.</p>
              </Box>

              <Box className='how-to-div-item'>
                <img src={how_to_3} alt='howTo3' className="how-to-img" />
                <h3 className="heading-21 m-y-0">Share!</h3>
                <p className="how-to-content">Select and add your chosen stock videos to the project, then edit as needed to fit your video’s theme and style.</p>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
      </Box>

      <Box className="footer-containner"><Footer /></Box>
    </Box >
    
  )
}