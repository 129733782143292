import { GetProjectLink, CreateAssets, UploadAssets, UploadVideo, SubtitleVideo } from "../../axios/ApiProvider"
const EDIT_FRONTEND_URL = process.env.REACT_APP_BASE_FRONTEND_EDITOR_URL
export const uploadAction = async (result, file) => {
	let data = result.data;
	let project_uuid = '';
	for (const i in data) project_uuid = i;
	let CAresult = await CreateAssets(project_uuid, 1);

	if (!CAresult || CAresult.state === 'error') window.toastr.error(CAresult.data.message);
	else {
		let asset_uuid = CAresult.data.uuid;
		let assetResult = await UploadAssets(project_uuid, asset_uuid);

		if (!assetResult || assetResult.state === 'error') window.toastr.error(assetResult.data.message);
		else {
			if (assetResult.data && assetResult.data.code) window.toastr.error(assetResult.data.code);
			else if (assetResult.data && assetResult.data.uuid === asset_uuid) {
				let signedurl = assetResult.data.signed_url;
				let uploadresult = await UploadVideo(file, signedurl);
				let subtitleresult = await SubtitleVideo(asset_uuid, project_uuid);
			} else window.toastr.error('access denied');
		}
	}
}

export const createNewProject = async () => {
    let result = await GetProjectLink();
    if (!result || result.state == 'error') window.toastr.error(result.data.message);
    else if (!!Object.keys(result.data)[0]) window.location.href = `${EDIT_FRONTEND_URL}/${Object.keys(result.data)[0]}`
  }