import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from '@mui/material';

import './../Home/home.scss';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { Header } from '../../component/home-header/header';
import { Footer } from '../../component/home-footer/footer';

import { GetProjectLink } from './../../axios/ApiProvider';
const EDIT_FRONTEND_URL = process.env.REACT_APP_BASE_FRONTEND_EDITOR_URL
export const App = () => {
	const history = useNavigate();
	const [projectUUID, setProjectUUID] = useState('');
	if (projectUUID) history(`${EDIT_FRONTEND_URL}/${projectUUID}`);

	const redirectToEdit = async () => {
		let result = await GetProjectLink();
		let updatedProjectUUID;
		if (!result || result.state === 'error') window.toastr.error(result.data.message);
		else {
			updatedProjectUUID = Object.keys(result.data)[0];
			setProjectUUID(updatedProjectUUID);
		}
	};

	return (
		<Box className="T_containner">
			<Box className="Header"><Header /></Box>

			<Box className="content-containner">
				<Box className="h-section top-section">
					<Box className="h-section-container">
						<h1 className="hs1-Header">
							Video editing
							<span className="text-span-3"> made simple</span>
						</h1>

						<Box className="hs1-content">Create videos with a single click. Add subtitles, transcribe audio and more</Box>

						<Box className="hs1-content2">Try it now, no account required</Box>

						<Box className="main-hero-cta">
							<Box className="uploadbtn" onClick={redirectToEdit}>
								Take me to the video editor
								<br />
								(No account is required)
							</Box>
						</Box>

						<Box className="main-here-footer">
							<Box className="trysamplebtn">
								Try Sample <NavigateNextIcon />
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>

			<Box className="footer-containner"><Footer /></Box>
		</Box>
	)
}