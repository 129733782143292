import { Box, Button, Card, CardMedia, Grid, Typography } from "@mui/material";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Link } from "react-router-dom";

import './filter-to-video.scss';
import { createNewProject } from './../VideoEditorHelper';
import { Header } from '../../../component/home-header/header';
import { Footer } from '../../../component/home-footer/footer';

import { GetProjectLink } from "../../../axios/ApiProvider";

export const EDIT_FRONTEND_URL = process.env.REACT_APP_BASE_FRONTEND_EDITOR_URL;
export const BASE_STATIC_STORAGE_URL = process.env.REACT_APP_STATIC_STORAGE_URL;

const VideooEditor_AddFilter_Welcome = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddFilter-1-Welcome.png'
const VideooEditor_AddFilter_Step1 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddFilter-2-FilterOptions.png'
const VideooEditor_AddFilter_Step2 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddFilter-3-BWFilter.png'
const VideooEditor_AddFilter_Step3 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddFilter-4-InstagramFilter.png'
const VideooEditor_AddFilter_Step4 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddFilter-5-NoFilter.png'
const VideooEditor_AddFilter_Step5 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddFilter-6-Exporting.png'
const VideooEditor_AddFilter_Step6 = BASE_STATIC_STORAGE_URL + '/images/features/Videoo-AddFilter-7-ExportedVideo.png'

const VideooEditor_AddFilter_Action1 = BASE_STATIC_STORAGE_URL + '/images/features/Add Your File Filter.png';
const VideooEditor_AddFilter_Action2 = BASE_STATIC_STORAGE_URL + '/images/features/Add a Filter to a Video.png';
const VideooEditor_AddFilter_Action3 = BASE_STATIC_STORAGE_URL + '/images/features/Export Filter.png';

const VideooEditor_AddTransitions_Image = BASE_STATIC_STORAGE_URL + '/images/features/Add%20Transition%20to%20Video.png'
const VideooEditor_AddMusic_Image = BASE_STATIC_STORAGE_URL + '/images/features/Add%20Music%20to%20Video%202.png'
const VideooEditor_AddText_Image = BASE_STATIC_STORAGE_URL + '/images/features/Add%20Text%20to%20Video.png'
const VideooEditor_AddStock_Image = BASE_STATIC_STORAGE_URL + '/images/features/Export%20Stock.png'


export const FilterToVideo = () => {
    const handleCreateNewProject = async () => {
        let result = await GetProjectLink();
        if (!result || result.state == 'error') window.toastr.error(result.data.message);
        else if (!!Object.keys(result.data)[0]) window.location.href = `${EDIT_FRONTEND_URL}/${Object.keys(result.data)[0]}?from=new`
    }

    return (
        <Box className="T_containner">
          <Box className="Header"><Header /></Box>

          <Box className="content-containner">
            <Box className="section-15" sx={{marginTop: '4rem'}}> 
                <Box className="div-1232">
                    <Grid container spacing={1} justifyContent={'center'} alignItems={'center'} height={400}>
                        <Grid item xs={12} md={6}>
                            <Card className="p-6">
                                <Box className="div-1232">
                                    <Typography variant="h3" gutterBottom fontWeight={'bold'}>
                                    Filter to Your <br />Videos Online
                                    </Typography>
                                </Box>
                                <Box className="div-1232" sx={{marginBottom: '1rem'}}>
                                    <Typography variant="body1" gutterBottom >
                                     - Easily add filters to your videos with a single click using Videoo.io | Online Cloud Based Video Editor.
                                    </Typography>
                                    <Typography variant="body1" gutterBottom >
                                     - Enhance the look and feel of your videos instantly with VIDEOO’s online filtering tools. Our platform provides a variety of filters that you can apply to your videos with just a few clicks. Whether you're looking to add a vintage effect, boost colors, or create a cinematic atmosphere, our filters help you achieve the desired aesthetic effortlessly.
                                    </Typography>
                                </Box>
                                <Box className="div-1232" onClick={handleCreateNewProject}>
                                    <Button variant="contained" className="create-now" color="success">Create Now</Button>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6} height={'100%'}>
                            <Card className="p-6">
                                {/*<img src={Illustration} className="mx-auto h-full " />*/}
                                <div style={{ width: '100%', paddingTop: '56.25%', position: 'relative' }}>
                                <iframe
                                    src="https://www.youtube.com/embed/IgBUAmt4Uxc"
                                    title="Videoo.io, Online Cloud Based Video Editor"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                                ></iframe>
                                </div>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box className='section-15 pt-12'>
              <Box className='div-1232'>
                <Box className='breadcrumbs'>
                  <a href={'/'}>
                    <Box className='breadcrumb-text-block'>Videoo</Box>
                  </a>
                  <Box className='breadcrumb-arrow'></Box>
                  <a href={'/'}>
                    <Box className='breadcrumb-text-block'>Video Editor</Box>
                  </a>
                  <Box className='breadcrumb-arrow'></Box>
                  <a href={'/'}>
                    <Box className='breadcrumb-text-block'>Add Image to Video</Box>
                  </a>
                </Box>
              </Box>
            </Box>
            <Box className="section-9 directory std-hero-section" sx={{paddingY: '3rem'}}>
              <Box className="div-1232">
                <Box className="mx-auto text-center" sx={{width: '80%'}}>
                  <Typography variant="h3" gutterBottom fontWeight={600} color={'#4D4D4D'}>Add Filter To Your Video using Online Video editor</Typography>
                </Box>
                <Box sx={{textAlign: 'center', marginBottom: '3rem'}}>
                  <Typography variant="body1" gutterBottom >Easily Add Filters to your Video with a single click</Typography>
                </Box>
                <Box className="container">
                  <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                    <Grid item md={6} xs={12} >
                      <img width={'100%'} src={VideooEditor_AddFilter_Welcome} />
                    </Grid>
                    <Grid item md={6} xs={12} >
                      <Box sx={{paddingBottom: '0.5rem'}}>
                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>With VIDEOO's wide range of Filters, you can make a vivid touch to your videos</Typography>
                      </Box>
                      <Box sx={{paddingBottom: '1rem'}}>
                      <Typography variant="body1" gutterBottom>
                        <strong>Wide Range of Filters:</strong> Choose from a diverse selection of filters designed to enhance your video’s visual appeal. From classic black and white to vibrant color boosts, there's a filter for every style.
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        <strong>Real-Time Preview:</strong> See the impact of each filter in real-time. Adjust and compare different filters to find the perfect match for your video before applying the final effect.
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        <strong>Easy Application:</strong> Applying filters is straightforward and user-friendly. Simply select your desired filter and apply it to your video with a single click, saving time and effort.
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        With VIDEOO’s online filtering tools, transforming the visual style of your videos is quick and easy. Enhance your content, captivate your audience, and make every video stand out with professional-quality filters.
                      </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>

            <Box className="section-9 directory std-hero-section" >
              <Box className="div-1232" >
                <Box className="text-center">
                <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>How to Add a Color Filter to a Video, a step-by-step Guide</Typography>
                </Box>
                <Box className="text-center" sx={{marginBottom: '3rem'}}>
                  <Typography variant="body1" gutterBottom >You can use Videoo.io to enhance your creativity by adding custom, Auto Subtitles or AI-generated Subtitle Translations to your video projects.</Typography>
                </Box>

                <Box className="section-9 directory std-hero-section" >
                    <Box className="div-1232">
                        <Box className="container">
                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddFilter_Step1} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 1 - Selecting Filters</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body1" gutterBottom>
                                        - Signup & Login to Videoo.io, and create either a blank or template project.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                        - Add your video content (or create a Template or Stock Content Project)
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                        - Go to Text Section on left side menu. Click on Add Text to start adding text to your video.
                                        </Typography>
                                    </Box>
                                    <Box sx={{paddingTop: '1rem'}}>
                                      <Button to="../signup" component={Link} variant="contained" className="py-10 px-33" color="success">Sign up to Videoo.io</Button>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddFilter_Step2} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 2 - Instagram Filters</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                      <Typography variant="body1" gutterBottom>
                                        - Once you have added a video or image to your video, then you can add filters to your video. VIDEOO offers a wide range of filters to choose from, allowing you to customize the style of your video to match your video's theme or branding.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                        - You can also adjust the opacity of the filter to make it more visible or blend it with the video.
                                        </Typography>
                                    </Box>
                                    <Box sx={{paddingTop: '1rem'}}>
                                        <Button to="../dashboard/templates" component={Link} variant="contained" className="py-10 px-33" color="success">Create a Template Project</Button>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddFilter_Step3} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 3 - Applying Color Filters to Image</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body1" gutterBottom>
                                         - To apply a color filter to an image, select the image and click on the filter icon in the toolbar. This will open a menu with a variety of filters to choose from.
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                         - Select the filter you want to apply to the image. You can preview the filter in real-time to see how it will look on the image.
                                        </Typography>
                                    </Box>
                                    <Box className="div-1232" onClick={handleCreateNewProject}>
                                        <Button variant="contained" className="create-now" color="success">Create a Blank Project</Button>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddFilter_Step4} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 4 - Setting No Filter</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body1" gutterBottom>
                                         - To remove a filter from an image, select the image and click on the filter icon in the toolbar. This will open a menu with a list of filters that are currently applied to the image.
                                        </Typography>
                                    </Box>
                                    <Box sx={{paddingTop: '1rem'}}>
                                        <Button to="../dashboard/stocks" component={Link} variant="contained" className="py-10 px-33" color="success">Create a Stock Project</Button>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddFilter_Step5} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 5 - Exporting a Project with Filter</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                         - Once you have applied the desired filters to your images, you can export the project with the filters included. VIDEOO offers a range of export options to suit your needs, including resolutions up to 4K and various file formats.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <img width={'100%'} src={VideooEditor_AddFilter_Step6} />
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{paddingBottom: '0.5rem'}}>
                                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Step 6 - Download or Share your Video</Typography>
                                    </Box>
                                    <Box sx={{paddingBottom: '1rem'}}>
                                        <Typography variant="body2" gutterBottom>
                                         - Once you have exported the project with the filters applied, you can download the final video to your device or share it directly to social media platforms. VIDEOO makes it easy to share your creative projects with the world.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                        </Box>
                    </Box>
                </Box>
              </Box>
            </Box>
            <Box className="section-9  std-hero-section">
              <Box className="div-1232">
                <Typography className="text-center" variant="h4" gutterBottom fontWeight={600} >Easy-to Use Add Filter to Video Features</Typography>
                <Box className="container py-15" sx={{marginTop: '2rem'}}>
                  <Grid container spacing={4}>
                    <Grid item md={6} xs={12}>
                      <Box className="mb-5">
                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>
                        Instagram Filters
                        </Typography>
                      </Box>
                      <Box className="py-10">
                        <Typography variant="body1" gutterBottom>
                          VIDEOO’s Text Editor provides an intuitive and user-friendly interface for adding and customizing text in your videos. Here’s how it simplifies the process:
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          VIDEOO’s Instagram Filters feature allows you to bring the popular and trendy look of Instagram filters to your video content. Here’s how it enhances your videos:
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Wide Range of Filters:</strong> Choose from a variety of Instagram-style filters to give your videos a unique and stylish appearance. Whether you want to add a nostalgic vintage effect, a bright and vibrant look, or a soft and muted tone, VIDEOO has the perfect filter for you.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Real-Time Preview:</strong> See the impact of each Instagram filter in real-time. This allows you to experiment with different looks and find the perfect filter for your video before applying it.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Easy Application:</strong> Applying Instagram filters is straightforward and user-friendly. Simply select your desired filter and apply it to your video with a single click, instantly transforming the visual style of your content.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Customizable Intensity:</strong> Adjust the intensity of each filter to achieve the desired effect, whether you prefer subtle enhancements or dramatic changes.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Consistent Aesthetic:</strong> Use Instagram filters to maintain a consistent aesthetic across all your video content. This is particularly useful for content creators and brands looking to create a cohesive and recognizable visual style.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          With VIDEOO’s Instagram Filters feature, enhancing your videos with popular and trendy effects is quick and easy. Bring the charm and appeal of Instagram to your video content, making it more engaging and visually stunning.
                        </Typography>
                      </Box>
                      <Box className="mx-auto text-center mt-10" onClick={handleCreateNewProject}>
                        <Button variant="contained" className="py-10 px-33" color="success">Create Video with Text Editor</Button>
                      </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Box className="mb-5">
                        <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>
                        Social Media Filters
                        </Typography>
                      </Box>
                      <Box className="py-10">
                        <Typography variant="body1" gutterBottom>
                          VIDEOO’s Social Media Filters feature allows you to enhance your videos with popular effects inspired by various social media platforms. Here’s how it can elevate your video content:
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Variety of Filters:</strong> Choose from a diverse range of social media-inspired filters that can instantly transform the look and feel of your videos. Whether you’re aiming for the vibrant and trendy styles of Instagram, the dramatic tones of TikTok, or the professional polish of LinkedIn, VIDEOO has you covered.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>One-Click Application:</strong> Applying filters is simple and efficient. With just one click, you can apply your chosen filter to your video.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Adjustable Intensity:</strong> Customize the intensity of each filter to achieve the perfect balance. Fine-tune the strength of the filter to ensure it complements your video without overpowering the original footage.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Platform-Specific Styles:</strong> Tailor your videos to match the aesthetic of specific social media platforms. This helps ensure that your content looks its best whether it’s being shared on Instagram, TikTok, Facebook, or any other platform.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Consistent Branding:</strong> Maintain a consistent visual style across all your video content. Using social media filters helps in creating a recognizable and cohesive brand aesthetic, making your videos stand out in crowded feeds.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          With VIDEOO’s Social Media Filters feature, enhancing your videos to fit the latest trends and platform-specific styles is easy and effective. Create visually appealing content that captures attention and engages viewers on any social media platform.
                        </Typography>
                      </Box>
                      <Box className="mx-auto text-center mt-10" onClick={handleCreateNewProject}>
                        <Button variant="contained" className="py-10 px-33" color="success">Create Video with Subtitles</Button>
                      </Box>
                    </Grid>

                    <Box className="container">
                      <Grid container spacing={5}>
                          <Grid item md={4} xs={12} className="text-center">
                          <Card className="p-6">
                              <Box className="text-center py-4">
                              <img className="mx-auto" src={VideooEditor_AddFilter_Action1}/>
                              </Box>
                              <Box className="text-center py-4">
                              <Typography variant="h5" gutterBottom fontWeight={600} color={'#4D4D4D'}>Add Your Content</Typography>
                              </Box>
                              <Box className="text-center py-4">
                              <Typography variant="body2" gutterBottom>
                                  - Select your video and upload it. You can drag & drop, or choose the file from your computer or mobile device. Or you can start a new project based on a Stock Content or Template Project that suits your needs.
                              </Typography>
                              </Box>
                          </Card>
                          </Grid>
                          <Grid item md={4} xs={12} className="text-center">
                          <Card className="p-6">
                              <Box className="text-center py-4">
                              <img className="mx-auto" src={VideooEditor_AddFilter_Action2}/>
                              </Box>
                              <Box className="text-center py-4">
                              <Typography variant="h5" gutterBottom fontWeight={600} color={'#4D4D4D'}>Add Filter to Video</Typography>
                              </Box>
                              <Box className="text-center py-4">
                              <Typography variant="body2" gutterBottom>
                                  - Once you've added your video, you can start adding filters to enhance its visual appeal. VIDEOO offers a wide range of filters to choose from, allowing you to customize the style of your video to match your theme or branding.
                              </Typography>
                              </Box>
                          </Card>
                          </Grid>
                          <Grid item md={4} xs={12} className="text-center">
                          <Card className="p-6">
                              <Box className="text-center py-4">
                              <img className="mx-auto" src={VideooEditor_AddFilter_Action3}/>
                              </Box>
                              <Box className="text-center py-4">
                              <Typography variant="h5" gutterBottom fontWeight={600} color={'#4D4D4D'}>Export, Download & Share</Typography>
                              </Box>
                              <Box className="text-center py-4">
                              <Typography variant="body2" gutterBottom>
                                  - Once you've added filters to your video, you can export the final project with the filters included. VIDEOO offers a range of export options to suit your needs, including resolutions up to 4K and various file formats.
                              </Typography>
                              </Box>
                          </Card>
                          </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12} >
                    <Box className="py-10">
                        <img width={'100%'} src={VideooEditor_AddFilter_Step1} />
                    </Box>
                  </Grid>
                </Box>
              </Box>
            </Box>

            <Box className="section-9 directory" >
                <Box className="div-1232 px-18">
                    <Box>
                    <Typography className="text-center" variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>Other Video Tools</Typography>
                    </Box>
                    <Box className="text-center">
                    <Typography className="text-center" variant="body1" gutterBottom color={'#4D4D4D'}>There are plenty of other features that are avaliable with Videoo.io</Typography>
                    </Box>
                </Box>
                <Box >
                    <Grid container spacing={3}>
                    <Grid item md={3} xs={12}>
                        <Card className="p-6" sx={{background: '#abbed133'}}>
                        <Grid >
                            <img width={'100%'} src={VideooEditor_AddFilter_Action1} />
                        </Grid>
                        <Typography sx={{paddingY: '1rem'}} className="text-center" variant="h5" gutterBottom fontWeight={500} color={'#717171'}>
                            Add Music to Video
                        </Typography>
                        <Box className="mx-auto text-center">
                            <Button to="../add-music-to-video" component={Link}>Create <ArrowRightAltIcon /></Button>
                        </Box>
                        </Card>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Card className="p-6" sx={{background: '#abbed133'}}>
                        <Grid >
                            <img width={'100%'} src={VideooEditor_AddText_Image} />
                        </Grid>
                        <Typography sx={{paddingY: '1rem'}} className="text-center" variant="h5" gutterBottom fontWeight={500} color={'#717171'}>
                        Add Text to Video
                        </Typography>
                        <Box className="mx-auto text-center">
                            <Button to="../add-text-to-video" component={Link}>Create <ArrowRightAltIcon /></Button>
                        </Box>
                        </Card>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Card className="p-6" sx={{background: '#abbed133'}}>
                        <Grid >
                            <img width={'100%'} src={VideooEditor_AddStock_Image} />
                        </Grid>
                        <Typography sx={{paddingY: '1rem'}} className="text-center" variant="h5" gutterBottom fontWeight={500} color={'#717171'}>
                        Add Free Stock to Video
                        </Typography>
                        <Box className="mx-auto text-center">
                            <Button to="../add-free-stock-to-video" component={Link}>Create <ArrowRightAltIcon /></Button>
                        </Box>
                        </Card>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Card className="p-6" sx={{background: '#abbed133'}}>
                        <Grid>
                            <img width={'100%'} src={VideooEditor_AddTransitions_Image} />
                        </Grid>
                        <Typography sx={{paddingY: '1rem'}} className="text-center" variant="h5" gutterBottom fontWeight={500} color={'#717171'}>
                            Add Transitions to Video
                        </Typography>
                        <Box className="mx-auto text-center">
                            <Button to="../add-transition-to-video" component={Link}>Create <ArrowRightAltIcon /></Button>
                        </Box>
                        </Card>
                    </Grid>
                    </Grid>
                </Box>
            </Box>

            <Box className="section-9 directory" sx={{ paddingY: '3rem' }}>
              <Box className="div-1232 px-18">
                <Box className="text-center">
                  <Typography variant="h4" gutterBottom fontWeight={600} color={'#4D4D4D'}>
                    Frequently Asked Questions
                  </Typography>
                </Box>
                <Box className="text-center mx-auto" sx={{ marginBottom: '3rem', width: '60%' }}>
                  <Typography variant="body1" gutterBottom>
                    Welcome to our FAQ section about adding filters to videos with VIDEOO! Here, we answer the most common questions about using our filter features. Whether you're new to video editing or a seasoned pro, you'll find helpful tips and insights to make the most of our platform.
                  </Typography>
                </Box>
                <Box className="py-15">
                  <Typography variant="h5" gutterBottom fontWeight={600}>
                    Question #1: How do I apply filters to my video using VIDEOO?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    To apply filters to your video in VIDEOO, simply select the filter tool from the toolbar. Choose from the available filters and click on the one you want to apply. You can preview the effect in real-time and adjust the intensity to suit your preferences.
                  </Typography>
                </Box>
                <Box className="py-15">
                  <Typography variant="h5" gutterBottom fontWeight={600}>
                    Question #2: What types of filters are available in VIDEOO?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    VIDEOO offers a wide range of filters, including Instagram-style filters, social media-inspired filters, vintage effects, color boosts, black and white filters, and more. Each filter is designed to enhance your video's visual appeal and match various aesthetic styles.
                  </Typography>
                </Box>
                <Box className="py-15">
                  <Typography variant="h5" gutterBottom fontWeight={600}>
                    Question #3: Can I adjust the intensity of the filters?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Yes, VIDEOO allows you to customize the intensity of each filter. After applying a filter, you can use the slider to adjust its strength, ensuring the effect is just right for your video.
                  </Typography>
                </Box>
                <Box className="py-15">
                  <Typography variant="h5" gutterBottom fontWeight={600}>
                    Question #4: How can I preview the filters before applying them?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    VIDEOO provides a real-time preview feature that lets you see the effect of each filter on your video before applying it. Simply select a filter, and the preview will show you how your video will look with that filter.
                  </Typography>
                </Box>
                <Box className="py-15">
                  <Typography variant="h5" gutterBottom fontWeight={600}>
                    Question #5: Can I apply multiple filters to a single video?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Currently, VIDEOO allows you to apply one filter at a time to ensure the best performance and quality. However, you can layer effects by exporting the video after applying one filter and then re-importing it to apply another filter.
                  </Typography>
                </Box>
                <Box className="py-15">
                  <Typography variant="h5" gutterBottom fontWeight={600}>
                    Question #6: Are there platform-specific filters available?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Yes, VIDEOO includes platform-specific filters designed to match the aesthetics of various social media platforms such as Instagram, TikTok, Facebook, and more. These filters help ensure your video looks its best on each platform.
                  </Typography>
                </Box>
                <Box className="py-15">
                  <Typography variant="h5" gutterBottom fontWeight={600}>
                    Question #7: How do I remove a filter if I change my mind?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    If you decide to remove a filter, simply select the filter tool again and click on the "None" or "Reset" option to revert your video to its original state. This allows you to experiment freely without worrying about permanent changes.
                  </Typography>
                </Box>
                <Box className="py-15">
                  <Typography variant="h5" gutterBottom fontWeight={600}>
                    Question #8: Can I save my favorite filters for future use?
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Currently, VIDEOO does not support saving favorite filters. However, the platform makes it easy to find and apply your preferred filters quickly whenever you need them.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className="section-9 directory std-hero-section" >
              <Box className="section-15 py-12 mx-auto">
                <Box className="div-1232">
                  <Box className="mx-auto">
                    <Typography sx={{textAlign: 'center'}} variant="h3" gutterBottom fontWeight={'bolder'}>Make Your Videos Easily</Typography>
                  </Box>
                  <Box className="mx-auto text-center mt-10" onClick={handleCreateNewProject}>
                    <Button variant="contained" className="py-10 px-33" color="success">Create Now <ArrowRightAltIcon /></Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="footer-containner"><Footer /></Box>
        </Box>
        
    )
}