import './videoocareer.scss'
import { Layouts } from '../../component/layouts/layouts'

export const Career2 = () => {
  return (
    <Layouts>
      <div className='flex flex-col items-center justify-center px-10 sm:px-20 md:px-30 lg:px-40'>
        <div className='w-full max-w-1000 flex flex-col gap-10 md:gap-20 lg:gap-30 py-30'>
          <div className='text-center text-20 md:text-30 lg:text-40 font-semibold'>
            Graduate Digital Designer
          </div>

          <div className='flex flex-col gap-10 mm:gap-20'>
            <div className='text-15 md:text-18'>
              We are seeking a talented and creative Graduate Digital Designer to join our team. As a Graduate Digital Designer, you will be responsible for designing and creating visually appealing digital assets, including graphics, illustrations, and animations. You will work closely with our development team to ensure that the design elements seamlessly integrate with our web and mobile applications.

              This is an exciting opportunity to kickstart your career in digital design and gain hands-on experience in a fast-paced and innovative environment. You will have the chance to work on a variety of projects, from website design to mobile app interfaces, and contribute to the overall user experience of our products.
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Responsibilities:</span> 
                <div className='text-15 md:text-18'>
                  - Collaborate with cross-functional teams to understand project requirements and objectives
                </div>
                <div className='text-15 md:text-18'>
                  - Create visually appealing and user-friendly designs for web and mobile applications
                </div>
                <div className='text-15 md:text-18'>
                  - Develop wireframes, prototypes, and mockups to communicate design ideas
                </div>
                <div className='text-15 md:text-18'>
                  - Implement design concepts using industry-standard software and tools
                </div>
                <div className='text-15 md:text-18'>
                  - Conduct usability testing and gather feedback to iterate on designs
                </div>
                <div className='text-15 md:text-18'>
                  - Stay up-to-date with the latest design trends, techniques, and technologies
                </div>
                <div className='text-15 md:text-18'>
                  - Assist with other design-related tasks as needed
                </div>  
            </div>

            <div className='text-15 md:text-18'>
              <span className='font-semibold'>Qualifications:</span> 
                <div className='text-15 md:text-18'>
                  - Bachelor's degree in Graphic Design, Digital Design, or a related field
                </div>
                <div className='text-15 md:text-18'>
                  - Strong proficiency in design software such as Adobe Creative Suite (Photoshop, Illustrator, XD) and Sketch
                </div>  
                <div className='text-15 md:text-18'>
                  - Solid understanding of user-centered design principles and best practices
                </div>
                <div className='text-15 md:text-18'>
                  - Knowledge of HTML, CSS, and responsive design principles
                </div>
                <div className='text-15 md:text-18'>
                  - Familiarity with prototyping tools (e.g., InVision, Figma
                </div>
                <div className='text-15 md:text-18'>
                  - Excellent attention to detail and ability to work on multiple projects simultaneously
                </div>
                <div className='text-15 md:text-18'>
                  - Strong communication and collaboration skills
                </div>
                <div className='text-15 md:text-18'>
                  - Portfolio showcasing your design work and creativity
                </div>
            </div>

            <div className='text-15 md:text-18'>
              If you are a passionate and driven individual with a keen eye for design and a desire to contribute to cutting-edge digital products, we would love to hear from you. Join our team and be part of an exciting journey to create innovative solutions that impact millions of users worldwide.

              To apply, please submit your resume and portfolio to info@videoo.io. Please include "Graduate Digital Designer Application" in the subject line. Only shortlisted candidates will be contacted.

              We look forward to reviewing your application!
            </div>
          </div>
        </div>
      </div>
    </Layouts>
  )
}