import { Box } from '@mui/system';

import './aigenerated-video.scss';
import UploadImg from './../../../assets/image/tools/upload-img.png';
import HeroImg from './../../../assets/image/tools/hero-img.png';
import facebookLogo from "./../../../assets/image/Logos/facebook_logo.png";
import instagramLogo from "./../../../assets/image/Logos/instagram_logo.png";
import tiktokLogo from "./../../../assets/image/Logos/tiktok_logo.png";
import twitterLogo from "./../../../assets/image/Logos/twitter_logo.png";
import youtubeLogo from "./../../../assets/image/Logos/youtube_logo.png";
import whatsappLogo from "./../../../assets/image/Logos/whatsapp_logo.png";
import { createNewProject } from '../uploadHelper';
import { Header } from '../../../component/home-header/header';
import { Footer } from '../../../component/home-footer/footer';

export const BASE_STATIC_STORAGE_URL = process.env.REACT_APP_STATIC_STORAGE_URL;
export const how_to_1   = BASE_STATIC_STORAGE_URL + '/images/features/Add Your File Loop Video.png';
export const how_to_2   = BASE_STATIC_STORAGE_URL + '/images/features/Add a Filter to a Video.png';
export const how_to_3   = BASE_STATIC_STORAGE_URL + '/images/features/Export Join-Merge.png';

export const AIGeneratedVideo = () => {
    return (
        <Box className="T_containner">
      <Box className="Header"><Header /></Box>

      <Box className="content-containner">
      <>
            <Box className='section-15'>
                <Box className='div-1232 _1300'>
                    <Box className='breadcrumbs'>
                        <a href={'/'}>
                            <Box className='breadcrumb-text-block'>Videoo</Box>
                        </a>
                        <Box className='breadcrumb-arrow'></Box>
                        <a href={'/'}>
                            <Box className='breadcrumb-text-block'>AI Tools</Box>
                        </a>
                        <Box className='breadcrumb-arrow'></Box>
                        <Box className='breadcrumb-text-block'>AI Video Generator</Box>
                    </Box>
                </Box>
            </Box>

            <Box className='section-9 directory std-hero-section'>
                <Box className='div-1232'>
                    <Box className='hero-grid'>
                        <Box className='hero-grid-text'>
                            <h1 className='hero-header-title'>AI Video Generator</h1>
                            <Box className='hero-content-title'>Free online video editing software - Add AI-generated videos to your video editing experience (support coming soon)</Box>

                            <Box onClick={() => {
                                createNewProject("feature-compress-video")
                            }} className='choose-btn'>
                                <Box className='choose-btn-text'>Take me to Video Editor</Box>
                                <Box className='choose-btn-img'><img src={UploadImg} alt='uploadImg' /></Box>
                            </Box>
                        </Box>

                        <Box className='hero-img'>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/StOapicMq_s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </Box>
                    </Box>

                    {/* <Box className="logos-grid">
                        <Box className="usedByLogos-item">
                            <img src={instagramLogo} alt="instagram logo" width={100} />
                        </Box>
                        <Box className="usedByLogos-item">
                            <img src={youtubeLogo} alt="youtube logo" width={100} />
                        </Box>
                        <Box className="usedByLogos-item">
                            <img src={facebookLogo} alt="facebook logo" width={100} />
                        </Box>
                        <Box className="usedByLogos-item">
                            <img src={twitterLogo} alt="twitter logo" width={100} />
                        </Box>
                        <Box className="usedByLogos-item">
                            <img src={tiktokLogo} alt="tiktok logo" width={100} />
                        </Box>
                        <Box className="usedByLogos-item">
                            <img src={whatsappLogo} alt="whatsapp logo" width={100} />
                        </Box>
                        {{usedLogs.map((logo, index) => (
                            <Box className="usedByLogos-item" key={index}>{logo}</Box>
                        ))} }
                    </Box> */}
                </Box>
            </Box>

            <Box className='wf-section'>
                <Box className='div-1232 wide _3'>
                    <Box className='div-block-70'>
                        <Box className='div-block-67'>
                            <Box className='div-block-69'>
                                <h2 className="heading-20">What is SORA?</h2>
                                <h2 className="heading-20">Introducing OpenAI's First AI Video Generator!</h2>
                                <h2 className="heading-20">(Coming soon to Videoo.io)</h2>

                                <Box className='div-block-67'>
                                    <p className="how-to-content">OpenAI, a leading artificial intelligence research organization who created ChatGPT and DALL·E 3, has unveiled a new text to video AI model named SORA. SORA can create high-quality videos based on user prompts just like creating an image with DALL·E 3. The AI generated videos are quite realistic. Even though the AI model is still in the development stages, the AI generated videos are hardly distinguishable from the real camera footage. You can see some examples of these videos in this blog. </p>
                                    <br /><br />
                                    <video className="w-full" src='https://storage.googleapis.com/static-videoo-storage-25/videos/tokyo-walk-sora-ai-generated-video-text-to-video-prompt-to-video.mp4' loop controls/>
                                    <i className="how-to-content">Text to Video Prompt: “A stylish woman walks down a Tokyo street filled with warm glowing neon and animated city signage. She wears a black leather jacket, a long red dress, and black boots, and carries a black purse. She wears sunglasses and red lipstick. She walks confidently and casually. The street is damp and reflective, creating a mirror effect of the colourful lights. Many pedestrians walk about." </i>
                                </Box>
                            </Box>

                            <Box className='div-block-67'>

                                <h2 className="heading-19">What is SORA OpenAI? How does it Work?</h2>
                                
                                <b className="how-to-content">SORA is a diffusion model that can generate realistic videos from prompts. It is built by OpenAI, which is the creator of the well-known AI chat bot, ChatGPT, in San Francisco, California on February 15, 2024. SORA can create videos up to 60 seconds with multiple scenes and characters. It uses an AI image generation model to create videos. </b>
                                <br /><br />

                                <video className="w-full" src='https://storage.googleapis.com/static-videoo-storage-25/videos/wooly-mammoth-sora-ai-generated-video-text-to-video-prompt-to-video.mp4' loop controls/>
                                <i className="how-to-content">Text to Video Prompt: “Several giant wooly mammoths approach treading through a snowy meadow, their long wooly fur lightly blows in the wind as they walk, snow covered trees and dramatic snow capped mountains in the distance, mid afternoon light with wispy clouds and a sun high in the distance creates a warm glow, the low camera view is stunning capturing the large furry mammal with beautiful photography, depth of field.” </i>
                                <br /><br />

                                <b className="how-to-content">SORA has some weaknesses while creating AI generated videos, like simulating complex interactions between objects and multiple characters, physical modelling and unnatural object “morphing” or animals or people can spontaneously appear, especially in scenes containing many entities.</b>
                                <video className="w-full" src='https://storage.googleapis.com/static-videoo-storage-25/videos/puppy-cloning-sora-ai-generated-video-text-to-video-prompt-to-video.mp4' loo controls/>
                                <i className="how-to-content">Text to Video Prompt: “Five gray wolf pups frolicking and chasing each other around a remote gravel road, surrounded by grass. The pups run and leap, chasing each other, and nipping at each other, playing.” </i>
                                <br /><br />
                                <video className="w-full" src='https://storage.googleapis.com/static-videoo-storage-25/videos/chair-archaeology-sora-ai-generated-video-text-to-video-prompt-to-video.mp4' loop controls/>
                                <i className="how-to-content">Text to Video Prompt: “Archeologists discover a generic plastic chair in the desert, excavating and dusting it with great care.” </i>
                                <br /><br />


                                <h2 className="heading-19">When Will SORA be Available to the Public?</h2>
                                <b className="how-to-content">SORA is now in test stage, and it is available for some professionals. OpenAI didn’t indicate a date to release to the public, it may not even be this year. The videos generated with SORA that goes viral on the internet come out of the announcement blog post published by OpenAI. </b>
                                <br /><br />
                                <video className="w-full" src='https://storage.googleapis.com/static-videoo-storage-25/videos/closeup-man-in-glasses-sora-ai-generated-video-text-to-video-prompt-to-video.mp4' loop controls/>
                                <i className="how-to-content">Text to Video Prompt: “An extreme close-up of an gray-haired man with a beard in his 60s, he is deep in thought pondering the history of the universe as he sits at a cafe in Paris, his eyes focus on people offscreen as they walk as he sits mostly motionless, he is dressed in a wool coat suit coat with a button-down shirt , he wears a brown beret and glasses and has a very professorial appearance, and the end he offers a subtle closed-mouth smile as if he found the answer to the mystery of life, the lighting is very cinematic with the golden light and the Parisian streets and city in the background, depth of field, cinematic 35mm film.” </i>
                                <h2 className="heading-19"> </h2>

                                <h2 className="heading-19">Where SORA can be used?</h2>
                                <b className="how-to-content">SORA is now in test stage, and it is available for some professionals. OpenAI didn’t indicate a date to release to the public, it may not even be this year. The videos generated with SORA that goes viral on the internet come out of the announcement blog post published by OpenAI. </b>
                                <br /><br />
                                <video className="w-full" src='https://storage.googleapis.com/static-videoo-storage-25/videos/octopus-and-crab-sora-ai-generated-video-text-to-video-prompt-to-video.mp4' loop controls/>
                                <i className="how-to-content">Text to Video Prompt: “A large orange octopus is seen resting on the bottom of the ocean floor, blending in with the sandy and rocky terrain. Its tentacles are spread out around its body, and its eyes are closed. The octopus is unaware of a king crab that is crawling towards it from behind a rock, its claws raised and ready to attack. The crab is brown and spiny, with long legs and antennae. The scene is captured from a wide angle, showing the vastness and depth of the ocean. The water is clear and blue, with rays of sunlight filtering through. The shot is sharp and crisp, with a high dynamic range. The octopus and the crab are in focus, while the background is slightly blurred, creating a depth of field effect.” </i>
                                <h2 className="heading-19"> </h2>

                                <h2 className="heading-19">When will SORA be avilable with Videoo.io?</h2>
                                <b className="how-to-content">SORA is now in API support will be available in Videoo.io as soon as the product is released to public. We, as Videoo.io Team cannot wait to have our customers benefit the marvels of Sora by OpenAI which will surely enhance video editing experience of Videoo.io .</b>
                                <h2 className="heading-19"> </h2>

                                <h2 className="heading-19">Conclusion</h2>
                                <b className="how-to-content">SORA is a powerful new AI text to video creator tool. With its ability to generate high-quality videos from text descriptions, SORA could make video creation more accessible to a wider range of people and lead to a more diverse and creative range of videos being produced. </b>
                                <br /><br />
                                <video className="w-full" src='https://storage.googleapis.com/static-videoo-storage-25/videos/dancing-kangaroo-sora-ai-generated-video-text-to-video-prompt-to-video.mp4' loop controls/>
                                <i className="how-to-content">Text to Video Prompt: “A cartoon kangaroo disco dances.” </i>
                            </Box>

                            <Box className='div-block-67'>
                                <h2 className="heading-19">FAQ</h2>
                                <p className="how-to-content">1.	Q: What is SORA?</p>
                                <p className="how-to-content">A: SORA is a diffusion model that can generate realistic videos from prompts developed by the OpenAI which is the creator of the ChatGPT.</p>
                                <p className="how-to-content">2.	Q: When SORA launched?</p>
                                <p className="how-to-content">A: It is launched on February 15, 2024, for a small group that comprising professionals.</p>
                                <p className="how-to-content">3.	Q: Is SORA available?</p>
                                <p className="how-to-content">A: SORA is not released for the public and OpenAI didn’t indicate a date. So, it is not available for now.</p>
                                <p className="how-to-content">4.	Q: How can I use SORA?</p>
                                <p className="how-to-content">A: SORA is not released for the public and OpenAI didn’t indicate a date. However, SORA probably will be used on ChatGPT with a ChatGPT Plus membership as DALL·E 3.</p>
                                <p className="how-to-content">5.	Q: What is the pricing of SORA?</p>
                                <p className="how-to-content">A: Pricing of SORA hasn’t been announced, but it probably would be used with ChatGPT PLUS memberships as DALL·E 3.</p>
                                <br />
                                <p className="how-to-content">Keywords: SORA, OpenAI, ChatGPT, Text to Video, AI, AI Generated Video, AI Video Generator, ai video maker, ai video creator, artificial intelligence</p>
                            </Box>


                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box className='wf-section'>
                <Box className='div-1232 wide _3'>
                    <Box className='div-block-70'>
                        <Box className='div-block-69'>
                            <h2 className="heading-20">How to Add AI-Generated Visuals to your Video</h2>
                        </Box>

                        <Box className='div-block-68'>
                            <Box className='how-to-div-item'>
                                <img src={how_to_1} alt='howTo1' className="how-to-img" />
                                <h3 className="heading-21 m-y-0">Add Your File</h3>
                                <p className="how-to-content">Select your own video (or other assets) and upload it. </p>
                            </Box>

                            <Box className='how-to-div-item'>
                                <img src={how_to_2} alt='howTo2' className="how-to-img" />
                                <h3 className="heading-21 m-y-0">Add AI-Generated Visuals</h3>
                                <p className="how-to-content">Create AI-Generated visuals (currently only images are supported) in AI Director menu in video editor.</p>
                            </Box>

                            <Box className='how-to-div-item'>
                                <img src={how_to_3} alt='howTo3' className="how-to-img" />
                                <h3 className="heading-21 m-y-0">Save or Share</h3>
                                <p className="how-to-content">Export your video, and then save & share !</p>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
      </Box>

      <Box className="footer-containner"><Footer /></Box>
    </Box >
       
    )
}
