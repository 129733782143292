import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactLoading from 'react-loading';
import { Box, Select, MenuItem, Tooltip, Grid, Button, Stack } from "@mui/material";

import './projects.scss';
import PanoramaFishEyeOutlinedIcon from '@mui/icons-material/PanoramaFishEyeOutlined';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import WindowOutlinedIcon from '@mui/icons-material/WindowOutlined';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';

import GridItem from "./GridItem"
import ListItem from "./ListItem"
import { getProjects, getWorkspacesList } from './../../../../axios/ApiProvider';
import { copyObject } from "../../../workspaces/dashboard.config";
import emtpyThumb from '../../../../assets/image/emptyThumb.jpg'
import NodataImage from "../../../../assets/image/no data.png"

const BASE_BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
const BASE_FRONTEND_URL = process.env.REACT_APP_BASE_FRONTEND_URL;

export const Projects = () => {
  const languageStrs = useSelector((store) => store.editdata.languageStrs);
  const [sortKey, setSortKey] = useState(1);
  const [toolbarStyle, SetToolbarStyle] = useState('grid');
  const ToolbarStyleChange = () => { SetToolbarStyle(toolbarStyle === 'grid' ? 'list' : 'grid'); }
 
 
  const [isLoading, setIsLoading] = useState(true);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [pretPageUrl, setPretPageUrl] = useState(null);
  const [projectsArr, setProjectsArr] = useState([]);
  const [sortedArr, setSortedArr] = useState([]);

  const [workspaceType, setWorkspaceType] = useState('all')
  const [workSpacesList, setWorkSpacesList] = useState([])
  const [reloadeUrl, setReloadeUrl] = useState(BASE_BACKEND_URL + '/user/projects/')

  useEffect(() => {
    getWorkSpaces()
  }, [])

  useEffect(() => {
    if (workspaceType === 'all') {
      GetProjects(BASE_BACKEND_URL + '/user/projects/');
      setReloadeUrl(BASE_BACKEND_URL + '/user/projects/')
    } else {
      GetProjects(BASE_BACKEND_URL + '/user/projects/workspace/' + workspaceType)
      setReloadeUrl(BASE_BACKEND_URL + '/user/projects/workspace/' + workspaceType)
    }
  }, [workspaceType])

  useEffect(() => {
    let tempArr = copyObject(projectsArr)

    tempArr.sort((a, b) => {
      let returnValue;
      if (sortKey === 1) returnValue = a.title - b.title
      else if (sortKey === 2) returnValue = (+ new Date(a.updated_at)) - (+ new Date(b.updated_at))
      else if (sortKey === 3) returnValue = (+ new Date(b.updated_at)) - (+ new Date(a.updated_at))
      return returnValue
    })

    setSortedArr(tempArr)
  }, [projectsArr, sortKey])

  const getWorkSpaces = async () => {
    try {
      const result = await getWorkspacesList()

      if (result.state === 'success') {
        if (typeof result.data === 'object') {
          setWorkSpacesList(result.data)
        }
      }
    } catch (err) {
      console.log(err.message)
    }
  }

  const GetProjects = async (url) => {
    setIsLoading(false);

    let result = await getProjects(url);
    if (result.state === 'success') {
      setProjectsArr(result.data.results);
      setNextPageUrl(result.data.next);
      setPretPageUrl(result.data.previous);
    }

    setIsLoading(true);
  }

  const nextPage = () => { 
    GetProjects(nextPageUrl);
    setReloadeUrl(nextPageUrl)
  }
  const prePage = () => {
    GetProjects(pretPageUrl);
    setReloadeUrl(pretPageUrl)
  }

  const workspaceUUIDchange = async ({ target }) => {
    const uuid = target.value
    const checkUuid = workSpacesList.find((workspace) => (
      workspace.uuid === uuid
    ))

    if (checkUuid || uuid === 'all') {
      setWorkspaceType(uuid)
    }
  }

  const handleOnDelete = () => {
    GetProjects(reloadeUrl)
  }

  return (
    <Box className="custom-projects-wrapper">
      <Box className="custom-projects-toolbar-section">
        <Box className="toolbar-Breadcrumbs-contain">
          <Link to={`#`}>{languageStrs.projects}</Link>
        </Box>
        <Box className="toolbar-layoutOption-section">
          {/* <Box className="sortby-wrapper">
            <span className="sortby-title">Sort By</span>

            <Select value={sortKey}
              className="toolbar-SortBy-select"
              onChange={(event) => { setSortKey(event.target.value); }}
            >
              <Box className="toolbar-SortBy-select-header">Sort By</Box>

              <MenuItem value={1} className="toolbar-SortBy-select-item">
                <PanoramaFishEyeOutlinedIcon className="radio-outline" />
                <RadioButtonCheckedOutlinedIcon className="radio-checked" />
                <span>Name</span>
              </MenuItem>

              <MenuItem value={2} className="toolbar-SortBy-select-item">
                <PanoramaFishEyeOutlinedIcon className="radio-outline" />
                <RadioButtonCheckedOutlinedIcon className="radio-checked" />
                <span>Date Created</span>
              </MenuItem>

              <MenuItem value={3} className="toolbar-SortBy-select-item">
                <PanoramaFishEyeOutlinedIcon className="radio-outline" />
                <RadioButtonCheckedOutlinedIcon className="radio-checked" />
                <span>Date Modified</span>
              </MenuItem>
            </Select>
          </Box> */}

          <div className="sortby-wrapper">
            <Select value={workspaceType || 'all'}
              className="toolbar-SortBy-select"
              onChange={workspaceUUIDchange}
            >
              <Box className="toolbar-SortBy-select-header">{languageStrs.workspace}</Box>

              <MenuItem value='all' className="toolbar-SortBy-select-item">
                <PanoramaFishEyeOutlinedIcon className="radio-outline" />
                <RadioButtonCheckedOutlinedIcon className="radio-checked" />
                <span>{languageStrs.all_workspaces}</span>
              </MenuItem>

              {workSpacesList.map((workspace, key) => (
                <MenuItem key={key}
                  value={workspace.uuid}
                  className="toolbar-SortBy-select-item"
                >
                  <PanoramaFishEyeOutlinedIcon className="radio-outline" />
                  <RadioButtonCheckedOutlinedIcon className="radio-checked" />
                  <span>{`${workspace?.title} (${workspace?.uuid})`}</span>
                </MenuItem>
              ))}
            </Select>
          </div>

          <Box className="projects-toolbar-style-contain" onClick={ToolbarStyleChange}>
            {toolbarStyle === 'grid' ? (
              <Tooltip title="view as list"><FormatListBulletedOutlinedIcon /></Tooltip>
            ) : (
              <Tooltip title="view as grid"><WindowOutlinedIcon /></Tooltip>
            )}
          </Box>
        </Box>
      </Box >
      
      <Box className='projects-next-preview-btn'>
          <Stack direction="row" spacing={2}>
            <Button onClick={prePage} variant="outlined" startIcon={<SkipPreviousIcon />} disabled={!!pretPageUrl ? false : true}>
              {languageStrs.previous}
            </Button>

            <Button onClick={nextPage} variant="outlined" endIcon={<SkipNextIcon />} disabled={!!nextPageUrl ? false : true}>
              {languageStrs.next}
            </Button>
          </Stack>
        </Box>


        <Box className="custom-projects-contents-section">
        {isLoading && sortedArr.length === 0 && 
          <>
            <img src={NodataImage} alt="no data" />
            <h1 className="text-center text-[30px]">{languageStrs.you_dont_have_content_here}</h1>
          </>
        } 
        {sortedArr.length !== 0 &&
          <>
          {toolbarStyle === 'grid' ? (
            <Box className="projects-container">
              <Grid container spacing={2}>
                {sortedArr.map((item, index) => (
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={2.4} key={index}>
                    <GridItem onDelete={handleOnDelete} itemData={item} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          ) : (
            <Box className="list-projects-container">
              <Box className="projects-header-wrapper">
                <Box className="projects-header-NameTableCell1"></Box>
                <Box className="projects-header-NameTableCell1">{languageStrs.name}</Box>
                <Box className="projects-header-NameTableCell2">{languageStrs.status}</Box>
                <Box className="projects-header-NameTableCell3">{languageStrs.data_modified}</Box>
                <Box className="projects-header-NameTableCell4"></Box>
              </Box>

              {sortedArr.map((item, index) => (
                <ListItem onDelete={handleOnDelete} itemData={item} key={index} />
              ))}
            </Box>
          )}
          </>
        }

        </Box>

        {
          !isLoading && (
            <Box className='absolute top-1/2 left-1/2 -translate-x-1/2'>
              <ReactLoading type={'spinningBubbles'} color='rgb(53 126 221)' height={100} width={100} />
            </Box>
          )
        }
      
    </Box >
  )
}