import { useState } from "react";
import { useSelector } from "react-redux";
import { Box, MenuItem, Select } from "@mui/material";
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import Crop169Icon from '@mui/icons-material/Crop169';
import CropSquareIcon from '@mui/icons-material/CropSquare';

import './templateHeader.scss';
import { TemplatesHeaderItems } from './../../dashboard.config.js';

export const TemplatesHeader = () => {
  const languageStrs = useSelector((store) => store.editdata.languageStrs);
  const [pathMatch, SetPathMatch] = useState('');
  const MenuClickFunc = (path) => { SetPathMatch(path); }

  const [SortBy, SetSortBy] = useState(1);
  return (
    // <Box className="custom-templates-header-wrapper">
    //   <Box className="templates-header-items">
    //     {
    //       TemplatesHeaderItems.map((item, index) => {
    //         return (
    //           <Box key={index} className={`templates-header-item ${!pathMatch ? index === 0 ? 'active' : '' : pathMatch === item.link ? 'active' : ''}`} onClick={() => { MenuClickFunc(item.link) }}>
    //             {item.title}
    //           </Box>
    //         )
    //       })
    //     }
    //   </Box>

    //   <Box className="templates-header-aspectRatioBtn">
    //     <Select value={SortBy} onChange={(event) => { SetSortBy(event.target.value); }} className="templates-aspectRatioBtn-select">
    //       <MenuItem value={1} className="templates-aspectRatioBtn-select-item">
    //         <AspectRatioIcon />
    //         <span>All Sizes</span>
    //       </MenuItem>

    //       <MenuItem value={2} className="templates-aspectRatioBtn-select-item">
    //         <Crop169Icon />
    //         <span>Landscape</span>
    //       </MenuItem>

    //       <MenuItem value={3} className="templates-aspectRatioBtn-select-item">
    //         <CropSquareIcon />
    //         <span>Square</span>
    //       </MenuItem>

    //       <MenuItem value={4} className="templates-aspectRatioBtn-select-item">
    //         <Crop169Icon style={{ transform: 'rotate(90deg)' }} />
    //         <span>Portrait</span>
    //       </MenuItem>
    //     </Select>
    //   </Box>
    // </Box>

    <div className="custom-templates-header-wrapper py-20">
      <div className="flex-1 text-15 text-center">{languageStrs.create_empty_project_templates}</div>
    </div>
  )
}