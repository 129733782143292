import { PROJECTJSON, ACTIVEVIDEOUUID, ACTIVETEXTUUID, PROJECT_UUID, CHANGEDJSON, ACTIVEIMAGEUUID, SAVESTATE, SEQUENCESTATUS, SEQUENCENUMBER, THUMBNAILS, UPLOADINGFILES, TIMELINEFIT, SUBCREATING, THUMBNAILSTATE, EDITSUBTITLE, PROJECTVOLUME, FULLSCREENFLAG, STRETCHSCREENFLAG, ACTIVEAUDIOUUID, CAPTURETHUMBNAIL, ADDEDTEMPLATE } from '../constants/projectinfo';

export const projectStore = (state = {}, action) => {
  switch (action.type) {
    case PROJECT_UUID: { return { ...state, projectUUID: action.payload }; }
    case PROJECTJSON: { return { ...state, projectJSON: action.payload } }
    case ACTIVEVIDEOUUID: { return { ...state, activeVideoUUID: action.payload } }
    case ACTIVETEXTUUID: { return { ...state, activeTextUUID: action.payload } }
    case ACTIVEIMAGEUUID: { return { ...state, activeImageUUID: action.payload } }
    case ACTIVEAUDIOUUID: { return { ...state, activeAudioUUID: action.payload } }
    case CHANGEDJSON: { return { ...state, changedJSON: action.payload } }
    case SAVESTATE: { return { ...state, saveState: action.payload } }
    case SEQUENCESTATUS: { return { ...state, sequenceStatus: action.payload } }
    case SEQUENCENUMBER: { return { ...state, sequenceNumber: action.payload } }
    case THUMBNAILS: { return { ...state, thumbNails: action.payload } }
    case UPLOADINGFILES: { return { ...state, uploadingFiles: action.payload } }
    case TIMELINEFIT: { return { ...state, timelineFit: action.payload } }
    case SUBCREATING: { return { ...state, subcreating: action.payload } }
    case THUMBNAILSTATE: { return { ...state, thumbnailState: action.payload } }
    case EDITSUBTITLE: { return { ...state, editSubtitle: action.payload } }
    case PROJECTVOLUME: { return { ...state, projectVolume: action.payload } }
    case FULLSCREENFLAG: { return { ...state, fullScreenFlag: action.payload } }
    case STRETCHSCREENFLAG: { return { ...state, stretchScreenFlag: action.payload } }
    case CAPTURETHUMBNAIL: { return { ...state, captureThumbnailFlag: action.payload } }
    case ADDEDTEMPLATE: { return { ...state, addedTemplate: action.payload } }
    default: { return { ...state }; }
  }
};
