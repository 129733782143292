import { Box } from '@mui/system';

import './add-subtitles.scss';
import UploadImg from './../../../assets/image/tools/upload-img.png';
import facebookLogo from "./../../../assets/image/Logos/facebook_logo.png";
import instagramLogo from "./../../../assets/image/Logos/instagram_logo.png";
import tiktokLogo from "./../../../assets/image/Logos/tiktok_logo.png";
import twitterLogo from "./../../../assets/image/Logos/twitter_logo.png";
import youtubeLogo from "./../../../assets/image/Logos/youtube_logo.png";
import whatsappLogo from "./../../../assets/image/Logos/whatsapp_logo.png";
import { createNewProject } from './../SubtitlesTranscriptionHelper';
import { Header } from '../../../component/home-header/header';
import { Footer } from '../../../component/home-footer/footer';

export const BASE_STATIC_STORAGE_URL = process.env.REACT_APP_STATIC_STORAGE_URL;
export const how_to_1   = BASE_STATIC_STORAGE_URL + '/images/features/Add Your File Subtitles.png';
export const how_to_2   = BASE_STATIC_STORAGE_URL + '/images/features/Auto Subtitles.png';
export const how_to_3   = BASE_STATIC_STORAGE_URL + '/images/features/Export Subtitles.png';

export const AddSubtitles = () => {
    return (
        <Box className="T_containner">
      <Box className="Header"><Header /></Box>

      <Box className="content-containner">
      <>
            <Box className='section-15'>
                <Box className='div-1232 _1300'>
                    <Box className='breadcrumbs'>
                        <a href={'/'}>
                            <Box className='breadcrumb-text-block'>Videoo</Box>
                        </a>
                        <Box className='breadcrumb-arrow'></Box>
                        <a href={'/'}>
                            <Box className='breadcrumb-text-block'>Online Video Edito</Box>
                        </a>
                        <Box className='breadcrumb-arrow'></Box>
                        <Box className='breadcrumb-text-block'>Add Subtitles to Video</Box>
                    </Box>
                </Box>
            </Box>

            <Box className='section-9 directory std-hero-section'>
                <Box className='div-1232'>
                    <Box className='hero-grid'>
                        <Box className='hero-grid-text'>
                            <h1 className='hero-header-title'>Add Subtitles to Video</h1>
                            <Box className='hero-content-title'>Free online video editing software - create videos online</Box>

                            <Box onClick={createNewProject} className='choose-btn'>
                                <Box className='choose-btn-text'>Go to Editor</Box>
                                <Box className='choose-btn-img'><img src={UploadImg} alt='uploadImg' /></Box>
                            </Box>
                        </Box>

                        <Box className='hero-img'>
                            <iframe
                                src={`https://www.youtube.com/embed/r9MqKmAnIYE`}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="Embedded youtube"
                                style={{margin: 'auto', width: '100%', aspectRatio: 1.7}}
                            />
                        </Box>
                    </Box>

                    {/* <Box className="logos-grid">
                        <Box className="usedByLogos-item">
                            <img src={instagramLogo} alt="instagram logo" width={100} />
                        </Box>
                        <Box className="usedByLogos-item">
                            <img src={youtubeLogo} alt="youtube logo" width={100} />
                        </Box>
                        <Box className="usedByLogos-item">
                            <img src={facebookLogo} alt="facebook logo" width={100} />
                        </Box>
                        <Box className="usedByLogos-item">
                            <img src={twitterLogo} alt="twitter logo" width={100} />
                        </Box>
                        <Box className="usedByLogos-item">
                            <img src={tiktokLogo} alt="tiktok logo" width={100} />
                        </Box>
                        <Box className="usedByLogos-item">
                            <img src={whatsappLogo} alt="whatsapp logo" width={100} />
                        </Box>
                        {{usedLogs.map((logo, index) => (
                            <Box className="usedByLogos-item" key={index}>{logo}</Box>
                        ))} }
                    </Box> */}

                </Box>
            </Box>

            <Box className='wf-section'>
                <Box className='div-1232'>
                    <Box className='div-block-67'>
                        <h2 className="heading-19">Add Subtitles to Video, Free!</h2>

                        <Box className='text-section-block'>
                            <ul className='text-section-ul'>
                                <li className='py-10'>
                                    If you want to add subtitles to your video for accessibility or language purposes, you can do it easily using Videoo.io, a cloud-based online video editor. With Videoo.io's automatic subtitle feature, you can add subtitles to your video quickly and efficiently.
                                </li>
                                <li className='py-10'>
                                    Videoo.io offers a user-friendly interface that allows you to add auto subtitles to your video with ease. You can access the video editor from anywhere, anytime, and customize your subtitles to your desired style and formatting.
                                </li>
                                <li className='py-10'>
                                    Here are the steps to add auto subtitles to your video using Videoo.io:
                                </li>
                                <li className='py-10'>
                                    Upload your video: Start by logging in to your Videoo.io account and uploading your video file to the editor.
                                </li>
                                <li className='py-10'>
                                    Enable the automatic subtitle feature: Click on the "Subtitle" button in the toolbar on the right-hand side of the editor, and then select the "Automatic" option.
                                </li>
                                <li className='py-10'>
                                    Choose the language: Select the language that you want your subtitles to be in from the dropdown menu.
                                </li>
                                <li className='py-10'>
                                    Customize the subtitle style: Videoo.io offers various styles and formatting options for your subtitles, including font style, size, color, and position on the screen. You can customize these options to fit your desired style.
                                </li>
                                <li className='py-10'>
                                    Preview and save your edited video: Once you have added the auto subtitles to your video and customized them to your desired style, preview your video to ensure that it looks the way you want it to. If you are happy with the result, save your edited video to your computer or directly to your social media or website.
                                </li>
                                <li className='py-10'>
                                    In conclusion, adding auto subtitles to your video using Videoo.io is a simple and effective way to make your video accessible and understandable for a wider audience. With its easy-to-use interface and additional features, Videoo.io is a great tool for anyone looking to create high-quality, professional-looking videos.
                                </li>
                            </ul>
                        </Box>

                    </Box>
                </Box>
            </Box>

            <Box className='wf-section'>
                <Box className='div-1232 wide _3'>
                    <Box className='div-block-70'>
                        <Box className='div-block-69'>
                            <h2 className="heading-20">How to Add Subtitles to a Video</h2>
                        </Box>

                        <Box className='div-block-68'>
                            <Box className='how-to-div-item'>
                                <img src={how_to_1} alt='howTo1' className="how-to-img" />
                                <h3 className="heading-21 m-y-0">Add Your File</h3>
                                <p className="how-to-content">Select your video and upload it. You can drag &amp; drop, or choose the file from your computer or mobile device</p>
                            </Box>

                            <Box className='how-to-div-item'>
                                <img src={how_to_2} alt='howTo2' className="how-to-img" />
                                <h3 className="heading-21 m-y-0">Add Subtitle to Video</h3>
                                <p className="how-to-content">Simply click on the video's image and drag the corners to adjust. Or click 'Settings' and choose a preset canvas size (like 'YouTube', or 'Instagram Stories') to add subtitles automatically</p>
                            </Box>

                            <Box className='how-to-div-item'>
                                <img src={how_to_3} alt='howTo3' className="how-to-img" />
                                <h3 className="heading-21 m-y-0">Share!</h3>
                                <p className="how-to-content">That's it. You're ready to share your video with your friends and followers. Hit 'Export' and your video will start to download</p>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
      </Box>

      <Box className="footer-containner"><Footer /></Box>
    </Box >
      
    )
}