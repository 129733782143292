import { useSelector } from "react-redux"
import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Box } from "@mui/material";

import './workspaces.scss';
import { DashboardNavbar } from './../../component/workspaces/dashboard/navbar';
import { DashboardHeader } from './../../component/workspaces/dashboard/header';
import { Templates } from "./../../component/workspaces/pages/templates/templates";
import { Projects } from "./../../component/workspaces/pages/projects/projects";
import { WorkSpace } from "../../component/workspaces/pages/workspace/workspace";
import { Profile } from "../../component/workspaces/pages/profile/profile";
import { Preferences } from "../../component/workspaces/pages/preferences/preferences";
import { ApiKeys } from "../../component/workspaces/pages/apikeys/apikeys";
import Payment from "../../component/workspaces/pages/payment/payment"

export const DashBoard = () => {
  const loginState = useSelector((store) => store.maindata.loginState)
  const [userData, setUserData] = useState({})
  useEffect(() => {
    let tempData = {}
    let item = localStorage.getItem('user_data')
    if (!!item && JSON.parse(item)?.state === 'loggedin') {
      tempData = JSON.parse(item)
    }

    setUserData(tempData)
  }, [loginState])

  return (
    <Box className='w-dashboard-contain'>
      <Box className="w-dashboard">
        <DashboardNavbar userData={userData} />

        <Box className="dashboard-content-contain">
          <DashboardHeader userData={userData} />

          <section className="d-content-body-section">
            <Routes>
              <Route path="/stocks" element={<Projects />} />
              <Route path="/templates" element={<Templates />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/workspace" element={<WorkSpace />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/payment" element={<Payment />} />
              <Route path="/preferences" element={<Preferences />}></Route>
              <Route path="/apiKeys" element={<ApiKeys />}></Route>
            </Routes>
          </section>
        </Box>
      </Box >
    </Box >
  )
};
