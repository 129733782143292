import React, { useCallback, useState } from "react";
import { NavLink, Link, useParams, useSearchParams } from 'react-router-dom';
import { Box } from "@mui/material";

import './signIn.scss';
import Logo from './../../assets/image/Logo.png'
import { GoogleIcon } from "../../Icons";
import { useLocation } from 'react-router-dom';

import { BASE_BACKEND_URL, EmailSendEmailMagicLinkForSignIn } from "../../axios/ApiProvider"

export const SignIn = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectUUID = queryParams.get('project_uuid');

  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");

  const [accountInfo, setAccountInfo] = useState({
    email: email || ""
  })

  const GoogleSignInBtn = useCallback(() => {
    const REACT_APP_BASE_BACKEND_URL = BASE_BACKEND_URL;
    const REACT_APP_GOOGLE_CLIENT_ID = "1034580383158-i286oqc9psc2c11utuhr86c4b5mdcof0.apps.googleusercontent.com";
    const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
    const redirectUri = 'api/v1/oauth/google';

    const scope = ['https://www.googleapis.com/auth/userinfo.email', 'https://www.googleapis.com/auth/userinfo.profile'].join(' ');

    const params = {
      response_type: 'code',
      client_id: REACT_APP_GOOGLE_CLIENT_ID,
      redirect_uri: `${REACT_APP_BASE_BACKEND_URL}/${redirectUri}`,
      prompt: 'select_account',
      access_type: 'offline',
      scope
    };
    const urlParams = new URLSearchParams(params).toString();
    window.location = `${googleAuthUrl}?${urlParams}`;
  }, []);

  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const EmailSignInBtn = async (form_data, file) => {
    let username = accountInfo.email;
    let issuccess = false;

    if (!validateEmail(username)) {
      window.toastr.error("Email is not acceptable");
      return issuccess;
    }

    let result = await EmailSendEmailMagicLinkForSignIn(projectUUID);

    if (!result || result.state === 'error') {
      window.toastr.error(result.message);
      return issuccess;
    }

    issuccess = true;
    window.toastr.success(result.data.message);
    return issuccess;
  }


  const handleInputChange = (e) => {
    setAccountInfo({
      ...accountInfo,
      [e.target.name]: e.target.value
    })
  }

  return (
    <Box className="signUp-containner">
      <Box className="signup-left">
        <Box className='signup-left-containner'>
          <Box className='signup-left-content'>
            <Box className='signup-content-header'>
              <Link to={'..'}>
                <img src={Logo} alt='logo' />
              </Link>

              <Box className='signup-header-content'>
                Welcome back to VIDEOO
              </Box>

              <Box className='signup-header-footer'>
                Log in to your account and start creating
              </Box>
            </Box>

            <Box className='signup-content-body'>
            {projectUUID ? null : (
              <Box className='googleBtn' onClick={GoogleSignInBtn}>
                <GoogleIcon /> Continue with Google
              </Box>
            )}
            {projectUUID ? (
              <Box className='signup-content-or'></Box>
            ) : (
              <Box className='signup-content-or'>OR</Box>
            )}
              <Box className='signup-content-input'>
                <input placeholder="Enter your email address here" data-testid="@email-input" type="email" name="email" className='email-input' id='email_input' value={accountInfo.email} onChange={handleInputChange} />
                <label htmlFor='email' className='email-input-label'>
                  Email Address
                </label>
              </Box>

              <Box className='signup-Btn' onClick={EmailSignInBtn}>
                Get Magic Link
              </Box>

              <p className='signup-btn-footer-text'>We'll email you a magic link to sign in.</p>
            </Box>
          </Box>

          <Box className='signup-left-footer'>
            <Box className='signup-content-footer'>
              <Box className='signup-content-footer-text'>
                By proceeding, you agree to our
                <Box>
                  <Link to=''>Terms</Link> and <Link to=''>Privacy Policy</Link>
                </Box>
              </Box>

              <Box className='signup-content-footer-footer'>
                You don't have an account?
                <br />
                <NavLink to={`/signup/?project_uuid=${projectUUID || ''}`}>Create an account</NavLink>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className="signup-right"></Box>
    </Box>
  )
}